import { Box, Button, Flex, Grid, GridItem, Heading, HStack, Input, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { FiMoreVertical } from 'react-icons/fi'
import { createTrafficSourceForOrganization, deleteTrafficSource, updateTrafficSource } from '../../api/traffic-sources'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { initTrafficSourceState } from '../../store/actions/trafficSource'
import { TrafficSourceDetailSidebar } from './TrafficSourceDetailSidebar'

export const TrafficSourcesPage = () => {


    const [selectedTrafficSource, setSelectedTrafficSource] = useState<any>(null)
    const { isOpen, onClose, onOpen } = useDisclosure()
    const dispatch = useAppDispatch()
    const toast = useToast()

    const { trafficSource: { trafficSources }, organization: { currentSelectedOrganization } } = useAppSelector(state => state)

    console.log(trafficSources)

    useEffect(() => {
        if (!isOpen)
            setSelectedTrafficSource(null)
    }, [isOpen])

    const onAddNewTrafficSource = async (newTrafficSource: any) => {

        createTrafficSourceForOrganization(currentSelectedOrganization?.id, {
            data: {
                name: newTrafficSource.name,
                crmID: newTrafficSource.crmId
            }
        })
            .then(response => {
                onClose()
                dispatch(initTrafficSourceState())
                toast({
                    status: 'success',
                    title: 'Traffic Source Added'
                })
            })
            .catch(err => {

            })
    }

    const onEditTrafficSource = (id: number, updatedTrafficSource: any) => {

        updateTrafficSource(id, {
            data: {
                name: updatedTrafficSource.name,
                crmID: updatedTrafficSource.crmId
            }
        })
            .then(response => {
                onClose()
                dispatch(initTrafficSourceState())
                toast({
                    status: 'success',
                    title: 'Traffic Source Updated'
                })
            })
            .catch(err => {

            })
    }

    const onDeleteTrafficSource = (id: number) => {
        deleteTrafficSource(id)
            .then(() => {
                dispatch(initTrafficSourceState())
                toast({
                    status: 'success',
                    title: 'Traffic Source Deleted'
                })
            })
            .catch(err => {

            })
    }

    return (
        <>
            {
                isOpen ?
                    <TrafficSourceDetailSidebar trafficSource={selectedTrafficSource}
                        onAddNewTrafficSource={onAddNewTrafficSource}
                        onEditTrafficSource={onEditTrafficSource}
                        onBackDropClick={onClose} /> : null
            }
            <DashboardLayout heading="Traffic Sources" subHeading="0 traffic sources">
                <Box p={'5'}>

                    <Flex mb={'5'} alignItems={'center'} justifyContent={'space-between'}>
                        <Text fontWeight={'bold'} fontSize={'xl'}>Traffic Source</Text>

                        <HStack>
                            <Input size={'md'} bgColor={'white'} placeholder='Filter...' rounded={'full'} />
                            <Button onClick={onOpen} w={'full'} rounded={'full'} colorScheme={'brand'}>Add Traffic Sources</Button>
                        </HStack>
                    </Flex>

                    <Grid gap={5} templateColumns={selectedTrafficSource ? 'repeat(2,1fr)' : 'repeat(1,1fr)'}>
                        <GridItem>
                            <Table bgColor={'white'}>
                                <Thead>
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th>ID on CRM</Th>
                                        <Th># of Pixels</Th>
                                        <Th />
                                    </Tr>
                                </Thead>

                                <Tbody bgColor={'gray.50'}>
                                    {
                                        trafficSources.map((source, index) => (
                                            <Tr key={index}>
                                                <Td cursor={'pointer'} onClick={() => setSelectedTrafficSource(source)}>{source.name}</Td>
                                                <Td>{source.crmID}</Td>
                                                <Td>{source.pixels?.length}</Td>
                                                <Td>
                                                    <Menu>
                                                        <MenuButton>
                                                            <FiMoreVertical cursor={'pointer'} fontSize={'20px'} />
                                                        </MenuButton>
                                                        <MenuList>
                                                            <MenuItem onClick={() => {
                                                                setSelectedTrafficSource(source)
                                                                onOpen()
                                                            }}>Edit Source</MenuItem>
                                                            <MenuItem onClick={() => {
                                                                onDeleteTrafficSource(source.id)
                                                            }} color={'red.500'}>Delete</MenuItem>
                                                        </MenuList>
                                                    </Menu>
                                                </Td>



                                            </Tr>
                                        ))
                                    }

                                </Tbody>

                            </Table>
                        </GridItem>

                        {
                            selectedTrafficSource && (
                                <GridItem>
                                    <Table bgColor={'white'}>
                                        <Thead>
                                            <Tr>
                                                <Th>Project</Th>
                                                <Th>Campaign IDs</Th>
                                                <Th>Product IDs</Th>
                                                <Th>Pixel Type</Th>
                                            </Tr>
                                        </Thead>

                                        <Tbody bgColor={'gray.50'}>
                                            {
                                                selectedTrafficSource?.pixels?.map((pixel: any) => (
                                                    <Tr>
                                                        <Td>{pixel.project?.settings?.name}</Td>
                                                        <Td>{pixel.campaignIDs}</Td>
                                                        <Td>{pixel.productIDs}</Td>
                                                        <Td>{pixel.type}</Td>
                                                    </Tr>
                                                ))
                                            }
                                        </Tbody>

                                    </Table>

                                </GridItem>
                            )
                        }
                    </Grid>

                </Box>
            </DashboardLayout>
        </>

    )
}
