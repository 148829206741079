import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuItemOption,
    MenuGroup,
    MenuOptionGroup,
    MenuDivider,
    Button,
} from '@chakra-ui/react'
import { BsChevronDown } from 'react-icons/bs'

export const CustomWhiteMenuButton = ({ children }: any) => {
    return (
        <MenuButton rounded={'full'} bgColor={'white'} as={Button} rightIcon={<BsChevronDown />}>
            {children}
        </MenuButton>
    )
}
