import { AlertDialog , AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button } from '@chakra-ui/react'
import React, { useRef } from 'react'

export const CustomDialog = ({ isOpen, onClose, title, message, onDeleteClick, isLoading, actionButtonColorScheme , actionButtonText }: any) => {
    const cancelRef = useRef<any>()

    return (
        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={cancelRef}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {title}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {message}
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef.current} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button isLoading={isLoading} colorScheme={actionButtonColorScheme} onClick={onDeleteClick} ml={3}>
                            {actionButtonText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    )
}
