import { Box, Grid, GridItem, Input, useDisclosure } from '@chakra-ui/react'
import React, { useState } from 'react'
import { CustomResponsiveGrid } from '../../components/CustomResponsiveGrid'
import { CustomTabs } from '../../components/CustomTabs'
import { PluginDetailCard } from '../../components/PluginDetailCard'
import { useAppSelector } from '../../hooks/redux'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { PluginDetailModal } from './PluginDetailModal'

export const PluginsPage = () => {

  const { plugin: { plugins }, organization: { currentSelectedOrganization } } = useAppSelector(state => state)


  return (
    <DashboardLayout heading={`${plugins.length} Plugins`} subHeading={'6 Setups'}>
      <CustomTabs
        tabs={[
          'GENERAL',
          'ACTIVE'
        ]}
        panels={[
          <GeneralTab data={plugins.map(plugin => ({
            id: plugin.id,
            title: plugin.attributes.name,
            description: plugin.attributes.description
          }))} />,

          <GeneralTab data={currentSelectedOrganization?.activatedPlugins.map((plugin: any) => ({
            id: plugin.plugin.id,
            title: plugin.plugin.name,
            description: plugin.plugin.description
          }))} />
        ]}
        rightContent={
          <Box px={'3'}>
            <Input size={'lg'} w={'fit-content'} bgColor={'white'} rounded={'full'} placeholder={'Find Plugins...'} />
          </Box>}
      />


    </DashboardLayout>
  )
}

const GeneralTab = ({ data = [] }: any) => {

  const { isOpen, onClose, onOpen } = useDisclosure()

  const [selectedPlugin, setSelectedPlugin] = useState<any>(null)

  const onPluginClick = (pluginId: any) => {
    setSelectedPlugin(pluginId)
    onOpen()
  }

  return (
    <>
      <PluginDetailModal selectedPluginId={selectedPlugin} isOpen={isOpen} onClose={onClose} />
      <CustomResponsiveGrid>

        {
          data.map((entry: any) => {
            return <GridItem>
              <PluginDetailCard title={entry.title} description={entry.description} onClick={() => onPluginClick(entry.id)} />
            </GridItem>
          })
        }





      </CustomResponsiveGrid>
    </>
  )
}
