import { baseAxiosInstance } from "../config/axios"
import { store } from "../store"

export const fetchOrganizationTrafficSources = async (organizationId: number) => {
    const token = store.getState().user.token

    return baseAxiosInstance.get(`/traffic-sources/organization/${organizationId}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const createTrafficSourceForOrganization = async (organizationId: number, body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/traffic-sources/create/${organizationId}`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const updateTrafficSource = async (trafficSourceId: number, body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.put(`/traffic-sources/${trafficSourceId}`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}



export const deleteTrafficSource = async (trafficSourceId: number) => {
    const token = store.getState().user.token

    return baseAxiosInstance.delete(`/traffic-sources/${trafficSourceId}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}
