import { Box, Center, Image } from '@chakra-ui/react'
import React from 'react'

export const ImageCard = ({ imgSrc, children, ...rest }: any) => {
    return (
        <Box shadow={'md'} p={'4'} py={'6'} bgColor={'white'} {...rest}>
            <Box p={'3'} bgColor={'gray.50'}>
                <Center h={'full'}>
                    <Image boxSize={'180px'} objectFit={'contain'} src={imgSrc} />
                </Center>
            </Box>
            {children}

        </Box>
    )
}
