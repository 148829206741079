
import { Box, Button, Heading, SimpleGrid, Text, useColorModeValue as mode, } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { routeNames } from '../../router/routeNames'

export const AccountRequestedPage = () => {

    const navigate = useNavigate()

    return (
        <Box minH="100vh" bg={{ md: mode('gray.100', 'inherit') }}>
            <Box maxW="6xl" mx="auto" py={{ base: '10', md: '20' }} px={{ base: '4', md: '10' }}>
                <SimpleGrid columns={{ base: 1, lg: 1 }} spacing="14">
                    <Box w="full" maxW="xl" mx="auto">
                        <Box
                            bg={{ md: mode('white', 'gray.700') }}
                            p={{ base: '4', md: '12' }}
                            borderWidth={{ md: '1px' }}
                            borderColor={mode('gray.200', 'transparent')}
                            shadow={{ md: 'sm' }}
                        >
                            <Box mb="8" textAlign={{ base: 'center', md: 'start' }}>
                                <Heading size="lg" mb="2" fontWeight="extrabold">
                                    Request Submitted.
                                </Heading>
                                <Text fontSize="lg" color={mode('gray.600', 'gray.400')} fontWeight="medium">
                                    Your account request has been submitted for review, we will inform you through the provided email.
                                </Text>

                                <Button onClick={() => {
                                    navigate(routeNames.login())
                                }} mt={'5'} colorScheme={'brand'}>Go Back</Button>
                            </Box>


                        </Box>


                    </Box>
                </SimpleGrid>
            </Box>
        </Box>
    )
}
