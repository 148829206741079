import { Box, Button, Center, Divider, Flex, Grid, GridItem, Heading, HStack, Icon, IconButton, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AiFillCopy, AiOutlineArrowDown, AiOutlineClockCircle, AiOutlineSearch, AiOutlineUnorderedList } from 'react-icons/ai'
import { FiMoreVertical } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { CustomResponsiveGrid } from '../../components/CustomResponsiveGrid'
import { ProjectDetailsCard } from '../../components/ProjectDetailsCard'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { routeNames } from '../../router/routeNames'
import dayjs from 'dayjs'
import { cloneProject, createProjectForOrganization, deleteProject } from '../../api/project'
import { initProjectState } from '../../store/actions/project'
import { CustomDialog } from '../../components/CustomDialog'
import ProjectTypeSelectionModal from './ProjectTypeSelectionModal'
import ProjectCreate from './ProjectCreate'
import { BsGrid3X3Gap } from 'react-icons/bs'
import { ImEarth } from 'react-icons/im'
import CustomPagination from '../../components/CustomPagination'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const templateImage = require('../../assets/images/template.PNG')

export const ProjectsView = () => {

    const location = useLocation()
    console.log(location)

    const { project: { projects }, organization: { currentSelectedOrganization } } = useAppSelector(state => state)

    const [currentView, setCurrentView] = useState<'grid' | 'list'>(location?.hash.includes('#list') ? 'list' : 'grid')

    const [isProjectDeleteDialogOpen, setIsProjectDeleteDialogOpen] = useState(false)
    const [selectedProjectForDelete, setSelectedProjectForDelete] = useState<any>(null)

    const [isProjectCloneDialogOpen, setIsProjectCloneDialogOpen] = useState(false)
    const [selectedProjectForClone, setSelectedProjectForClone] = useState<any>(null)

    const [sortBy, setSortBy] = useState<'status' | 'name' | 'createdAt' | 'updatedAt'>('createdAt')


    const [delLoading, setDelLoading] = useState(false)
    const [cloneLoading, setCloneLoading] = useState(false)


    const { isOpen: isTypeSelectionModalOpen, onOpen: onIsTypeSelectionModalOpen, onClose: onIsTypeSelectionModalClose } = useDisclosure()

    const [currentProjects, setCurrentProjects] = useState<any>([])
    const [filteredProjects, setFilteredProjects] = useState<any>([])

    const [searchValue, setSearchValue] = useState('')

    const navigate = useNavigate()
    const dispatch = useAppDispatch()


    const toast = useToast()

    const onCurrentProjectsChange = (newItems: any) => {
        setCurrentProjects(newItems || [])
    }

    const onProjectDeleteClick = async (projectId: any) => {

        setSelectedProjectForDelete(projectId)
        setIsProjectDeleteDialogOpen(true)

    }

    const onConfirmDelete = async () => {

        setDelLoading(true)
        deleteProject(selectedProjectForDelete)
            .then(() => {
                dispatch(initProjectState())
                toast({
                    status: 'success',
                    title: 'Project Deleted'
                })
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: 'Failed, Try again'
                })
            })
            .finally(() => {
                setIsProjectDeleteDialogOpen(false)
                setSelectedProjectForDelete(null)
                setDelLoading(false)
            })
    }

    const onProjectCloneClick = async (projectId: any) => {

        setSelectedProjectForClone(projectId)
        setIsProjectCloneDialogOpen(true)

    }

    const onConfirmClone = () => {

        setCloneLoading(true)
        cloneProject(currentSelectedOrganization?.id, selectedProjectForClone)
            .then((response) => {

                dispatch(initProjectState())
                toast({
                    status: 'success',
                    title: 'Project Cloned'
                })
            })
            .catch((err) => {
                toast({
                    status: 'error',
                    title: 'Failed, Try again'
                })
            })
            .finally(() => {
                setIsProjectCloneDialogOpen(false)
                setSelectedProjectForClone(null)
                setCloneLoading(false)
            })
    }

    useEffect(() => {

        let fProjects = []

        console.log(projects)

        if (sortBy === 'status') {
            fProjects = projects.slice().sort((a, b) => a.isActivated ? -1 : 1)
        }

        if (sortBy === 'name') {
            fProjects = projects.slice().sort((a, b) => {
                if (a.settings?.name < b.settings?.name) { return -1; }
                if (a.settings?.name > b.settings?.name) { return 1; }
                return 0;
            })

        }

        if (sortBy === 'createdAt') {
            fProjects = projects.slice().sort((a, b) => {
                if (dayjs(a.createdAt).isBefore(dayjs(b.createdAt))) { return 1; }
                if (dayjs(a.createdAt).isAfter(dayjs(b.createdAt))) { return -1; }
                return 0;
            })
        }

        if (sortBy === 'updatedAt') {
            fProjects = projects.slice().sort((a, b) => {
                if (dayjs(a.updatedAt).isBefore(dayjs(b.updatedAt))) { return 1; }
                if (dayjs(a.updatedAt).isAfter(dayjs(b.updatedAt))) { return -1; }
                return 0;
            })
        }

        if (searchValue) {
            fProjects = fProjects.filter(p => p.settings?.name?.includes(searchValue))
        }

        setFilteredProjects(fProjects)

    }, [sortBy, projects, currentProjects, searchValue])


    return (
        <>
            <ProjectCreate isOpen={isTypeSelectionModalOpen} onClose={onIsTypeSelectionModalClose} />

            <CustomDialog actionButtonText={'Delete'} actionButtonColorScheme={'red'} isLoading={delLoading} isOpen={isProjectDeleteDialogOpen} onClose={() => setIsProjectDeleteDialogOpen(false)} title={'Delete Project?'} message={"Are you sure you want to delete this project ?"} onDeleteClick={onConfirmDelete} />
            <CustomDialog actionButtonText={'Clone'} actionButtonColorScheme={'blue'} isLoading={cloneLoading} isOpen={isProjectCloneDialogOpen} onClose={() => setIsProjectCloneDialogOpen(false)} title={'Clone Project?'} message={"Are you sure you want to clone this project ?"} onDeleteClick={onConfirmClone} />

            <Box px={'5'} py={'3'} bgColor={'gray.50'}>
                <Flex justifyContent={'space-between'}>

                    <HStack>
                        <Menu>
                            <MenuButton rounded={'full'} bgColor={'white'} as={Button} rightIcon={<AiOutlineArrowDown />}>
                                Sort By
                            </MenuButton>
                            <MenuList>
                                <MenuItem color={sortBy === 'status' ? 'brand.500' : ''} onClick={() => setSortBy('status')}>Status</MenuItem>
                                <MenuItem color={sortBy === 'name' ? 'brand.500' : ''} onClick={() => setSortBy('name')} >Name</MenuItem>
                                <MenuItem color={sortBy === 'createdAt' ? 'brand.500' : ''} onClick={() => setSortBy('createdAt')}>Created At</MenuItem>
                                <MenuItem color={sortBy === 'updatedAt' ? 'brand.500' : ''} onClick={() => setSortBy('updatedAt')}>Updated At</MenuItem>
                            </MenuList>
                        </Menu>
                    </HStack>


                    <Box>

                        <HStack>

                            <Button onClick={() => {
                                onIsTypeSelectionModalOpen()
                            }} rounded={'full'} colorScheme={'brand'} px={'6'}>
                                Add New
                            </Button>

                            <InputGroup>
                                {/* <InputLeftElement
                                    pointerEvents='none'
                                    children={
                                        <Center>
                                            <AiOutlineSearch fontSize={'20px'} />
                                        </Center>
                                    }
                                /> */}
                                <Input autoFocus value={searchValue} onChange={(e) => setSearchValue(e.target.value)} size="md" bgColor={'white'} rounded={'full'} placeholder={'Find Projects...'} />
                            </InputGroup>



                            <HStack ml={'5'}>

                                <IconButton
                                    variant={currentView === 'grid' ? 'solid' : 'outline'}
                                    colorScheme='primary'
                                    aria-label='Grid View'
                                    rounded={'full'}
                                    fontSize='20px'
                                    icon={<BsGrid3X3Gap />}
                                    onClick={() => {
                                        setCurrentView('grid')
                                        window.location.hash = '#grid'
                                    }}
                                />

                                <IconButton
                                    variant={currentView === 'list' ? 'solid' : 'outline'}
                                    colorScheme='primary'
                                    aria-label='List View'
                                    rounded={'full'}
                                    fontSize='20px'
                                    icon={<AiOutlineUnorderedList />}
                                    onClick={() => {
                                        setCurrentView('list')
                                        window.location.hash = '#list'
                                    }}

                                />



                            </HStack>

                        </HStack>



                    </Box>

                </Flex>
            </Box>


            {
                currentView === 'grid' ?
                    <CustomResponsiveGrid>
                        {
                            filteredProjects.map((project: any) => (
                                <GridItem key={project.id}>
                                    <ProjectDetailsCard
                                        color={project.displayColor}
                                        onCloneClick={() => onProjectCloneClick(project.id)}
                                        onDeleteClick={() => onProjectDeleteClick(project.id)}
                                        imageSrc={`${process.env.REACT_APP_API_URL}/${project.thumbnail?.url}`}
                                        onEditClick={() => navigate(routeNames.newProject(project.id))}
                                        date={dayjs(project.createdAt).format('DD/MM/YYYY')}
                                        title={project.settings?.name || 'Unnamed'} />
                                </GridItem>

                            ))
                        }




                    </CustomResponsiveGrid> :
                    <>
                        <TableContainer minH={'70vh'} m={'5'} bgColor={'white'}>
                            <Table variant='simple'>
                                <Thead>
                                    <Tr>
                                        <Th>#</Th>
                                        <Th>Status</Th>
                                        <Th>Name</Th>
                                        <Th>URL</Th>
                                        <Th>Created At</Th>
                                        <Th />
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        currentProjects.map((project: any, index: any) => (
                                            <Tr>
                                                <Td>{index + 1}</Td>
                                                <Td>
                                                    <Icon as={ImEarth} color={!project?.isActivated ? 'red.500' : 'green.500'} />
                                                </Td>
                                                <Td cursor={'pointer'} onClick={() => navigate(routeNames.newProject(project.id))}>{project.settings?.name || 'Unnamed'}</Td>
                                                <Td>
                                                    {project?.domains ? (<a target={'_blank'} href={project?.domains[0]?.value}>{project?.domains[0]?.value}</a>) : 'Not added'}</Td>
                                                <Td>{dayjs(project.createdAt).format('DD/MM/YYYY')}</Td>
                                                <Td>

                                                    <Menu>
                                                        <MenuButton as={IconButton} icon={<FiMoreVertical />} variant={'ghost'} />
                                                        <MenuList>
                                                            <MenuItem onClick={() => navigate(routeNames.newProject(project.id))}>Edit</MenuItem>
                                                            <MenuItem onClick={() => onProjectCloneClick(project.id)}>Clone</MenuItem>
                                                            <MenuItem color={'red.500'} onClick={() => onProjectDeleteClick(project.id)}>Delete</MenuItem>
                                                        </MenuList>
                                                    </Menu>

                                                </Td>
                                            </Tr>


                                        ))
                                    }

                                </Tbody>

                            </Table>
                        </TableContainer>

                        <CustomPagination onCurrentItemsChange={onCurrentProjectsChange} items={filteredProjects} itemsPerPage={10} />
                    </>
            }


        </>
    )
}
