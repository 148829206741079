import { fetchOrganizationTrafficSources } from "../../api/traffic-sources";
import { setIsTrafficSourcesLoading, setTrafficSources } from "../reducers/trafficSource";

export const initTrafficSourceState = () => async (dispatch: any, getState: any) => {

    const { organization: { currentSelectedOrganization } } = getState()

    const trafficSources = await fetchOrganizationTrafficSources(currentSelectedOrganization.id)
    dispatch(setTrafficSources(trafficSources))
    dispatch(setIsTrafficSourcesLoading(false))
}

