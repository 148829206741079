import { store } from "../store"
import { baseAxiosInstance } from "../config/axios"

export const setupOrganization = async (body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post('/organizations/setup', body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const fetchUserOrganizations = async () => {
    const token = store.getState().user.token

    return baseAxiosInstance.get('/organizations/user', {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const inviteUserToOrganization = async (organizationId: number, body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/organizations/${organizationId}/invite-user`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const fetchOrganizationInvites = async (organizationId: number) => {
    const token = store.getState().user.token

    return baseAxiosInstance.get(`/organization-invites/${organizationId}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const updateUser = async (id: number, body: any) => {
    const token = store.getState().user.token
    return baseAxiosInstance.put(`/users/${id}`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const removeUserFromOrganization = async (organizationId: number, email: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.get(`/organizations/${organizationId}/remove-user/${email}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const makeUserOwnerOfOrganization = async (organizationId: number, userId: number) => {
    const token = store.getState().user.token

    return baseAxiosInstance.get(`/organizations/${organizationId}/make-owner/${userId}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}


export const updateOrganization = async (organizationId: number, body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.put(`/organizations/${organizationId}`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}