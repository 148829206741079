import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface PluginState {
    plugins: any[]
    isPluginsLoading:boolean
}

const initialState: PluginState = {
    plugins:[],
    isPluginsLoading: true
}

export const pluginSlice = createSlice({
    name: 'plugin',
    initialState,
    reducers: {
        setPlugins: (state, action: PayloadAction<any>) => {
            state.plugins = action.payload
        },
        setIsPluginsLoading: (state, action: PayloadAction<boolean>) => {
            state.isPluginsLoading = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setPlugins , setIsPluginsLoading } = pluginSlice.actions

const pluginReducer = pluginSlice.reducer
export default pluginReducer