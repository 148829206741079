import { Box, Button, HStack, Input, Slide, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import CustomDropzone from '../../components/CustomDropzone'
import { CustomTabs } from '../../components/CustomTabs'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { GeneralTab } from './GeneralTab'
import { ProductDetailSidebar } from './ProductDetailSidebar'

export const ProductsPage = () => {

    const { isOpen, onClose, onOpen } = useDisclosure()

    return (
        <>
            <Slide direction='right' in={isOpen} style={{ zIndex: 10 }}>
                <ProductDetailSidebar onBackDropClick={onClose} /> : null
            </Slide>

            <DashboardLayout heading="Products" subHeading={'1 Products'}>
                <CustomTabs
                    tabs={[
                        'GENERAL',
                    ]}
                    panels={[
                        <GeneralTab />
                    ]}
                    rightContent={
                        <HStack px={'3'}>
                            <Input w={'fit-content'} size={'md'} bgColor={'white'} rounded={'full'} placeholder={'Find Products...'} />
                            <Button onClick={onOpen} rounded={'full'} colorScheme={'brand'}>Add</Button>
                        </HStack>}
                />



            </DashboardLayout>
        </>
    )
}
