import { Button, Checkbox, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { useState } from 'react'
import CustomDropzone from '../../components/CustomDropzone'

export const UploadAssetModal = ({ isOpen, onClose, onFilesSelected, processLoading }: any) => {

    const [files, setFiles] = useState<any[]>([])

    const onCancelClick = () => {
        setFiles([])
        onClose()
    }

    const onProcessClick = async () => {
        await onFilesSelected(files)
        onClose()
    }

    return (
        <Modal size={'xl'} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add Files</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {
                        files.length ?
                            <>
                                <Text fontWeight={'bold'}>{files.length} File Queued</Text>
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr>
                                                {/* <Th>Clear Queue</Th> */}
                                                <Th>Name</Th>
                                                <Th>File Size</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {/* <Td><Checkbox /></Td> */}
                                            {
                                                files.map((file , index) => (
                                                    <Tr key={index}>
                                                        <Td>{file.name}</Td>
                                                        <Td>{file.size}</Td>
                                                    </Tr>

                                                ))
                                            }
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </> :
                            <CustomDropzone onDrop={(files: any) => {
                                setFiles(files)
                            }}>
                                Drag and Drop Files Here
                            </CustomDropzone>
                    }

                </ModalBody>
                <ModalFooter>
                    <Button rounded={'full'} onClick={onCancelClick} colorScheme='gray' mr={3}>
                        Cancel
                    </Button>
                    <Button isLoading={processLoading} onClick={onProcessClick} rounded={'full'} colorScheme='brand'>Process</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
