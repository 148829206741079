import { configureStore } from '@reduxjs/toolkit'
import organizationReducer from './reducers/organization'
import pluginReducer from './reducers/plugin'
import projectReducer from './reducers/project'
import trafficSourceReducer from './reducers/trafficSource'
import userReducer from './reducers/user'

export const store = configureStore({
    reducer: {
        user: userReducer,
        organization: organizationReducer,
        project: projectReducer,
        trafficSource: trafficSourceReducer,
        plugin: pluginReducer,
    },
})
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch