import { Center, HStack, Text } from '@chakra-ui/react'
import React from 'react'

export const StatCountDisplay = ({ count, total }: any) => {
    return (

        <Center>
            <HStack>
                <Text fontSize={'5xl'}>{count}</Text>
                <Text pt={'6'} color={'gray.500'}>/ {total}</Text>
            </HStack>
        </Center>
    )
}
