import { baseAxiosInstance } from "../config/axios"
import { store } from "../store"

export const fetchActivityLogsOfOrganization = async (organizationId: any, { from, to, noOfRecords = "25", currentPage = "1", sortByTimestamp = 'asc' }: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/activity-logs/organization/${organizationId}?pagination[page]=${currentPage}&pagination[pageSize]=${noOfRecords}&sort[0]=createdAt:${sortByTimestamp}`, {
        from,
        to
    }, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}