export const routeNames = {
    home: () => '/',
    login: () => '/login',
    signup: () => '/signup',
    accountRequested: () => '/account-requested',
    projects: () => '/projects',
    products: () => '/products',
    plugins: () => '/plugins',
    trafficSources: () => '/traffic-sources',
    accountSettings: () => '/account-settings',
    newProject: (id = 'new') => `/projects/${id}`,
    profile: () => '/profile',
    onboarding: () => '/onboarding',
    forgotPassword: () => '/forgot-password',
    resetPassword: () => '/reset-password',
    billingManager: () => '/billing-manager',
    rerunOrdersView: () => '/rerun-orders',
    rerunOrderDetail:(id:any) => `/rerun-orders/${id}`,
    activityLogs: () => '/activity-logs'
}