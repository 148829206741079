import React from "react";
import {
    BrowserRouter,
    Routes,
    Route,
    Link
} from "react-router-dom";
import { AccountRequestedPage } from "../pages/AccountRequested";
import { AccountSettingsPage } from "../pages/AccountSettings";
import { ActivityLogsPage } from "../pages/ActivityLogs";
import { BillingManagerPage } from "../pages/BillingManager/Index";
import { EditProjectPage } from "../pages/EditProject";
import { ForgotPasswordPage } from "../pages/ForgotPassword";
import { HomePage } from "../pages/Home";
import { LoginPage } from "../pages/Login";
import { OnboardingPage } from "../pages/Onboarding";
import { PluginsPage } from "../pages/Plugins";
import { ProductsPage } from "../pages/Products";
import { ProfilePage } from "../pages/Profile";
import { ProjectsPage } from "../pages/Projects";
import { RerunOrderDetailPage } from "../pages/RerunOrderDetail";
import { RerunOrdersViewPage } from "../pages/RerunOrders";
import { ResetPasswordPage } from "../pages/ResetPassword";
import { SignupPage } from "../pages/Signup";
import { TrafficSourcesPage } from "../pages/TrafficSources";
import { ProtectedRoute } from "./ProtectedRoute";
import { routeNames } from "./routeNames";
import { UnprotectedRoute } from "./UnprotectedRoute";


const Router = () => {
    return (
        <Routes>
            <Route path={routeNames.login()} element={
                <UnprotectedRoute>
                    <LoginPage />
                </UnprotectedRoute>
            } />
            <Route path={routeNames.signup()} element={
                <UnprotectedRoute>
                    <SignupPage />
                </UnprotectedRoute>
            } />
            <Route path={routeNames.accountRequested()} element={
                <UnprotectedRoute>
                    <AccountRequestedPage />
                </UnprotectedRoute>
            } />

            <Route path={routeNames.forgotPassword()} element={
                <UnprotectedRoute>
                    <ForgotPasswordPage />
                </UnprotectedRoute>
            } />

            <Route path={routeNames.resetPassword()} element={
                <UnprotectedRoute>
                    <ResetPasswordPage />
                </UnprotectedRoute>
            } />


            <Route path={routeNames.trafficSources()} element={
                <ProtectedRoute>
                    <TrafficSourcesPage />
                </ProtectedRoute>
            } />

            <Route path={routeNames.onboarding()} element={
                <ProtectedRoute>
                    <OnboardingPage />
                </ProtectedRoute>
            } />


            <Route path={routeNames.accountSettings()} element={
                <ProtectedRoute>
                    <AccountSettingsPage />
                </ProtectedRoute>
            } />


            <Route path={routeNames.projects()} element={
                <ProtectedRoute>
                    <ProjectsPage />
                </ProtectedRoute>
            } />


            <Route path={routeNames.newProject(':id')} element={
                <ProtectedRoute>
                    <EditProjectPage />
                </ProtectedRoute>
            } />

            <Route path={routeNames.products()} element={
                <ProtectedRoute>
                    <ProductsPage />
                </ProtectedRoute>
            } />


            <Route path={routeNames.plugins()} element={
                <ProtectedRoute>
                    <PluginsPage />
                </ProtectedRoute>
            } />

            <Route path={routeNames.profile()} element={
                <ProtectedRoute>
                    <ProfilePage />
                </ProtectedRoute>
            } />

            <Route path={routeNames.home()} element={
                <ProtectedRoute>
                    <HomePage />
                </ProtectedRoute>
            } />

            <Route path={routeNames.billingManager()} element={
                <ProtectedRoute>
                    <BillingManagerPage />
                </ProtectedRoute>
            } />

            <Route path={routeNames.rerunOrdersView()} element={
                <ProtectedRoute>
                    <RerunOrdersViewPage />
                </ProtectedRoute>
            } />

            <Route path={routeNames.rerunOrderDetail(':id')} element={
                <ProtectedRoute>
                    <RerunOrderDetailPage />
                </ProtectedRoute>
            } />

            <Route path={routeNames.activityLogs()} element={
                <ProtectedRoute>
                    <ActivityLogsPage />
                </ProtectedRoute>
            } />

        </Routes>
    )
}

export default Router
