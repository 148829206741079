import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Grid, GridItem, Input, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { updateUser } from '../../api/organization';
import * as yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { initUserState } from '../../store/actions/user';


export const GeneralTab = () => {

    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch()

    const validationSchema = yup.object().shape({
        firstname: yup.string().required('Required'),
        lastname: yup.string().required('Required'),
        // phone: yup.string().required('Required'),
    })

    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const { user } = useAppSelector(state => state.user)

    useEffect(() => {
        if (user) {
            setValue('firstname', user.firstname)
            setValue('lastname', user.lastname)
            setValue('phoneNumber', user.phoneNumber)
        }
    }, [user])

    const onSaveClick = handleSubmit(async values => {

        setLoading(true)
        await updateUser(user.id, values)
        await dispatch(initUserState())
        setLoading(false)

    })

    return (
        <Box p={'0'}>

            <Box w={'50%'} bgColor={'white'} shadow={'md'} p={'5'}>
                <Text fontSize={'lg'} fontWeight={'bold'} mb={'3'}>Profile Details</Text>


                <Grid gap={'3'} templateColumns={'repeat(2,1fr)'}>
                    <GridItem>
                        <FormControl isInvalid={!!errors.firstname}>
                            <FormLabel>First Name</FormLabel>
                            <Input {...register('firstname')} />
                            <FormErrorMessage>{`${errors?.firstname?.message || ''}`}</FormErrorMessage>
                        </FormControl>
                    </GridItem>

                    <GridItem>
                        <FormControl isInvalid={!!errors.lastname}>
                            <FormLabel>Last Name</FormLabel>
                            <Input {...register('lastname')} />
                            <FormErrorMessage>{`${errors?.lastname?.message || ''}`}</FormErrorMessage>
                        </FormControl>
                    </GridItem>

                    <GridItem>
                        <FormControl>
                            <FormLabel>Email</FormLabel>
                            <Input value={user?.email} readOnly />
                        </FormControl>
                    </GridItem>

                    <GridItem>
                        <FormControl>
                            <FormLabel>Phone Number</FormLabel>
                            <Input {...register('phoneNumber')} />
                        </FormControl>
                    </GridItem>

                </Grid>

                <Flex mt={'5'} justifyContent={'flex-end'}>
                    <Button isLoading={loading} onClick={onSaveClick} rounded={'full'} colorScheme={'brand'}>Save</Button>
                </Flex>

            </Box>

        </Box>
    )
}
