import { Alert, AlertIcon, AlertTitle, Box, Center, FormControl, FormLabel, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Select, Spinner, Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { fetchScrubLogsOfProject } from '../../api/scrubLog'

export const LogsViewModal = ({ isOpen, onClose, logs = [] }: any) => {


    const [paginationMetaData, setPaginationMetaData] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)




    // const currentRuleLogs = logs.filter((log:any) => log.traffic_source?.id == rule?.byAffiliateSource)


    return (
        <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>View Logs</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {/* <Box>
                        <FormControl w={['full', '50%']}>
                            <FormLabel>Go To Page</FormLabel>
                            <Select w={'full'} bgColor={'white'} value={`${currentPage || 1}`} onChange={(e) => setCurrentPage(parseInt(e.target.value))}>
                                {
                                    Array(paginationMetaData?.pageCount || 1).fill('').map((v, i) => (
                                        <option value={`${i + 1}`}>{i + 1}</option>
                                    ))
                                }
                            </Select>
                        </FormControl>

                    </Box> */}

                    <Box h={'200px'} overflow="auto">
                        {
                            isLoading ? (
                                <Center my={'10'}>
                                    <Spinner />
                                </Center>
                            ) : (
                                <TableContainer>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr>
                                                <Th>No.#</Th>
                                                <Th>Action</Th>
                                                <Th>Rule</Th>
                                                {/* <Th>Affiliate</Th> */}
                                                <Th>Throttle</Th>
                                                <Th>Timestamp</Th>

                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {
                                                logs.map((log: any, index: any) => (
                                                    <Tr>
                                                        <Td>{index + 1}</Td>
                                                        <Td>{log.type}</Td>
                                                        <Td>{log.rule}</Td>
                                                        {/* <Td></Td> */}
                                                        <Td>{log.throttle}%</Td>
                                                        <Td>{dayjs(log.timestamp).format('DD/MM/YYYY hh:mm a')}</Td>

                                                    </Tr>
                                                ))
                                            }

                                        </Tbody>
                                    </Table>

                                    {
                                        !logs.length && (
                                            <Alert my={'5'} status='info'>
                                                <AlertIcon />
                                                <AlertTitle>No Logs Found</AlertTitle>
                                            </Alert>
                                        )
                                    }
                                </TableContainer>

                            )
                        }

                    </Box>






                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
