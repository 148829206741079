import { Box, Button, Flex, FormControl, FormLabel, Grid, GridItem, HStack, Input, Text, Textarea } from '@chakra-ui/react'
import React from 'react'
import CustomDropzone from '../../components/CustomDropzone'

export const ProductDetailSidebar = ({ onBackDropClick }: any) => {
    return (
        <>
            <Box onClick={onBackDropClick} position={'fixed'} top={0} left={0} bottom={0} right={0} bgColor={'gray.500'} zIndex={1} opacity={0.5}>
            </Box>
            <Flex
                overflow={'auto'}
                position={'fixed'}
                top={0}
                right={0}
                bottom={0}
                height="100vh"
                width={'500px'}
                direction="column"
                bg="white"
                zIndex={1}
            >
                <Box p={'5'}>
                    <Flex justifyContent={'space-between'}>
                        <Text fontSize={'xl'} fontWeight={'semibold'}>Edit Product</Text>
                    </Flex>
                </Box>

                <Box p={'5'}>


                    <Box w={'50%'}>
                        <CustomDropzone />
                    </Box>

                    <Text mt={'5'} mb={'2'} fontSize={'lg'}>General Settings</Text>
                    <Grid gap={3} templateColumns={'repeat(2,1fr)'}>
                        <GridItem>
                            <FormLabel>ID</FormLabel>
                            <Input />
                        </GridItem>

                        <GridItem>
                            <FormLabel>Product Name</FormLabel>
                            <Input />
                        </GridItem>

                        <GridItem>
                            <FormLabel>Slug Name</FormLabel>
                            <Input />
                        </GridItem>

                        <GridItem>
                            <FormLabel>Alt Name</FormLabel>
                            <Input />
                        </GridItem>

                        <GridItem>
                            <FormLabel>Price</FormLabel>
                            <Input />
                        </GridItem>

                        <GridItem>
                            <FormLabel>Shipping Price</FormLabel>
                            <Input />
                        </GridItem>

                        <GridItem>
                            <FormLabel>Max Quantity</FormLabel>
                            <Input />
                        </GridItem>

                    </Grid>

                    <Text mt={'5'} mb={'2'} fontSize={'lg'}>CRM Data</Text>

                    <Grid gap={3} templateColumns={'repeat(3,1fr)'}>
                        <GridItem>
                            <FormLabel>CRM ID</FormLabel>
                            <Input />
                        </GridItem>

                        <GridItem>
                            <FormLabel>Offer ID</FormLabel>
                            <Input />
                        </GridItem>

                        <GridItem>
                            <FormLabel>Billing Model ID</FormLabel>
                            <Input />
                        </GridItem>

                    </Grid>

                    <Text mt={'5'} mb={'2'} fontSize={'lg'}>Additional Settings</Text>

                    <FormControl mb={'3'}>
                        <FormLabel>Short Description</FormLabel>
                        <Textarea />
                    </FormControl>

                    <FormControl mb={'3'}>
                        <FormLabel>Long Description</FormLabel>
                        <Textarea />
                    </FormControl>

                    <FormControl mb={'3'}>
                        <FormLabel>Special Terms</FormLabel>
                        <Textarea />
                    </FormControl>

                    <Flex justifyContent={'flex-end'}>
                        <HStack spacing={'3'}>
                            <Button colorScheme={'red'} rounded={'full'}>Delete</Button>
                            <Button colorScheme={'brand'} rounded={'full'}>Save and Publish</Button>
                        </HStack>
                    </Flex>


                </Box>
            </Flex>
        </>
    )
}
