import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '../hooks/redux'
import { routeNames } from './routeNames'

export const ProtectedRoute = ({ children }: any) => {

    const { user, isUserLoading } = useAppSelector(state => state.user)

    const location = useLocation()

    if (isUserLoading)
        return <div>Loading...</div>

    if (!user)
        return <Navigate to={routeNames.login()} />

    if (!user.isAccountSetup && location.pathname !== routeNames.onboarding())
        return <Navigate to={routeNames.onboarding()} />

    if (location.pathname === routeNames.onboarding() && user.isAccountSetup)
        return <Navigate to={routeNames.home()} />

    return children

}
