import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks/redux'

export const AddRuleModal = ({ isOpen, onClose, rule, ruleIndex, onAddRule, onEditRule }: any) => {


    const isEdit = !!rule

    const { trafficSources } = useAppSelector(state => state.trafficSource)

    const [ruleType, setRuleType] = useState<'affiliate' | 'product' | 'sub-affiliate'>('affiliate')
    const [selectedSource, setSelectedSource] = useState<any>(null)
    const [throttle, setThrottle] = useState<any>('')
    const [s1, setS1] = useState('')
    const [threedsScrub, setThreedsScrub] = useState<'off' | 'secure' | 'non-secure'>('off')


    useEffect(() => {
        if (isEdit) {

            setRuleType(rule.rule)
            setSelectedSource(`${rule.byAffiliateSource}`)
            setThrottle(rule.throttle)
            setS1(rule.s1)
            setThreedsScrub(rule.threedsScrub)
        } else {
            setRuleType('affiliate')
            setThrottle('')
            setS1('')
            setSelectedSource(trafficSources[0]?.id?.toString())
            setThreedsScrub('off')

        }
    }, [isEdit, rule, trafficSources])

    const onSaveClick = () => {

        if (!isEdit) {
            onAddRule({
                ruleType,
                selectedSource,
                threedsScrub,
                throttle,
                s1
            })
        } else {
            onEditRule(ruleIndex, {
                ruleType,
                selectedSource,
                threedsScrub,
                throttle,
                s1
            })
        }

        onClose()
    }



    return (
        <Modal size={'sm'} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Conversation Rule Setup</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={'3'}>
                        <FormLabel>Rule</FormLabel>
                        <Select onChange={(e: any) => setRuleType(e.target.value)} value={ruleType} rounded={'none'}>
                            <option value="affiliate">By Affiliate</option>
                            <option value="sub-affiliate">By Sub Affiliate</option>

                            {/* <option value="product">By Product</option> */}
                        </Select>
                    </FormControl>

                    <FormControl mb={'3'}>
                        <FormLabel>Source</FormLabel>
                        <Select value={selectedSource} onChange={(e) => setSelectedSource(e.target.value)} rounded={'none'}>
                            {
                                trafficSources.map(source => (
                                    <option key={source.id} value={source.id}>{source.name}</option>
                                ))
                            }
                        </Select>
                    </FormControl>

                    <FormControl mb={'3'}>
                        <FormLabel>Three DS Scrub</FormLabel>
                        <Select value={threedsScrub} onChange={(e: any) => setThreedsScrub(e.target.value)} rounded={'none'}>

                            <option value="off">Off</option>
                            <option value="secure">Secure</option>
                            <option value="non-secure">Non Secure</option>

                        </Select>
                    </FormControl>

                    {
                        ruleType === 'sub-affiliate' ?
                            (
                                <FormControl mb={'3'}>
                                    <FormLabel>s1/c1/pub Id</FormLabel>
                                    <Input value={s1} onChange={(e) => setS1(e.target.value)} rounded={'none'} />
                                </FormControl>
                            ) : null
                    }


                    <FormControl mb={'3'}>
                        <FormLabel>Percentage %</FormLabel>
                        <Input value={throttle} onChange={(e) => setThrottle(e.target.value)} rounded={'none'} type={'number'} />
                    </FormControl>

                </ModalBody>
                <ModalFooter>
                    <Button onClick={onSaveClick} rounded={'full'} colorScheme='brand'>Save</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
