import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
} from '@chakra-ui/react'
import * as React from 'react'
import { FaFacebook, FaGoogle } from 'react-icons/fa'
import { Logo } from './Logo'
import { Form } from './Form'
import { Link } from 'react-router-dom'
import { DividerWithText } from '../DividerWithText'
import { AiOutlineCheck } from 'react-icons/ai'

export const SignupForm: React.FC = () => {
  return (
    <Box minH="100vh" bg={{ md: mode('gray.100', 'inherit') }}>
      <Box maxW="6xl" mx="auto" py={{ base: '10', md: '20' }} px={{ base: '4', md: '10' }}>
        <Center>
          <Box w="full" maxW="xl" mx="auto">
            <Box
              bg={{ md: mode('white', 'gray.700') }}
              p={{ base: '4', md: '12' }}
              borderWidth={{ md: '1px' }}
              borderColor={mode('gray.200', 'transparent')}
              shadow={{ md: 'md' }}
            >
              <Box mb="8" textAlign={{ base: 'center', md: 'start' }}>
                <Heading size="lg" mb="2" fontWeight="extrabold">
                  Welcome to Super Funnels
                </Heading>
                <Text fontSize="lg" color={mode('gray.600', 'gray.400')} fontWeight="medium">
                  Enter your info to get started
                </Text>
              </Box>
              {/* <Stack spacing="4">
                <Button variant="outline" leftIcon={<Box as={FaGoogle} color="red.500" />}>
                  Sign up with Google
                </Button>
              </Stack> */}

              {/* <DividerWithText>or</DividerWithText> */}
              <Form />
            </Box>

            <Text mt="8" align="center" fontWeight="medium">
              Already have an account?{' '}
              <Box
                as={Link}
                to="/login"
                color={mode('blue.600', 'blue.200')}
                display={{ base: 'block', md: 'inline-block' }}
              >
                Log in
              </Box>
            </Text>
          </Box>
        </Center>
        <SimpleGrid columns={{ base: 1, lg: 2 }} spacing="14">

          {/* 
          <Flex direction="column" py='12' display={{ base: 'none', lg: 'flex' }}>

            <Text mb={'5'} color={'brand.500'} fontWeight={'bold'} fontSize={'3xl'}>Create your account. <br /> The first site is on us!</Text>
            <Text color={'gray.500'} fontWeight={'semibold'} mb={'5'}>Let Subscribe Funnels handle the complexities of coding a website and integrating into your CRM!</Text>
            <Text mb={'5'} fontWeight={'semibold'} color={'gray.700'}>START FOR FREE AND SAVE TIME WITH SUBSCRIBE FUNNELS!</Text>

            <CheckPoint>1000s of hosted websites & over $100M processed</CheckPoint>
            <CheckPoint>Prebuilt templates</CheckPoint>
            <CheckPoint>Full suite of plugins</CheckPoint>
            <CheckPoint>FULL Konnektive, LimelightCRM & ResponseCRM integration</CheckPoint>
            <CheckPoint>Unlimited website possibilites</CheckPoint>


            <Text my={'5'} fontWeight={'semibold'} fontSize={'xl'}>No contracts. Downgrade or cancel your account anytime...</Text>

          </Flex> */}
        </SimpleGrid>
      </Box>
    </Box>
  )
}

const CheckPoint = ({ children }: any) => (
  <HStack alignItems={'center'} spacing={'2'}>
    <AiOutlineCheck color='green.500' />
    <Text color={'gray.500'} fontWeight={'semibold'} mb={'5'}>{children}</Text>
  </HStack>
)