import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React from 'react'
import { DateRangePicker } from 'react-date-range';

export default function DateTimePickerModal({ isOpen, onClose , selectedRange , onRangeChange}: any) {


    const handleSelect = (ranges: any) => {
        console.log(ranges);
        onRangeChange(ranges.selection)
    }

    return (
        <Modal size={'2xl'} isOpen={isOpen} onClose={onClose}>

            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Pickup Date Range</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Box p={'1'} borderColor={'brand.500'}>

                        <DateRangePicker
                            ranges={[selectedRange]}
                            onChange={handleSelect}
                        />
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>

    )
}
