import { Center, Spinner } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../hooks/redux'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { routeNames } from '../../router/routeNames'
import { NoProjectsView } from './NoProjectsView'
import { ProjectsView } from './ProjectsView'

export const ProjectsPage = () => {

  const { projects, isProjectsLoading } = useAppSelector(state => state.project)
  const navigate = useNavigate()

  return (
    <DashboardLayout heading="Projects" subHeading={`${projects.length} Projects`}>


      {
        isProjectsLoading ?
          <Center h={'100vh'}>
            <Spinner />
          </Center> :
          projects.length ?
            <ProjectsView /> :
            <NoProjectsView />
      }
    </DashboardLayout>
  )
}
