import { Button, Center, Divider, Flex, FormControl, FormLabel, HStack, Image, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Slide, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { activatePlugin } from '../../api/plugin'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { initOrganizationState } from '../../store/actions/organization'
import { PluginDetailSidebar } from './PluginDetailSidebar'
const img = require('../../assets/images/plug.png')

export const PluginDetailModal = ({ selectedPluginId, isOpen, onClose }: any) => {


    const dispatch = useAppDispatch()

    const [isDetailsSidebarOpen, setIsDetailsSidebarOpen] = useState(false)

    const [activateLoading, setActivateLoading] = useState(false)

    const { plugin: { plugins }, organization: { currentSelectedOrganization } } = useAppSelector(state => state)

    const plugin = plugins.find(plugin => plugin.id == selectedPluginId)
    const isActivated = currentSelectedOrganization?.activatedPlugins.find((p: any) => p.plugin.id == selectedPluginId)

    const onActivateClick = async () => {

        if (!isActivated) {

            console.log(selectedPluginId , currentSelectedOrganization?.id)
            setActivateLoading(true)
            await activatePlugin(selectedPluginId, currentSelectedOrganization?.id)
                .then(response => {
                    setIsDetailsSidebarOpen(true)
                    dispatch(initOrganizationState())
                })
                .catch(err => console.log(err))
                .finally(() => {
                    onClose()
                    setActivateLoading(false)
                })
        } else {
            onClose()
            setIsDetailsSidebarOpen(true)
        }

    }

    return (
        <>

            <Slide direction='right' in={isDetailsSidebarOpen} style={{ zIndex: 10 }}>
                <PluginDetailSidebar onClose={() => setIsDetailsSidebarOpen(false)} selectedPluginId={selectedPluginId} onBackDropClick={() => setIsDetailsSidebarOpen(false)} />
            </Slide>

            <Modal size={'md'} isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalBody p={'5'}>

                        <Center p={'5'} mb={'3'}>
                            <Image w={'150px'} src={img} />
                        </Center>
                        <Text mb={'3'} textAlign={'center'} fontWeight={'semibold'}>{plugin?.attributes.name}</Text>
                        <Text color={'gray.500'} textAlign={'center'} fontWeight={'semibold'}>{plugin?.attributes.description}</Text>

                        <Center mt={'5'}>
                            <Flex justifyContent={'center'}>

                                <HStack>
                                    <Text fontWeight={'semibold'}>Developer:</Text>
                                    <Text color={'gray.500'}>{plugin?.attributes.developer}</Text>
                                </HStack>

                                <Divider px={'2'} orientation='vertical' />

                                <HStack>
                                    <Text fontWeight={'semibold'}>Version:</Text>
                                    <Text color={'gray.500'}>{plugin?.attributes.version}</Text>
                                </HStack>

                            </Flex>
                        </Center>

                    </ModalBody>
                    <ModalFooter>
                        <Button rounded={'full'} mr={'3'} onClick={() => onClose()} >Cancel</Button>
                        <Button isLoading={activateLoading} onClick={onActivateClick} rounded={'full'} colorScheme={isActivated ? 'whatsapp' : 'brand'}>{isActivated ? 'Activated' : 'Activate'}</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

        </>

    )
}
