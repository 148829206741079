import { Box, Button, Center, Divider, Flex, Grid, GridItem, HStack, Image, Input, InputGroup, InputLeftElement, Menu, MenuButton, MenuItem, MenuList, Spinner, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import { AiFillCopy, AiOutlineArrowDown, AiOutlineClockCircle, AiOutlineSearch } from 'react-icons/ai'
import { FiMoreVertical } from 'react-icons/fi'

const templateImage = require('../assets/images/template.PNG')

export const ProjectDetailsCard = ({ imageSrc, color, onEditClick, title, subTitle, date, onDeleteClick, onCloneClick }: any) => {

    const [isDelLoading, setIsDelLoading] = useState(false)

    return (
        <Box display={'flex'} flexDir={'column'} _hover={{ shadow: 'xl' }} h={'full'} shadow={'md'} bgColor={'white'}>
            <Box bgColor={color} h={'15px'}>
                {/* <Image src={'https://placebear.com/640/360'} /> */}
            </Box>
            <Box flex={1} px={'5'} py={'2'}>
                <Text fontSize={'xl'} fontWeight={'semibold'} >{title}</Text>
                <Text color={'brand.500'}>{subTitle}</Text>
            </Box>

            <HStack px={'5'} py={'2'} mt={'3'} alignItems={'center'}>
                <AiOutlineClockCircle color={'gray.500'} />
                <Text fontSize={'sm'} fontWeight={'semibold'} color={'gray.500'}>{date}</Text>
            </HStack>

            <Divider orientation='horizontal' />

            {
                isDelLoading ?
                    <Center>
                        <Spinner />
                    </Center> :

                    <Flex px={'5'} py={'2'} justifyContent={'space-between'}>

                        <HStack >
                            <Button colorScheme={'brand'} onClick={onEditClick} rounded={'full'}>Edit</Button>
                            <Button onClick={onCloneClick} rounded={'full'}>
                                <AiFillCopy />
                            </Button>
                        </HStack>


                        <Menu>
                            <MenuButton variant={'ghost'} rounded={'full'} as={Button}>
                                <FiMoreVertical />
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={async () => {
                                    setIsDelLoading(true)
                                    await onDeleteClick()
                                    setIsDelLoading(false)
                                }} color={'red.500'}>
                                    Delete
                                </MenuItem>
                            </MenuList>
                        </Menu>


                    </Flex>
            }

        </Box>

    )
}
