import { Flex, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React from 'react'

export const CustomTabs = ({ onTabChange, tabs = [], panels = [], rightContent }: any) => {
    return (
        <Tabs onChange={onTabChange} variant={'unstyled'}>

            <Flex justifyContent={'space-between'} alignItems={'center'} bgColor={'gray.50'}>
                <TabList fontWeight={'bold'} color={'gray.500'} >
                    {
                        tabs.map((tab: any, index: any) => <TabHeader key={index}>{tab}</TabHeader>)
                    }
                </TabList>
                {rightContent}
            </Flex>

            <TabPanels>
                {
                    panels.map((panel: any , index:number) => (
                        <TabPanel key={index}>
                            {panel}
                        </TabPanel>
                    ))
                }
            </TabPanels>
        </Tabs>
    )
}


const TabHeader = ({ children }: any) => (
    <Tab px={'5'} py={'4'} _selected={{ fontWeight: 'semibold', borderBottomWidth: '3px', borderColor: 'brand.500' }}>{children}</Tab>

)