import { baseAxiosInstance } from "../config/axios"

export const loginUser = async (body: any) => {
    return baseAxiosInstance.post('/auth/local', body)
        .then(async response => {
            localStorage.setItem('user', JSON.stringify(response.data))
            return response.data
        })
        .catch(err => {
            throw err
        })
}

export const registerUser = async (body: any) => {
    return baseAxiosInstance.post('/auth/local/register', body)
        .then(async response => {
            return response.data
        })
        .catch(err => {
            throw err
        })
}

export const forgotPassword = async (body: any) => {
    return baseAxiosInstance.post('/auth/forgot-password', body)
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const resetPassword = async (body: any) => {
    return baseAxiosInstance.post('/auth/reset-password', body)
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}
