import { baseAxiosInstance } from "../config/axios"
import { store } from "../store"

export const createPixel = async (body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/pixels`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const updatePixel = async (id: any, body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.put(`/pixels/${id}`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const deletePixel = async (id: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.delete(`/pixels/${id}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}