import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface TrafficSourceState {
    trafficSources: any[]
    isTrafficSourcesLoading:boolean
}

const initialState: TrafficSourceState = {
    trafficSources:[],
    isTrafficSourcesLoading: true
}

export const trafficSourceSlice = createSlice({
    name: 'trafficSource',
    initialState,
    reducers: {
        setTrafficSources: (state, action: PayloadAction<any>) => {
            state.trafficSources = action.payload
        },
        setIsTrafficSourcesLoading: (state, action: PayloadAction<boolean>) => {
            state.isTrafficSourcesLoading = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setTrafficSources , setIsTrafficSourcesLoading } = trafficSourceSlice.actions

const trafficSourceReducer = trafficSourceSlice.reducer
export default trafficSourceReducer