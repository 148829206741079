import React, { useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Input,
    Checkbox,
    useToast,
    FormErrorMessage,
} from '@chakra-ui/react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { inviteUserToOrganization } from '../../api/organization'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { initOrganizationState } from '../../store/actions/organization'


export const NewUserModal = ({ isOpen, onClose }: any) => {

    const { currentSelectedOrganization } = useAppSelector(state => state.organization)
    const dispatch = useAppDispatch()
    const toast = useToast()

    const [loading, setLoading] = useState(false)

    const validationSchema = yup.object().shape({
        email: yup.string().required('Required').email('Invalid Email')
    })

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onInviteClick = handleSubmit((values) => {
        setLoading(true)
        inviteUserToOrganization(currentSelectedOrganization?.id, {
            email: values.email
        })
            .then(response => {
                dispatch(initOrganizationState())
                toast({
                    status: 'success',
                    title: 'Invitation Sent',
                })
                onClose()
            })
            .catch(err => {
                toast({
                    status: 'error',
                    title: 'Invitation Failed',
                    description: err?.response?.data?.error?.message || 'Try again'
                })
            })
            .finally(() => {
                setLoading(false)
            })
    })

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Invite New User</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text color={'gray.500'}>Email</Text>
                    <Input isInvalid={!!errors.email} {...register('email')} mb={'2'} borderRadius={'none'} />
                    <FormErrorMessage>{`${errors.email?.message || ''}`}</FormErrorMessage>
                </ModalBody>

                <ModalFooter>
                    <Button rounded={'full'} onClick={onClose} colorScheme='gray' mr={3}>
                        Cancel
                    </Button>
                    <Button isLoading={loading} onClick={onInviteClick} rounded={'full'} colorScheme='brand'>Invite</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
