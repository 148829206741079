import { Box, Button, Center, Flex, FormControl, FormLabel, Grid, GridItem, Heading, HStack, Input, ListItem, OrderedList, Select, Switch, Tab, Table, TableContainer, TabList, TabPanel, TabPanels, Tabs, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { CustomHeaderCard } from '../../components/CustomHeaderCard'
import { StatCountDisplay } from '../../components/StatCountDisplay'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { FiMoreVertical } from 'react-icons/fi'
import { UserDetailCard } from '../../components/UserDetailCard'
import { CreateSSLModal } from './CreateSSLModal'
import { CustomTabs } from '../../components/CustomTabs'
import { CustomResponsiveGrid } from '../../components/CustomResponsiveGrid'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { NewUserModal } from './NewUserModal'
import { makeUserOwnerOfOrganization, removeUserFromOrganization, updateOrganization } from '../../api/organization'
import { initOrganizationState } from '../../store/actions/organization'
import { initUserState } from '../../store/actions/user'

export const AccountSettingsPage = () => {

  const [rightContent, setRightContent] = useState<any>(null)

  const { organization: { currentSelectedOrganization }, user: { user } } = useAppSelector(state => state)

  const isOwner = currentSelectedOrganization?.owner?.id == user?.id

  const {
    isOpen: isNewUserModalOpen,
    onClose: onNewUserModalClose,
    onOpen: onNewUserModalOpen
  } = useDisclosure()


  const tabs = isOwner ? [
    'GENERAL',
    'USERS',
    // 'SSL/TLS',
    // 'BILLING',
    // 'ACTIVITY LOGS'
  ] : ['ACTIVITY LOGS']

  const tabPanels = isOwner ? [
    <GeneralTab />,
    <UsersTab onNewUserModalClose={onNewUserModalClose} isNewUserModalOpen={isNewUserModalOpen} />,
    <SSLTab />,
    <BillingTab />,
    <ActivityTab />
  ] : [
    <ActivityTab />
  ]


  return (
    <DashboardLayout heading="Account Settings" >


      <CustomTabs onTabChange={(index: number) => {
        if (index === 1)
          setRightContent(
            <Box px={'3'}>
              {/* <Button onClick={onNewUserModalOpen} rounded={'full'} colorScheme={'brand'}>New User</Button> */}
            </Box>
          )
        else
          setRightContent(null)
      }}
        tabs={tabs}
        rightContent={rightContent}
        panels={tabPanels}
      />

    </DashboardLayout>
  )
}

const GeneralTab = () => {

  const [kpLoginId, setKpLoginId] = useState('')
  const [kpPassword, setKpPassword] = useState('')
  const [kpOrderVaultKey, setKpOrderVaultKey] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const { currentSelectedOrganization } = useAppSelector(state => state.organization)
  const dispatch = useAppDispatch()

  const toast = useToast()

  useEffect(() => {
    if (currentSelectedOrganization) {
      setKpLoginId(currentSelectedOrganization?.konnektivePlugin?.loginId || '')
      setKpPassword(currentSelectedOrganization?.konnektivePlugin?.password || '')
      setKpOrderVaultKey(currentSelectedOrganization?.konnektivePlugin?.orderVaultKey || '')
    }

  }, [currentSelectedOrganization])

  const onSaveClick = async () => {

    setIsLoading(true)

    const body = {
      data: {
        konnektivePlugin: {
          loginId: kpLoginId,
          password: kpPassword,
          orderVaultKey: kpOrderVaultKey
        }
      }
    }

    await updateOrganization(currentSelectedOrganization?.id, body)
      .then(response => {
        dispatch(initOrganizationState())
      })
      .catch(err => {

      })

    toast({
      status: 'success',
      title: 'Details Updated'
    })

    setIsLoading(false)

  }

  return (
    <>
      <Grid templateColumns='repeat(2, 1fr)' gap={6}>
        <GridItem w='100%'>

          <Grid mb={'5'} gap={'5'} templateColumns={'repeat(2,1fr)'}>
            <GridItem>
              <CustomHeaderCard heading={'Projects'} >
                <StatCountDisplay count={1} total={100} />
              </CustomHeaderCard>
            </GridItem>

            <GridItem>
              <CustomHeaderCard heading={'Clicks'}>
                <StatCountDisplay count={0} total={'1000'} />
              </CustomHeaderCard>
            </GridItem>
          </Grid>

          <CustomHeaderCard heading={'Account Summary'}>

            <FormControl mb={'2'}>
              <FormLabel color={'gray.500'}>Company Name</FormLabel>
              <Input borderRadius={0} bgColor={'white'} />
            </FormControl>


            <FormControl mb={'2'}>
              <FormLabel>Phone</FormLabel>
              <Input borderRadius={0} bgColor={'white'} />
            </FormControl>


            <FormControl mb={'2'}>
              <FormLabel>Created Date</FormLabel>
              <Input borderRadius={0} bgColor={'white'} />
            </FormControl>

          </CustomHeaderCard>
          <Box p={'5'} bgColor={'white'}>
            <Flex flexDirection={'row'} justifyContent={'flex-end'}>
              <Button isLoading={isLoading} borderRadius={'full'} onClick={onSaveClick} colorScheme={'brand'}>Save</Button>
            </Flex>
          </Box>
        </GridItem>

        <GridItem w='100%'>

          <CustomHeaderCard heading={'Premium Plugins'}>

            <TableContainer>
              <Table variant='simple'>
                <Thead>
                  <Tr>
                    <Th>Plugin</Th>
                    <Th>Price</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td>IPQS Plugin</Td>
                    <Td>$1500/month</Td>
                    <Td>
                      <FiMoreVertical cursor={'pointer'} fontSize={'20px'} />
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>

          </CustomHeaderCard>

          <Box mb={'5'} />


          <CustomHeaderCard heading={'Konnektive Plugin Settings'}>

            <FormControl mb={'2'}>
              <FormLabel color={'gray.500'}>Login Id</FormLabel>
              <Input value={kpLoginId} onChange={(e) => setKpLoginId(e.target.value)} borderRadius={0} bgColor={'white'} />
            </FormControl>


            <FormControl mb={'2'}>
              <FormLabel color={'gray.500'}>Password</FormLabel>
              <Input value={kpPassword} type={showPassword ? "text" : "password"} onChange={(e) => setKpPassword(e.target.value)} borderRadius={0} bgColor={'white'} />
            </FormControl>


            <FormControl mb={'2'}>
              <FormLabel color={'gray.500'}>Order Vault Key (Salutaris)</FormLabel>
              <Input value={kpOrderVaultKey} onChange={(e) => setKpOrderVaultKey(e.target.value)} borderRadius={0} bgColor={'white'} />
            </FormControl>

            <Flex flexDirection={'row'} justifyContent={'flex-end'}>
              <Button isLoading={isLoading} borderRadius={'full'} onClick={() => setShowPassword(!showPassword)} colorScheme={'brand'}>{showPassword ? "Hide Password" : "Show Password"}</Button>
            </Flex>
          </CustomHeaderCard>

        </GridItem>
      </Grid>

    </>

  )
}

const UsersTab = ({ isNewUserModalOpen, onNewUserModalClose }: any) => {

  const dispatch = useAppDispatch()
  const { organizationInvitations, currentSelectedOrganization } = useAppSelector(state => state.organization)


  const roleOptions = [
    {
      label: 'User',
      value: 'user'
    },

    {
      label: 'Owner',
      value: 'owner'
    }
  ]

  const onUserRemoveClick = (email: string) => {
    removeUserFromOrganization(currentSelectedOrganization.id, email)
      .then(response => {
        dispatch(initOrganizationState())
      })
      .catch(err => {
        console.log(err)
      })
  }

  const users = useMemo(() => {

    const updatedUsers: {
      id?: number
      email: string
      fullname: string
      phoneNumber: string
      role: 'owner' | 'user'
      type: 'invited' | 'system-user'
    }[] = []

    const orgUsers = currentSelectedOrganization?.users?.map((user: any) => ({
      id: user.id,
      email: user.email,
      fullname: `${user.firstname} ${user.lastname}`,
      phoneNumber: user.phoneNumber,
      role: 'user',
      type: 'system-user'
    })) || []

    const orgInv = organizationInvitations?.map((invitation: any) => ({
      email: invitation.email,
      fullname: '',
      phoneNumber: '',
      role: 'user',
      type: 'invited'
    }))

    if (currentSelectedOrganization) {
      updatedUsers.push({
        id: currentSelectedOrganization.owner.id,
        email: currentSelectedOrganization.owner.email,
        fullname: `${currentSelectedOrganization.owner.firstname} ${currentSelectedOrganization.owner.lastname}`,
        phoneNumber: currentSelectedOrganization.phoneNumber,
        role: 'owner',
        type: 'system-user'
      })
    }

    return [...updatedUsers, ...orgUsers, ...orgInv]

  }, [currentSelectedOrganization, organizationInvitations])

  const onRoleOptionValueChange = (value: any, user: any) => {

    if (value === 'owner') {
      makeUserOwnerOfOrganization(currentSelectedOrganization.id, user.id)
        .then((response) => {
          dispatch(initOrganizationState())
          dispatch(initUserState())
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  return (
    <>
      <NewUserModal isOpen={isNewUserModalOpen} onClose={onNewUserModalClose} />
      <CustomResponsiveGrid p={'0'}>

        {
          users.map((user, index) => {
            return (
              <GridItem key={index} w='100%'>
                <UserDetailCard onRoleOptionChange={(e: any) => onRoleOptionValueChange(e.target.value, user)} isRoleOptionsDisabled={user.role === 'owner' || user.type === 'invited'} selectedRoleOption={user.role} onRemoveClick={() => onUserRemoveClick(user.email)} isRemoveEnabled={user.role !== 'owner'} roleOptions={roleOptions} heading={user.fullname} detail={user.email} detail2={user.phoneNumber} />
              </GridItem>
            )
          })
        }


      </CustomResponsiveGrid>

    </>
  )
}

const SSLTab = () => {

  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <CreateSSLModal isOpen={isOpen} onClose={onClose} />

      <Text mb={'5'} fontWeight={'bold'} fontSize={'xl'}>SSL Certificates</Text>

      <Grid templateColumns='repeat(2, 1fr)' gap={6}>

        <GridItem w='100%'>
          <Text fontWeight={'semibold'} color={'gray.500'} mb={'5'}>ISNTRUCTIONS IF NOT USING CLOUD FARE</Text>

          <OrderedList mb={'5'}>
            <ListItem color={'gray.500'}>Add a new SSL Certificate by clicking the purple “+ New SSL”</ListItem>
            <ListItem color={'gray.500'}>Point the root URL and any subdomains to a CNAME record for dns.subscribefunnels.com</ListItem>
          </OrderedList>

          <Text color={'gray.500'}>NOTE: CNAME record for root does not work on some registrars (Godaddy and Epik Domains) and therefore can not be added as wildcards.</Text>

        </GridItem>

        <GridItem w='100%'>
          <Text fontWeight={'semibold'} color={'gray.500'} mb={'5'}>ISNTRUCTIONS IF USING CLOUD FARE</Text>

          <OrderedList mb={'5'}>
            <ListItem color={'gray.500'}>Point the DNS record for any subdomains to a CNAME record for cname.subscribefunnels.com</ListItem>
          </OrderedList>
        </GridItem>

      </Grid>



      <Center mt={'10'}>

        <Box w={'60%'}>

          <Flex mb={'3'} justifyContent={'flex-end'}>
            <Button onClick={onOpen} rounded={'full'} colorScheme={'brand'}>New SSL</Button>
          </Flex>


          <Table bgColor={'white'} variant='simple'>
            <Thead>
              <Tr>
                <Th>Domain</Th>
                <Th>Wildcard</Th>
                <Th>SSL/TLS Status</Th>
              </Tr>
            </Thead>

          </Table>

        </Box>

      </Center>
    </>
  )
}

const BillingTab = () => {
  return (
    <>
      <Grid templateColumns='repeat(2, 1fr)' gap={6}>

        <GridItem>
          <CustomHeaderCard heading={'Upgrade Plan'}>
            <Flex justifyContent={'space-between'} alignItems={'flex-end'}>
              <Box>
                <HStack>
                  <Text color={'gray.500'} fontSize={'lg'}>You are currently on </Text>
                  <Text color={'gray.500'} fontSize={'lg'} fontWeight={'semibold'}>Free Plan</Text>
                </HStack>
                <Text color={'gray.500'} fontSize={'lg'}>Upgrade today to add unlimited sites and clicks! </Text>
              </Box>

              <Button colorScheme={'brand'} rounded={'full'}>Upgrade</Button>
            </Flex>

          </CustomHeaderCard>

        </GridItem>
      </Grid>

      <Center mt={'5'}>
        <Switch>Auto upgrade</Switch>
      </Center>
    </>
  )
}

const ActivityTab = () => {
  return (
    <>
    </>
  )
}

