import { Alert, AlertIcon, AlertTitle, Box, Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AiOutlineMore } from 'react-icons/ai'
import { CustomDialog } from '../../components/CustomDialog'
import { CustomReactMarkdown } from '../../components/CustomReactMarkdown'
import { NewTokenModal } from './NewTokenModal'

export const TokensTab = ({ project, data }: any) => {

    const { isOpen, onClose, onOpen } = useDisclosure()

    const [tokens, setTokens] = useState<{
        key: string
        value: string
    }[]>([])

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [selectedTokenForDelete, setSelectedTokenForDelete] = useState<any>(null)
    const [selectedTokenForEdit, setSelectedTokenForEdit] = useState<any>(null)




    useEffect(() => {

        if (project) {
            setTokens(project?.attributes?.tokens.map((token: any) => ({ key: token.key, value: token.value })) || [])
        }

    }, [project])

    useEffect(() => {
        data.tokens = tokens
    })


    const onAddNewToken = (newToken: any) => {
        setTokens((prevTokens) => [...prevTokens, newToken])
    }

    const onEditNewToken = (index: any, updatedToken: any) => {
        const updatedTokens = [...tokens]
        updatedTokens[index] = updatedToken
        setTokens(updatedTokens)
    }

    const onDeleteTokenClick = (tokenIndex: number) => {
        setIsDeleteDialogOpen(true)
        setSelectedTokenForDelete(tokenIndex)
    }

    const onEditTokenClick = (tokenIndex: number) => {
        setSelectedTokenForEdit(tokenIndex)
        onOpen()
    }

    const onConfirmDelete = () => {
        const filteredTokens = tokens.filter((token, index) => index !== selectedTokenForDelete)
        setTokens(filteredTokens)
        setSelectedTokenForDelete(null)
        setIsDeleteDialogOpen(false)
    }

    return (
        <>
            <CustomDialog actionButtonText={'Delete'} actionButtonColorScheme={'red'} isOpen={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)} title={'Delete Token?'} message={"Are you sure you want to delete this token ?"} onDeleteClick={onConfirmDelete} />
            <NewTokenModal tokens={tokens} index={selectedTokenForEdit} selectedToken={tokens[selectedTokenForEdit]} onEditNewToken={onEditNewToken} onAddNewToken={onAddNewToken} isOpen={isOpen} onClose={onClose} />
            <Box>

                <Flex justifyContent={'space-between'} alignItems={'center'} mb={'3'}>
                    <Text fontWeight={'bold'} fontSize={'xl'}>Tokens</Text>
                    <Button onClick={() => {
                        setSelectedTokenForEdit(null)
                        onOpen()
                    }} rounded={'full'} colorScheme={'brand'} >New Token</Button>
                </Flex>


                <Table bgColor={'white'}>
                    <Thead>
                        <Tr>
                            <Th>Key</Th>
                            <Th>Value</Th>
                            <Th />
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            tokens.map((token, index) => (
                                <Tr key={index}>
                                    <Td fontWeight={'semibold'}>{token.key}</Td>
                                    <Td fontWeight={'semibold'}>
                                        <CustomReactMarkdown>
                                            {token.value.substring(0, 100) + "..."}
                                        </CustomReactMarkdown>
                                    </Td>
                                    <Td isNumeric>
                                        <Menu>
                                            <MenuButton>
                                                <Icon cursor={'pointer'} fontSize={'2xl'} as={AiOutlineMore} />
                                            </MenuButton>
                                            <MenuList>
                                                <MenuItem onClick={() => onEditTokenClick(index)}>Edit</MenuItem>
                                                <MenuItem onClick={() => onDeleteTokenClick(index)} color={'red.500'}>Delete</MenuItem>
                                            </MenuList>
                                        </Menu>
                                    </Td>
                                </Tr>
                            ))
                        }



                    </Tbody>
                </Table>

                {
                    !tokens.length && (
                        <Alert status='info'>
                            <AlertIcon />
                            <AlertTitle>No Tokens Added</AlertTitle>
                        </Alert>
                    )
                }
            </Box>
        </>
    )
}
