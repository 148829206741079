import { Button, Center, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import React, { useState } from 'react'

export default function ProjectTypeSelectionModal({ isOpen, onClose, onConfirmClick }: any) {

    const [value, setValue] = useState('secure-nonsecure')
    const [loading, setLoading] = useState(false)

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Project Type</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <RadioGroup onChange={setValue} value={value}>
                        <Stack direction='column'>
                            <Radio value='secure-nonsecure'>Secure / Non Secure</Radio>
                            <Radio value='mc-visa'>Mastercard / Visa</Radio>
                        </Stack>
                    </RadioGroup>

                    <Center my={'5'}>
                        <Button colorScheme={'whatsapp'} isLoading={loading} onClick={async () => {
                            setLoading(true)
                            await onConfirmClick(value)
                            setLoading(false)
                        }} >Create Project</Button>
                    </Center>

                </ModalBody>
            </ModalContent>
        </Modal>
    )
}
