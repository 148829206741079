import React, { Children } from 'react'
import { Navigate } from 'react-router-dom'
import { useAppSelector } from '../hooks/redux'

export const UnprotectedRoute = ({ children }: any) => {
    const { user, isUserLoading } = useAppSelector(state => state.user)

    if (isUserLoading)
        return <div>Loading...</div>

    if (!user)
        return children
    else
        return <Navigate to={'/'} />
}
