import { baseAxiosInstance } from "../config/axios"
import { store } from "../store"

export const uploadFiles = (body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/upload`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}