import { Box, Menu, MenuButton, MenuItem, MenuList, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { FiMoreVertical } from 'react-icons/fi'
import { ProductDetailSidebar } from './ProductDetailSidebar'

export const GeneralTab = ({ }: any) => {


    return (
        <>

            <Box>
                <Table bgColor={'white'}>
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>CRM ID</Th>
                            <Th>Category</Th>
                            <Th>Name</Th>
                            <Th>Alt Name</Th>
                            <Th>Slug Name</Th>
                            <Th>CreatedAt</Th>
                            <Th>UpdatedAt</Th>
                        </Tr>
                    </Thead>

                    <Tbody bgColor={'gray.50'}>
                        <Tr>
                            <Td>p_2</Td>
                            <Td></Td>
                            <Td></Td>
                            <Td>My New Product</Td>
                            <Td></Td>
                            <Td></Td>
                            <Td>7/12/22</Td>
                            <Td>7/12/22</Td>
                            <Td>
                                <Menu>
                                    <MenuButton>
                                        <FiMoreVertical cursor={'pointer'} fontSize={'20px'} />
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem>Edit</MenuItem>
                                    </MenuList>
                                </Menu>
                            </Td>



                        </Tr>
                    </Tbody>

                </Table>

            </Box>
        </>
    )
}
