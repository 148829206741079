import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  useColorModeValue as mode,
  useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import * as yup from 'yup'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { loginUser } from '../../api/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeNames } from '../../router/routeNames';
import { useAppDispatch } from '../../hooks/redux';
import { initUserState } from '../../store/actions/user';


export const Form = () => {

  const toast = useToast()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [loading, setLoading] = React.useState(false)

  const validationSchema = yup.object().shape({
    email: yup.string().required('Required').email('Enter a valid email'),
    password: yup.string().required('Required')
  })

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });


  const onLoginClick = handleSubmit((values) => {
    // navigate('/')

    setLoading(true)
    loginUser({
      identifier: values.email,
      password: values.password
    })
      .then(async response => {

        await dispatch(initUserState())
        if (response.user.isAccountSetup)
          navigate(routeNames.home())
        else
          navigate(routeNames.onboarding())
      })
      .catch(err => {
        toast({
          status: 'error',
          title: 'Login Failed',
          description: err?.response?.data?.error?.message || 'Try again'
        })
      }).finally(() => setLoading(false))
  })


  return (

    <Stack spacing="4">
      <FormControl isInvalid={!!errors.email} id="email">
        <FormLabel mb={1}>Email</FormLabel>
        <Input {...register('email')} type="email" autoComplete="email" />
        <FormErrorMessage>{`${errors?.email?.message}` || ''}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.password}>
        <Flex align="baseline" justify="space-between">
          <FormLabel mb={1}>Password</FormLabel>
          <Box
            as="a"
            href="#"
            fontWeight="semibold"
            fontSize="sm"
            color={mode('blue.600', 'blue.200')}
            onClick={() => navigate(routeNames.forgotPassword())}
          >
            Forgot Password?
          </Box>
        </Flex>
        <Input {...register('password')} type="password" autoComplete="current-password" />
        <FormErrorMessage>{`${errors?.password?.message}` || ''}</FormErrorMessage>
      </FormControl>
      <Button isLoading={loading} onClick={onLoginClick} type="submit" colorScheme="brand" size="lg" rounded={'full'} fontSize="md">
        Login
      </Button>
    </Stack>
  )
}
