import { baseAxiosInstance } from "../config/axios"
import { store } from "../store"

export const fetchBmsBatchs = async (organizationId: any, { from, to, noOfRecords = "25", currentPage = "1" }: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/bms-batches/organization/${organizationId}?pagination[page]=${currentPage}&pagination[pageSize]=${noOfRecords}`, {
        from,
        to
    }, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const fetchSingleBmsBatch = async (id: any, organizationId: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.get(`/bms-batches/${id}/organization/${organizationId}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}