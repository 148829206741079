import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ProjectState {
    projects: any[],
    scrubLogs: any[],
    isProjectsLoading: boolean
}

const initialState: ProjectState = {
    projects: [],
    scrubLogs:[],
    isProjectsLoading: true
}

export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setProjects: (state, action: PayloadAction<any>) => {
            state.projects = action.payload
        },
        setScrubLogs: (state, action: PayloadAction<any>) => {
            state.scrubLogs = action.payload
        },
        setProjectsLoading: (state, action: PayloadAction<boolean>) => {
            state.isProjectsLoading = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setProjects, setProjectsLoading } = projectSlice.actions

const projectReducer = projectSlice.reducer
export default projectReducer