import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import styled from 'styled-components'

const MyPaginate = styled(ReactPaginate).attrs({
    // You can redifine classes here, if you want.
    activeClassName: 'active', // default to "disabled"
  })`
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style-type: none;
    padding: 1.5rem 5rem;
    li a {
      border-radius: 7px;
      padding: 0.1rem 1rem;
      border: gray 1px solid;
      cursor: pointer;
      margin: 1rem;
    }
    li.previous a,
    li.next a,
    li.break a {
      border-color: transparent;
      background-color: white;
      padding:0.5rem;
      cursor:pointer;
      color:#6451B8;
    }
    li.active a {
      background-color: #6451B8;
      border-color: transparent;
      color: white;
      min-width: 32px;
    }
    li.disabled a {
      color: grey;
    }
    li.disable,
    li.disabled a {
      cursor: default;
    }
  `;

export default function CustomPagination({ items = [], itemsPerPage = 0, onCurrentItemsChange }: any) {

    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, items]);


    useEffect(() => {
        onCurrentItemsChange(currentItems)
    }, [currentItems, items])

    // Invoke when user click to request another page.
    const handlePageClick = (event: any) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };



    return (
        <MyPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            marginPagesDisplayed={1}
            pageCount={pageCount}
            previousLabel="< previous"
            renderOnZeroPageCount={undefined}
        />
    )
}
