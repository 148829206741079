import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UserState {
    user: any
    token: string | null
    isUserLoading: boolean
}

const initialState: UserState = {
    user: null,
    token: null,
    isUserLoading: true
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<any>) => {
            state.user = action.payload
        },
        setToken: (state, action: PayloadAction<any>) => {
            state.token = action.payload
        },
        setUserLoading: (state, action: PayloadAction<boolean>) => {
            state.isUserLoading = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setUser, setToken, setUserLoading } = userSlice.actions

const userReducer = userSlice.reducer
export default userReducer