import { Code, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'
import JSONPretty from 'react-json-pretty'

export const ViewActivityDataModal = ({ data, isOpen, onClose }: any) => {
    return (
        <>
            <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Data</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>

                        <Code height={'300px'} width={'100%'} overflow={'auto'} p={'3'}>
                            <JSONPretty id="json-pretty" data={data || "Not Found"}></JSONPretty>  </Code>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}
