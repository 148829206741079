import React, { useEffect, useState } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Input,
    Checkbox,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    Select,
    Flex,
    RadioGroup,
    Radio,
    Textarea,
} from '@chakra-ui/react'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { createPixel, updatePixel } from '../../api/pixel'
import { initProjectState } from '../../store/actions/project'

export const AddPixelModal = ({ isOpen, onClose, project, fetchProjectData, pixel }: any) => {

    const { trafficSource: { trafficSources } } = useAppSelector(state => state)

    const dispatch = useAppDispatch()

    const [selectedTrafficSource, setSelectedTrafficSource] = useState<any>(null)
    const [pixelCategory, setPixelCategory] = useState<'conversation' | 'click'>('conversation')
    const [pixelType, setPixelType] = useState<'iframe' | 'image' | 'js' | 'postback'>('iframe')
    const [pixelBody, setPixelBody] = useState('')
    const [campaignId, setCampainId] = useState('')
    const [productId, setProductId] = useState('')

    useEffect(() => {

        if (pixel) {
            setProductId(pixel.productIDs)
            setCampainId(pixel.campaignIDs)
            setPixelBody(pixel.body)
            setPixelType(pixel.type)
            setPixelCategory(pixel.category)
            setSelectedTrafficSource(pixel.traffic_source?.data?.id || null)

        }
    }, [pixel])


    const onSaveClick = () => {

        const body = {
            data: {
                category: pixelCategory,
                campaignIDs: campaignId,
                productIDs: productId,
                type: pixelType,
                body: pixelBody,
                traffic_source: selectedTrafficSource,
                project: project.id
            }
        }

        let apiCall

        if (pixel) {
            apiCall = updatePixel(pixel.id, body)
        } else {
            apiCall = createPixel(body)
        }

        apiCall.then(response => {
            onClose()
            fetchProjectData(false)
        })
            .catch(err => {
                console.log(err.response)
            })

    }


    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Pixel Setup</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Grid gap={'3'} templateColumns={'repeat(2,1fr)'}>

                        <GridItem>
                            <FormControl>
                                <FormLabel color={'gray.500'}>Traffic Source</FormLabel>
                                <Select value={selectedTrafficSource} onChange={(e) => setSelectedTrafficSource(e.target.value)}>
                                    {
                                        trafficSources.map(source => (
                                            <option key={source.id} value={source.id}>{source.name}</option>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </GridItem>

                        {/* 
                        <GridItem>
                            <FormControl>
                                <FormLabel color={'gray.500'}>Project Name</FormLabel>
                                <Input bgColor={'white'} rounded={'none'} />
                            </FormControl>
                        </GridItem> */}

                    </Grid>

                    <RadioGroup value={pixelCategory} onChange={(value: any) => setPixelCategory(value)} name='pixelCategory' my={'5'}>
                        <Radio value='conversation' mr={'5'}>Conversation Pixel</Radio>
                        <Radio value='click'>Click Pixel</Radio>
                    </RadioGroup>


                    {
                        pixelCategory === 'conversation' ?
                            (
                                <Grid mb={'5'} gap={'3'} templateColumns={'repeat(2,1fr)'}>

                                    <GridItem>
                                        <FormControl>
                                            <FormLabel color={'gray.500'}>Campaign Id</FormLabel>
                                            <Input value={campaignId} onChange={(e) => setCampainId(e.target.value)} bgColor={'white'} rounded={'none'} />

                                        </FormControl>
                                    </GridItem>


                                    <GridItem>
                                        <FormControl>
                                            <FormLabel color={'gray.500'}>Product Id</FormLabel>
                                            <Input value={productId} onChange={(e) => setProductId(e.target.value)} bgColor={'white'} rounded={'none'} />
                                        </FormControl>
                                    </GridItem>

                                </Grid>
                            ) : null
                    }




                    <FormControl mb={'5'}>
                        <FormLabel color={'gray.500'}>Pixel Type</FormLabel>
                        <Select value={pixelType} onChange={(e: any) => setPixelType(e.target.value)} rounded={'none'}>
                            <option value="iframe">iframe</option>
                            <option value="image">image</option>
                            <option value="js">js</option>
                            <option value="postback">postback</option>
                        </Select>
                    </FormControl>

                    <FormControl mb={'5'}>
                        <FormLabel color={'gray.500'}>Pixel Body</FormLabel>
                        <Textarea value={pixelBody} onChange={(e) => setPixelBody(e.target.value)} rounded={'none'} />
                    </FormControl>

                </ModalBody>

                <ModalFooter>
                    <Button rounded={'full'} onClick={onClose} colorScheme='gray' mr={3}>
                        Cancel
                    </Button>
                    <Button onClick={onSaveClick} rounded={'full'} colorScheme='brand'>Save</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
