import { Box, Center, Flex, Icon, Image, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { FiExternalLink } from 'react-icons/fi'
import { ImageCard } from './ImageCard'
const templateImage = require('../assets/images/template.PNG')
const pdfImg = require('../assets/images/pdf.png')
const jsonImg = require('../assets/images/json.webp')
const jsImg = require('../assets/images/js.png')
const cssImg = require('../assets/images/css.png')
const fontImg = require('../assets/images/font.png')



export const AssetDetailCard = ({ name, size, fileSrc, fileType , onExternalLinkClick }: any) => {

    let displayImgURL = ''

    if (fileType.includes('image'))
        displayImgURL = fileSrc

    if (fileType.includes('pdf'))
        displayImgURL = pdfImg

    if (fileType.includes('json'))
        displayImgURL = jsonImg

    if (fileType.includes('js'))
        displayImgURL = jsImg

    if (fileType.includes('css'))
        displayImgURL = cssImg

    if (fileType === 'font')
        displayImgURL = fontImg

    return (
        <ImageCard imgSrc={displayImgURL}>

            <Flex mt={'2'} justifyContent={'space-between'}>
                <Box>
                    <Text>{name}</Text>
                    <Text color={'gray.500'} >{size}</Text>
                </Box>

                <Tooltip label={'open in new window'}>
                    <Box onClick={onExternalLinkClick} cursor={'pointer'} h={'fit-content'}>
                        <Icon fontSize={'2xl'} as={FiExternalLink} color={'brand.500'} />
                    </Box>
                </Tooltip>

            </Flex>
        </ImageCard>

    )
}
