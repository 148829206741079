import { Box, Text } from '@chakra-ui/react'
import React from 'react'
import { ImageCard } from './ImageCard'
const img = require('../assets/images/plug.png')

export const PluginDetailCard = ({ title, description, onClick }: any) => {
    return (
        <ImageCard imgSrc={img} onClick={onClick}>
            <Box mt={'3'}>
                <Text fontWeight={'semibold'}>{title}</Text>
                <Text color={'gray.500'} >{description}</Text>
            </Box>
        </ImageCard>
    )
}
