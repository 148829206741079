import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Alert, AlertDescription, AlertTitle, Box, Button, Center, Flex, FormControl, FormLabel, Grid, GridItem, HStack, IconButton, Image, Input, Radio, RadioGroup, Select, Spinner, Switch, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { AiOutlineDelete } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { uploadFiles } from '../../api/upload'
import CustomDropzone from '../../components/CustomDropzone'
import { PLUGIN_NAMES } from '../../constants'
import { useAppSelector } from '../../hooks/redux'
import { v4 as uuidv4 } from 'uuid';

const GeneralTab = ({ data, project }: any) => {

    const { currentSelectedOrganization } = useAppSelector(state => state.organization)

    const [neobodSalesOption, setNeobodSalesOption] = useState('all')

    const [favicon, setFavicon] = useState<any>(null)
    const [projectName, setProjectName] = useState('')
    const [titleTag, setTitleTag] = useState('')
    const [mobileRedirect, setMobileRedirect] = useState('anotherPage')
    const [sessionDuration, setSessionDuration] = useState('')
    const [lockSessionOnPage, setLockSessionOnPage] = useState('index')
    const [blockSearchEnginesAndSpiders, setBlockSearchEnginesAndSpiders] = useState(false)
    const [isActivated, setIsActivated] = useState(true)
    const [is3dsEnabled, setIs3dsEnabled] = useState(false)
    const [isBundled, setIsBundled] = useState(false)
    const [isNeobodEnabled, setIsNeobodEnabled] = useState(false)


    const [thumbnail, setThumbnail] = useState<any>(null)

    const [domains, setDomains] = useState<any>([])

    const [externalURL, setExternalURL] = useState('')
    const [anotherProject, setAnotherProject] = useState<any>(null)
    const [anotherPage, setAnotherPage] = useState('')


    const [kpAppVersion, setKpAppVersion] = useState('')
    const [kpUpsellDelayTime, setKpUpsellDelayTime] = useState('')
    const [kpIsManualUpsell, setKpIsManualUpsell] = useState(false)


    const [kpLiveCampaignId, setKpLiveCampaignId] = useState('')
    const [kpLiveSalutarisToken, setKpLiveSalutarisToken] = useState('')
    const [kpLiveProducts, setKpLiveProducts] = useState<any[]>([])
    const [kpLiveUpsells, setKpLiveUpsells] = useState<any[]>([])


    const [kpInternalCampaignId, setKpInternalCampaignId] = useState('')
    const [kpInternalSalutarisToken, setKpInternalSalutarisToken] = useState('')

    const [kpInternalProducts, setKpInternalProducts] = useState<any[]>([])
    const [kpInternalUpsells, setKpInternalUpsells] = useState<any[]>([])


    const [kpPrepaidCampaignId, setKpPrepaidCampaignId] = useState('')
    const [kpPrepaidSalutarisToken, setKpPrepaidSalutarisToken] = useState('')
    const [kpPrepaidProducts, setKpPrepaidProducts] = useState<any[]>([])
    const [kpPrepaidUpsells, setKpPrepaidUpsells] = useState<any[]>([])


    const toast = useToast()

    const onDomainAddClick = () => {
        setDomains([...domains, {
            value: ""
        }])
    }

    const onDomainDeleteClick = (index: any) => {
        const filteredDomains = domains.filter((d: any, i: any) => i !== index)
        setDomains(filteredDomains)
    }


    useEffect(() => {

        data.neobodSalesOption = neobodSalesOption
        data.isNeobodEnabled = isNeobodEnabled
        data.isBundled = isBundled
        data.is3dsEnabled = is3dsEnabled
        data.isActivated = isActivated
        data.favicon = favicon
        data.projectName = projectName
        data.titleTag = titleTag
        data.mobileRedirect = mobileRedirect
        data.sessionDuration = sessionDuration
        data.lockSessionOnPage = lockSessionOnPage
        data.blockSearchEnginesAndSpiders = blockSearchEnginesAndSpiders
        data.externalURL = externalURL
        data.anotherProject = anotherProject
        data.anotherPage = anotherPage
        data.thumbnail = thumbnail
        data.kpAppVersion = kpAppVersion
        data.kpUpsellDelayTime = kpUpsellDelayTime
        data.kpIsManualUpsell = kpIsManualUpsell
        data.domains = domains

        data.kpLiveCampaignId = kpLiveCampaignId
        data.kpLiveSalutarisToken = kpLiveSalutarisToken
        data.kpLiveProducts = kpLiveProducts
        data.kpLiveUpsells = kpLiveUpsells

        data.kpInternalCampaignId = kpInternalCampaignId
        data.kpInternalSalutarisToken = kpInternalSalutarisToken
        data.kpInternalProducts = kpInternalProducts
        data.kpInternalUpsells = kpInternalUpsells

        data.kpPrepaidCampaignId = kpPrepaidCampaignId
        data.kpPrepaidSalutarisToken = kpPrepaidSalutarisToken
        data.kpPrepaidProducts = kpPrepaidProducts
        data.kpPrepaidUpsells = kpPrepaidUpsells
    })

    useEffect(() => {

        if (project) {

            setNeobodSalesOption(project.attributes.neobodSalesOption || 'all')
            setIsNeobodEnabled(project.attributes.isNeobodEnabled || false)
            setIsBundled(project.attributes.isBundled || false)
            setIs3dsEnabled(project.attributes.is3dsEnabled)
            setIsActivated(project.attributes.isActivated)
            setThumbnail(project.attributes.thumbnail.data ? { ...project.attributes?.thumbnail?.data?.attributes, id: project.attributes?.thumbnail?.data.id } : null)
            setProjectName(project.attributes.settings?.name)
            setFavicon(project.attributes.settings?.favicon?.data ? { ...project.attributes.settings?.favicon?.data?.attributes, id: project.attributes.settings?.favicon?.data?.id } : null)
            setLockSessionOnPage(project.attributes.settings?.sessionLockPage)
            setSessionDuration(project.attributes.settings?.sessionDuration)
            setTitleTag(project.attributes.globalSEO?.titleTag)
            setMobileRedirect(project.attributes.globalSEO?.mobileRedirect)
            setExternalURL(project.attributes.globalSEO?.externalURL)
            setAnotherPage(project.attributes.globalSEO?.anotherPage)
            setAnotherProject(project.attributes.globalSEO?.anotherProject?.data?.id)
            setBlockSearchEnginesAndSpiders(project.attributes.globalSEO?.blockSearchEnginesAndSpiders)
            setDomains(project.attributes?.domains || [])

            setKpAppVersion(project.attributes.konnektiveGeneralSettings?.appVersion)
            setKpUpsellDelayTime(project.attributes.konnektiveGeneralSettings?.upsellDelayTime)
            setKpIsManualUpsell(project.attributes.konnektiveGeneralSettings?.isManualUpsell || false)

            setKpLiveCampaignId(project.attributes.konnektiveLiveSettings?.campaignId)
            setKpLiveSalutarisToken(project.attributes.konnektiveLiveSettings?.salutarisToken)
            setKpLiveProducts(project.attributes?.konnektiveLiveProducts?.map((e: any) => ({ ...e, uuid: uuidv4() })) || [])
            setKpLiveUpsells(project.attributes?.konnektiveLiveUpsells?.map((e: any) => ({ ...e, uuid: uuidv4() })) || [])


            setKpInternalCampaignId(project.attributes.konnektiveInternalSettings?.campaignId)
            setKpInternalSalutarisToken(project.attributes.konnektiveInternalSettings?.salutarisToken)
            setKpInternalProducts(project.attributes?.konnektiveInternalProducts?.map((e: any) => ({ ...e, uuid: uuidv4() })) || [])
            setKpInternalUpsells(project.attributes?.konnektiveInternalUpsells?.map((e: any) => ({ ...e, uuid: uuidv4() })) || [])

            setKpPrepaidCampaignId(project.attributes.konnektivePrepaidSettings?.campaignId)
            setKpPrepaidSalutarisToken(project.attributes.konnektivePrepaidSettings?.salutarisToken)
            setKpPrepaidProducts(project.attributes?.konnektivePrepaidProducts?.map((e: any) => ({ ...e, uuid: uuidv4() })) || [])
            setKpPrepaidUpsells(project.attributes?.konnektivePrepaidUpsells?.map((e: any) => ({ ...e, uuid: uuidv4() })) || [])


        }
    }, [project])


    const is3dsPluginEnabled = currentSelectedOrganization?.activatedPlugins?.find((p: any) => p.plugin.identifier === PLUGIN_NAMES.ThreeDs)
    const isNeobodPluginEnabled = currentSelectedOrganization?.activatedPlugins?.find((p: any) => p.plugin.identifier === PLUGIN_NAMES.Neobod)

    return (
        <Center>

            <Box my={'10'} p={'2'} bgColor={'white'} w={'60%'}>

                <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                    <HStack alignItems={'center'} w={'100%'} justifyContent={'space-between'}>

                        <Box>
                            <Text fontWeight={'bold'} fontSize={'xl'}>Project Status</Text>
                            <Text >Toggle the project ON/OFF.</Text>
                        </Box>

                        <Switch isChecked={isActivated} onChange={(e) => setIsActivated(e.target.checked)} colorScheme={'whatsapp'} size={'lg'} />

                    </HStack>

                </Box>

                <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                    <Text fontWeight={'bold'} fontSize={'xl'}>API Secret Key</Text>
                    <Text >Use this Api key to integrate the panel with your project.</Text>

                    <Text fontWeight={'bold'} style={{ textAlign: 'center' }}>{project?.attributes.apiKey}</Text>
                </Box>





                <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                    <Flex mb={'5'} justifyContent={'space-between'}>
                        <Text fontWeight={'bold'} fontSize={'xl'}>Settings</Text>

                        {/* <Box>
                            <Switch>Status</Switch>
                            <Text fontSize={'sm'} fontWeight={'semibold'}>c26rgh.subscribefunnels.com</Text>
                        </Box> */}
                    </Flex>

                    <FormControl mb={'3'}>
                        <FormLabel color={'gray.500'}>Name</FormLabel>
                        <Input value={projectName} onChange={(e) => setProjectName(e.target.value)} bgColor={'white'} borderRadius={'none'} />
                    </FormControl>

                    <FormControl mb={'3'}>
                        <FormLabel color={'gray.500'}>Bundled (Products)</FormLabel>
                        <Switch isChecked={isBundled} onChange={(e) => setIsBundled(e.target.checked)} />
                    </FormControl>


                    <Flex mb={'2'} alignItems={'center'} justifyContent={'space-between'}>
                        <Text>Domains</Text>
                        <Button onClick={onDomainAddClick} variant={'outline'}>Add</Button>
                    </Flex>


                    {
                        domains.length ?
                            <Accordion mb={'3'} bgColor={'white'} allowToggle allowMultiple>

                                {
                                    domains.map((domain: any, index: any) => (
                                        <>

                                            <AccordionItem>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex='1' textAlign='left'>
                                                            {domain.value}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>

                                                    <Flex justifyContent={'flex-end'}>
                                                        <IconButton
                                                            onClick={() => onDomainDeleteClick(index)}
                                                            aria-label='Delete'
                                                            icon={<AiOutlineDelete />}
                                                            colorScheme={'red'}
                                                            variant={'outline'}
                                                        />
                                                    </Flex>


                                                    <FormControl mb={'3'}>
                                                        <FormLabel color={'gray.500'}>Value</FormLabel>
                                                        <Input value={domain.value} onChange={(e) => {
                                                            const newDomains = [...domains]
                                                            newDomains[index].value = e.target.value
                                                            setDomains(newDomains)
                                                        }} bgColor={'white'} borderRadius={'none'} />
                                                    </FormControl>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </>
                                    ))
                                }

                            </Accordion> :
                            <Alert mb={'3'} status='info'>
                                <AlertDescription>No Domains Added</AlertDescription>
                            </Alert>
                    }

                    {/* <Grid gap={6} templateColumns={'repeat(3,1fr)'}>
                        <GridItem>
                            <Text color={'gray.500'}>Upload Favicon</Text>
                            <CustomDropzone onDrop={onFaviconFileDrop} >

                                {
                                    isFaviconUploading ?
                                        <Spinner /> :
                                        favicon ?
                                            <Image src={`${process.env.REACT_APP_API_URL}${favicon?.url}`} /> :
                                            <p>Upload Files here</p>
                                }
                            </CustomDropzone>
                        </GridItem>

                        <GridItem>
                            <Text color={'gray.500'}>Lock Session on Page</Text>
                            <Select value={lockSessionOnPage} onChange={(e) => setLockSessionOnPage(e.target.value)} bgColor={'white'} rounded={'none'}>
                                <option value="index">Index</option>
                                <option value="home">Home</option>
                            </Select>
                        </GridItem>

                        <GridItem>
                            <Text color={'gray.500'}>Session Duration</Text>
                            <Select value={sessionDuration} bgColor={'white'} rounded={'none'} onChange={(e) => setSessionDuration(e.target.value)}>
                                <option value="5">5 Minutes</option>
                                <option value="10">10 Minutes</option>
                                <option value="15">15 Minutes</option>
                                <option value="30">30 Minutes</option>
                                <option value="60">1 Hour</option>
                                <option value="720">12 Hours</option>
                                <option value="1440">1 day</option>
                                <option value="10080">7 days</option>
                                <option value="43200">30 days</option>
                                <option value="524160">1 year</option>
                            </Select>
                        </GridItem>


                    </Grid> */}
                </Box>

                {
                    is3dsPluginEnabled && (
                        <Box mb={'2'} p={'4'} bgColor={'gray.50'}>
                            <HStack alignItems={'center'} w={'100%'} justifyContent={'space-between'}>

                                <Box>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>3DS Plugin Settings</Text>
                                    <Text >Enable 3DS secure transactions in your orders.</Text>
                                </Box>

                                <Switch isChecked={is3dsEnabled} onChange={(e) => setIs3dsEnabled(e.target.checked)} colorScheme={'whatsapp'} size={'lg'} />
                            </HStack>
                        </Box>
                    )
                }


                {
                    isNeobodPluginEnabled && (
                        <Box mb={'2'} p={'4'} bgColor={'gray.50'}>
                            <HStack alignItems={'center'} w={'100%'} justifyContent={'space-between'}>

                                <Box>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>Neobod Plugin Settings</Text>
                                    <Text >Enable Neobod plugin for your orders.</Text>
                                </Box>

                                <Switch isChecked={isNeobodEnabled} onChange={(e) => setIsNeobodEnabled(e.target.checked)} colorScheme={'whatsapp'} size={'lg'} />
                            </HStack>

                            {
                                isNeobodEnabled && (
                                    <FormControl w={'50%'} my={'3'}>
                                        <FormLabel>Sales Settings</FormLabel>

                                        <Select value={neobodSalesOption} onChange={(e) => setNeobodSalesOption(e.target.value)} bgColor={'white'} rounded={'none'}>
                                            <option value="all">All</option>
                                            <option value="success">Only Success</option>
                                            <option value="declined">Only Declines</option>
                                        </Select>
                                    </FormControl>
                                )
                            }


                        </Box>
                    )
                }


                <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                    <Text fontWeight={'bold'} fontSize={'xl'}>Konnektive Plugin Settings</Text>
                    {/* <Text >.</Text> */}

                    <FormControl mb={'3'}>
                        <FormLabel>App Version</FormLabel>
                        <Input value={kpAppVersion} onChange={(e) => setKpAppVersion(e.target.value)} bgColor={'white'} />
                    </FormControl>

                    {/* 
                    <FormControl mb={'3'}>
                        <FormLabel>Manual Upsell </FormLabel>
                        <Switch onChange={(e) => setKpIsManualUpsell(e.target.checked)} isChecked={kpIsManualUpsell} />
                    </FormControl>


                    <FormControl mb={'3'}>
                        <FormLabel>Upsell Delay Time (mins)</FormLabel>
                        <Input type={'number'} value={kpUpsellDelayTime} onChange={(e) => setKpUpsellDelayTime(e.target.value)} bgColor={'white'} />
                    </FormControl> */}

                    <Tabs>
                        <TabList>
                            <Tab>Live</Tab>
                            <Tab>Internal</Tab>
                            <Tab>Prepaid</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel>
                                <KonnektiveSettingsTab
                                    identifier={'live'}
                                    project={project}
                                    values={{
                                        campaignId: kpLiveCampaignId,
                                        salutarisToken: kpLiveSalutarisToken,
                                        products: kpLiveProducts,
                                        upsells: kpLiveUpsells
                                    }}

                                    products={kpLiveProducts}
                                    setProducts={setKpLiveProducts}
                                    setValue={{
                                        setCampaignId: setKpLiveCampaignId,
                                        setSalutarisToken: setKpLiveSalutarisToken,
                                        setProducts: setKpLiveProducts,
                                        setUpsells: setKpLiveUpsells
                                    }}
                                />
                            </TabPanel>
                            <TabPanel>
                                <KonnektiveSettingsTab
                                    identifier={'internal'}
                                    project={project}
                                    values={{
                                        campaignId: kpInternalCampaignId,
                                        salutarisToken: kpInternalSalutarisToken,
                                        products: kpInternalProducts,
                                        upsells: kpInternalUpsells
                                    }}

                                    setValue={{
                                        setCampaignId: setKpInternalCampaignId,
                                        setSalutarisToken: setKpInternalSalutarisToken,
                                        setProducts: setKpInternalProducts,
                                        setUpsells: setKpInternalUpsells

                                    }}
                                />
                            </TabPanel>
                            <TabPanel>
                                <KonnektiveSettingsTab
                                    identifier={'prepaid'}
                                    project={project}
                                    values={{
                                        campaignId: kpPrepaidCampaignId,
                                        salutarisToken: kpPrepaidSalutarisToken,
                                        products: kpPrepaidProducts,
                                        upsells: kpPrepaidUpsells

                                    }}

                                    setValue={{
                                        setCampaignId: setKpPrepaidCampaignId,
                                        setSalutarisToken: setKpPrepaidSalutarisToken,
                                        setProducts: setKpPrepaidProducts,
                                        setUpsells: setKpPrepaidUpsells

                                    }}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>



                </Box>


                {/* <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                    <Flex mb={'5'} justifyContent={'space-between'}>
                        <Text fontWeight={'bold'} fontSize={'xl'}>Thumbnail</Text>
                    </Flex>


                    <CustomDropzone onDrop={onThumbnailFileDrop}>
                        {
                            favicon ?
                                <Image h={'200px'} src={`${process.env.REACT_APP_API_URL}${thumbnail?.url}`} /> :
                                <p>Upload File here</p>
                        }
                    </CustomDropzone>


                </Box> */}

                {/* <Box mb={'2'} p={'4'} bgColor={'gray.50'}>
                    <Text mb={'5'} fontWeight={'bold'} fontSize={'xl'}>Domain</Text>

                    <Text textAlign={'end'} color={'brand.500'}>+ create SSL</Text>

                    <RadioGroup mb={'3'}>
                        <Flex justifyContent={'space-between'}>
                            <Radio value='1'>Subscribe Funnels Subdomain</Radio>
                            <Radio value='2'>Custom Domain</Radio>
                        </Flex>
                    </RadioGroup>

                    <FormControl>
                        <FormLabel color={'gray.500'}>Subdomain</FormLabel>
                        <Input bgColor={'white'} rounded={'none'} />
                    </FormControl>


                </Box> */}

                {/* <Box mb={'2'} p={'4'} bgColor={'gray.50'}>
                    <Text mb={'5'} fontWeight={'bold'} fontSize={'xl'}>Traffic Link</Text>

                    <FormControl>
                        <FormLabel color={'gray.500'}>Traffic Source</FormLabel>
                        <Select bgColor={'white'} rounded={'none'}>
                            <option>sdsd</option>
                        </Select>
                    </FormControl>


                </Box> */}


                {/* <Box p={'4'} bgColor={'gray.50'}>

                    <Flex alignItems={'center'} justifyContent={'space-between'}>
                        <Text mb={'5'} fontWeight={'bold'} fontSize={'xl'}>Global SEO</Text>
                        <Switch isChecked={blockSearchEnginesAndSpiders} onChange={(e) => setBlockSearchEnginesAndSpiders(e.target.checked)}>Block Search Engines and Spider</Switch>
                    </Flex>

                    <FormControl mb={'3'}>
                        <FormLabel color={'gray.500'}>Title Tag</FormLabel>
                        <Input value={titleTag} onChange={(e) => setTitleTag(e.target.value)} bgColor={'white'} rounded={'none'} />
                    </FormControl>

                    <Grid gap={6} templateColumns={'repeat(3,1fr)'}>
                        <GridItem colSpan={1}>
                            <FormControl>
                                <FormLabel color={'gray.500'}>Mobile Redirect</FormLabel>
                                <Select value={mobileRedirect} onChange={(e) => setMobileRedirect(e.target.value)} bgColor={'white'} rounded={'none'}>
                                    <option value={"doNotRedirect"}>Do Not Redirect</option>
                                    <option value={"anotherPage"}>Another Page</option>
                                    <option value={"anotherProject"}>Another Project</option>
                                    <option value={"externalURL"}>External URL</option>

                                </Select>
                            </FormControl>
                        </GridItem>



                        <GridItem colSpan={2}>
                            {renderMobileRedirectComponent()}
                        </GridItem>

                    </Grid>

                </Box> */}
            </Box>
        </Center>

    )
}


const KonnektiveSettingsTab = ({ project, values, setValue, identifier }: any) => {

    const onAddProductClick = () => {

        setValue.setProducts([
            ...values.products,
            {
                identifier: "",
                initialId: "",
                rebillId: "",
                isInitialMasterVisa: false,
                isRebillMasterVisa: false,
                uuid: uuidv4()
            }
        ])

    }

    const onAddUpsellClick = () => {

        setValue.setUpsells([
            ...values.upsells,
            {
                identifier: "",
                initialId: "",
                rebillId: "",
                isInitialMasterVisa: false,
                isRebillMasterVisa: false,
                uuid: uuidv4()

            }
        ])

    }

    const onDeleteProductClick = (uuid: any) => {
        setValue.setProducts((prevProducts = []) => {
            const filteredProducts = prevProducts.filter((p: any) => p.uuid !== uuid)
            return filteredProducts
        })

    }

    const onDeleteUpsellClick = (uuid: any) => {
        setValue.setUpsells((prevUpsells = []) => {
            const filteredUpsells = prevUpsells.filter((u: any) => u.uuid !== uuid)
            return filteredUpsells
        })
    }



    return (
        <Box>
            <FormControl mb={'3'}>
                <FormLabel>Campaign ID </FormLabel>
                <Input value={values.campaignId} onChange={(e) => setValue.setCampaignId(e.target.value)} bgColor={'white'} />
            </FormControl>

            <FormControl mb={'3'}>
                <FormLabel>Salutaris Token</FormLabel>
                <Input value={values.salutarisToken} onChange={(e) => setValue.setSalutarisToken(e.target.value)} bgColor={'white'} />
            </FormControl>


            <Flex mb={'2'} alignItems={'center'} justifyContent={'space-between'}>
                <Text>Products</Text>
                <Button onClick={onAddProductClick} variant={'outline'}>Add</Button>
            </Flex>

            {
                values.products.length ?
                    <Accordion mb={'3'} bgColor={'white'} allowMultiple>

                        {
                            values.products.map((prod: any, index: any) => (
                                <ProductUpsellSettings
                                    project={project}
                                    key={prod.uuid}
                                    onDeleteClick={() => onDeleteProductClick(prod.uuid)}
                                    index={index}
                                    data={values.products}
                                    entity={prod}
                                />
                            ))
                        }

                    </Accordion> :
                    <Alert mb={'3'} status='info'>
                        <AlertDescription>No Products Added</AlertDescription>
                    </Alert>
            }




            <Flex mb={'2'} alignItems={'center'} justifyContent={'space-between'}>
                <Text>Upsells</Text>
                <Button onClick={onAddUpsellClick} variant={'outline'}>Add</Button>
            </Flex>

            {
                values.upsells.length ?

                    <Accordion mb={'3'} bgColor={'white'} allowMultiple>
                        {
                            values.upsells.map((upsell: any, index: any) => (
                                <ProductUpsellSettings
                                    project={project}
                                    isUpsell
                                    key={upsell.uuid}
                                    onDeleteClick={() => onDeleteUpsellClick(upsell.uuid)}
                                    index={index}
                                    data={values.upsells}
                                    entity={upsell} />
                            ))
                        }
                    </Accordion>
                    :
                    <Alert mb={'3'} status='info'>
                        <AlertDescription>No Upsells Added</AlertDescription>
                    </Alert>

            }




        </Box>
    )
}


const ProductUpsellSettings = ({ data, index, entity, onDeleteClick, project, isUpsell }: any) => {

    const [identifier, setIdentifier] = useState(entity.identifier)
    const [initialId, setInitialId] = useState(entity.initialId)
    const [rebillId, setRebillId] = useState(entity.rebillId)
    const [isInitialMasterVisa, setIsInitialMasterVisa] = useState(entity.isInitialMasterVisa)
    const [isRebillMasterVisa, setIsRebillMasterVisa] = useState(entity.isRebillMasterVisa)
    const [isManualUpsell, setIsManualUpsell] = useState(entity.isManualUpsell)
    const [upsellDelayTime, setUpsellDelayTime] = useState(entity.upsellDelayTime)

    useEffect(() => {
        data[index].identifier = identifier
        data[index].initialId = initialId
        data[index].rebillId = rebillId
        data[index].isInitialMasterVisa = isInitialMasterVisa
        data[index].isRebillMasterVisa = isRebillMasterVisa
        data[index].isManualUpsell = isManualUpsell
        data[index].upsellDelayTime = parseFloat(upsellDelayTime || 0)
    })

    return (
        <AccordionItem>
            <h2>
                <AccordionButton>
                    <Box flex='1' textAlign='left'>
                        {identifier}
                    </Box>
                    <AccordionIcon />
                </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>

                <Flex justifyContent={'flex-end'}>
                    <IconButton
                        onClick={onDeleteClick}
                        aria-label='Delete'
                        icon={<AiOutlineDelete />}
                        colorScheme={'red'}
                        variant={'outline'}
                    />
                </Flex>


                <FormControl mb={'3'}>
                    <FormLabel>Identifier</FormLabel>
                    <Input value={identifier} onChange={(e) => setIdentifier(e.target.value)} bgColor={'white'} />
                </FormControl>


                <Flex>
                    <FormControl mb={'3'}>
                        <FormLabel>Initial ID {project?.attributes?.type === 'mc-visa' ? ' (VISA:MC)' : isInitialMasterVisa ? ' (VISA:MC)' : ' (NS:S)'}</FormLabel>
                        <Input value={initialId} onChange={(e) => setInitialId(e.target.value)} bgColor={'white'} />
                    </FormControl>
                    {
                        project?.attributes?.type !== 'mc-visa' && (
                            <FormControl mx={'5'}>
                                <FormLabel>Mastercard/VISA</FormLabel>
                                <Switch isChecked={isInitialMasterVisa} onChange={(e) => setIsInitialMasterVisa(e.target.checked)} />
                            </FormControl>
                        )
                    }

                </Flex>



                <Flex>

                    <FormControl mb={'3'}>
                        <FormLabel>Rebill ID {project?.attributes?.type === 'mc-visa' ? ' (VISA:MC)' : isRebillMasterVisa ? ' (VISA:MC)' : ' (NS:S)'}</FormLabel>
                        <Input value={rebillId} onChange={e => setRebillId(e.target.value)} bgColor={'white'} />
                    </FormControl>

                    {
                        project?.attributes?.type !== 'mc-visa' && (
                            <FormControl mx={'5'}>
                                <FormLabel>Mastercard/VISA</FormLabel>
                                <Switch isChecked={isRebillMasterVisa} onChange={(e) => setIsRebillMasterVisa(e.target.checked)} />
                            </FormControl>
                        )
                    }
                </Flex>


                {
                    isUpsell && (
                        <FormControl mb={'3'}>
                            <FormLabel>Manual Upsell</FormLabel>
                            <Switch isChecked={isManualUpsell} onChange={(e) => setIsManualUpsell(e.target.checked)} />
                        </FormControl>
                    )
                }



                {
                    isUpsell && (

                        <FormControl mb={'3'}>
                            <FormLabel>Upsell Delay Time (mins)</FormLabel>
                            <Input value={upsellDelayTime} onChange={e => setUpsellDelayTime(e.target.value)} bgColor={'white'} />
                        </FormControl>
                    )
                }


            </AccordionPanel>
        </AccordionItem>
    )

}
export default GeneralTab