import React from 'react'
import { Navigate } from 'react-router-dom'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { routeNames } from '../../router/routeNames'

export const HomePage = () => {
  return (
    <Navigate to={routeNames.projects()} />
  )
}
