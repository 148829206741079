import { Alert, AlertIcon, AlertTitle, Box, Button, Center, Checkbox, CheckboxGroup, Flex, Grid, GridItem, Heading, HStack, Input, Menu, MenuItem, MenuList, Table, Tbody, Td, Th, Thead, Tr, useDisclosure, useToast } from "@chakra-ui/react"
import { AssetDetailCard } from "../../components/AssetDetailCard"
import { CustomWhiteMenuButton } from "../../components/CustomMenu"
import { UploadAssetModal } from "./UploadAssetModal"
import { ImCross } from 'react-icons/im'
import { useEffect, useState } from "react"
import { uploadFiles } from "../../api/upload"
import { useAppSelector } from "../../hooks/redux"

const AssetsTab = ({ data, project }: any) => {

    const { isOpen, onClose, onOpen } = useDisclosure()

    const [assets, setAssets] = useState<any[]>([])
    const [filteredAssets, setFilteredAssets] = useState<any[]>([])

    const [searchValue, setSearchValue] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const toast = useToast()

    const onFilesSelected = async (files: any) => {


        setIsLoading(true)

        const body = new FormData()
        files.forEach((file: any) => {
            body.append('files', file)
        })

        body.append('ref', 'api::project.project')
        body.append('refId', project.id)
        body.append('field', 'assets')



        await uploadFiles(body)
            .then(response => {
                setAssets([...assets, ...response])
                toast({
                    status: 'success',
                    title: 'Assets Uploaded'
                })

            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))

    }

    const searchAssets = (value: string) => {

    }

    useEffect(() => {

    }, [searchValue, assets])


    useEffect(() => {
        if (project) {

            const mappedAssets = project.attributes.assets?.data?.map((asset: any) => {
                return {
                    ...asset.attributes,
                    id: asset.id
                }
            })

            setAssets(mappedAssets || [])
            setFilteredAssets(mappedAssets || [])
        }
    }, [project])

    return (
        <>
            {/* <FilesUploadSection /> */}
            <UploadAssetModal processLoading={isLoading} onFilesSelected={onFilesSelected} isOpen={isOpen} onClose={onClose} />
            <Flex justifyContent={'space-between'}>

                <HStack>

                    <Menu>
                        <CustomWhiteMenuButton>Sort By Name</CustomWhiteMenuButton>
                        <MenuList>
                            <MenuItem>File Type</MenuItem>
                            <MenuItem>Name</MenuItem>
                            <MenuItem>Size</MenuItem>
                        </MenuList>
                    </Menu>

                    <Menu closeOnSelect={false}>
                        <CustomWhiteMenuButton>Filter</CustomWhiteMenuButton>
                        <MenuList>
                            <CheckboxGroup>

                                <MenuItem>
                                    <Checkbox>CSS</Checkbox>
                                </MenuItem>

                                <MenuItem>
                                    <Checkbox>HTML</Checkbox>
                                </MenuItem>

                                <MenuItem>
                                    <Checkbox>JS</Checkbox>
                                </MenuItem>

                                <MenuItem>
                                    <Checkbox>PDF</Checkbox>
                                </MenuItem>

                                <MenuItem>
                                    <Checkbox>JSON</Checkbox>
                                </MenuItem>

                                <MenuItem>
                                    <Checkbox>FONTS</Checkbox>
                                </MenuItem>

                            </CheckboxGroup>
                        </MenuList>
                    </Menu>

                </HStack>

                <HStack>
                    <Input value={searchValue} onChange={(e) => setSearchValue(e.target.value)} bgColor={'white'} rounded={'full'} placeholder={'Find Asset...'} />
                    <Button onClick={onOpen} colorScheme={'brand'} rounded={'full'} px={'8'}>Upload Asset</Button>
                </HStack>

            </Flex>


            {
                !filteredAssets.length && (
                    <Alert my={'10'} status='info'>
                        <AlertIcon />
                        <AlertTitle>No Assets Added</AlertTitle>
                    </Alert>
                )
            }

            <Grid my={'10'} gap={'6'} templateColumns={'repeat(4,1fr)'}>

                {
                    filteredAssets.map((asset, index) => (
                        <GridItem key={index}>
                            <AssetDetailCard onExternalLinkClick={() => {
                                window.open(`${process.env.REACT_APP_API_URL}${asset.url}`)
                            }} fileType={asset.mime} fileSrc={`${process.env.REACT_APP_API_URL}${asset.url}`} name={asset.name} size={`${asset.size} kb`} />
                        </GridItem>
                    ))
                }

            </Grid>
        </>
    )
}

const FilesUploadSection = () => {

    const { isOpen, onClose, onOpen } = useDisclosure()

    useEffect(() => {
        onOpen()
    }, [])

    if (isOpen)
        return (
            <>
                <Center mb={'5'}>
                    <Box w={'60%'} p={'4'}>
                        <Box bgColor={'gray.50'} p={'4'}>

                            <Flex mb={'3'} alignItems={'center'} justifyContent={'space-between'}>
                                <Heading size={'md'}>Files To Upload</Heading>
                                <ImCross onClick={onClose} cursor={'pointer'} />
                            </Flex>

                            <Table bgColor={'white'}>
                                <Thead bgColor={'white'}>
                                    <Tr>
                                        <Th>Name</Th>
                                        <Th>File Size</Th>
                                        <Th>Status</Th>
                                    </Tr>
                                </Thead>

                                <Tbody>
                                    <Tr>
                                        <Td>dummy_pic.png</Td>
                                        <Td>134kb</Td>
                                        <Td>
                                            Completed
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>

                        </Box>
                    </Box>
                </Center>
            </>
        )
    else
        return null
}
export default AssetsTab