import { fetchCurrentUser } from "../../api/user"
import { setToken, setUser, setUserLoading } from "../reducers/user"

export const initUserState = () => async (dispatch: any, getState: any) => {
    dispatch(setUserLoading(true))
    let user = localStorage.getItem('user')
    if (user) {
        const parsedUser = JSON.parse(user)
        const userDetails = await fetchCurrentUser(`Bearer ${parsedUser.jwt}`)
        dispatch(setUser(userDetails))
        dispatch(setToken(`Bearer ${parsedUser.jwt}`))
    } else {
        dispatch(setUser(null))
    }
    dispatch(setUserLoading(false))
}
