import { fetchOrganizationProjects } from "../../api/project";
import { setProjects, setProjectsLoading } from "../reducers/project";

export const initProjectState = () => async (dispatch: any, getState: any) => {

    const { organization: { currentSelectedOrganization } } = getState()

    const projects = await fetchOrganizationProjects(currentSelectedOrganization.id)
    dispatch(setProjects(projects))
    dispatch(setProjectsLoading(false))
}

