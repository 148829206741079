import { AnyAaaaRecord } from "dns"
import { baseAxiosInstance } from "../config/axios"
import { store } from "../store"

export const fetchPlugins = async () => {
    const token = store.getState().user.token

    return baseAxiosInstance.get(`/plugins`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}


export const activatePlugin = async (pluginId: any, organizationId: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/plugins/activate/${pluginId}/organization/${organizationId}`, "", {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const deactivatePlugin = async (pluginId: any, organizationId: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/plugins/deactivate/${pluginId}/organization/${organizationId}`, "", {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}


export const updatePlugin = async (pluginId: any, organizationId: any, body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.put(`/plugins/edit/${pluginId}/organization/${organizationId}`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const billingManagerRerun = async (body: any, organizationId: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/plugins/billing-manager/rerun/${organizationId}`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}
