import { Box, Button, Center, Flex, Heading, HStack, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { AiFillCheckCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { createProjectForOrganization } from '../../api/project'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { routeNames } from '../../router/routeNames'
import { initProjectState } from '../../store/actions/project'
import ProjectCreate from './ProjectCreate'

export const NoProjectsView = () => {

    const { organization: { currentSelectedOrganization }, user: { user } } = useAppSelector(state => state)

    const { isOpen: isTypeSelectionModalOpen, onOpen: onIsTypeSelectionModalOpen, onClose: onIsTypeSelectionModalClose } = useDisclosure()

    return (
        <Box bgColor={'white'} h={'100vh'}>
            <ProjectCreate isOpen={isTypeSelectionModalOpen} onClose={onIsTypeSelectionModalClose} />
            <Center h={'full'}>
                <Box w={'50%'}>
                    <Box mb={'5'}>
                        <Text fontSize={'xl'} color={'brand.500'}>Hello, {user?.firstname} {user?.lastname}</Text>
                        <Heading size={'3xl'} color={'brand.500'}>Welcome!</Heading>
                    </Box>

                    <Text mb={'8'} fontSize={'lg'} fontWeight={'semibold'}>You’re on your way to making AMAZING online sales funnels! You can begin a new project or begin setting up plugins for advanced integrations.</Text>

                    <HStack>
                        <Button onClick={() => onIsTypeSelectionModalOpen()} colorScheme={'brand'} rounded={'full'}>Start new Project</Button>
                        <Button colorScheme={'gray'} rounded={'full'}>Go to Plugins Integration</Button>

                    </HStack>
                </Box>
            </Center>
        </Box>
    )
}
