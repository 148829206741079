import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({

    fonts: {
        heading: `'Inter', sans-serif`,
        body: `'Inter', sans-serif`,
    },

    colors: {
        brand: {
            50: '#A59AD6',
            100: '#988BD0',
            200: '#8B7DCA',
            300: '#7E6EC4',
            400: '#7160BE',
            500: '#6451B8',
            600: '#5A47AE',
            700: '#52419F',
            800: '#4B3B91',
            900: '#433582'
        },
        primary: {
            50: '#A59AD6',
            100: '#988BD0',
            200: '#8B7DCA',
            300: '#7E6EC4',
            400: '#7160BE',
            500: '#6451B8',
            600: '#5A47AE',
            700: '#52419F',
            800: '#4B3B91',
            900: '#433582'
        },
    },
    components: {
        FormLabel: {
            baseStyle: {
                color: 'gray.500',
                fontSize: 'sm'

            }
        },

        Button: {
            defaultProps: {
                size: 'md'
            }
        },

        Input: {
            baseStyle: {
                bgColor: 'white'
            },
            defaultProps: {
                size: 'sm',
                rounded: 'none'
            }
        },

        Select: {
            baseStyle: {
                bgColor: 'white'
            },
            defaultProps: {
                size: 'sm',
                rounded: 'none'
            }
        },

        // Text: {
        //     defaultProps: {
        //         fontSize: 'sm'
        //     },
        //     baseStyle: {
        //         fontSize: 'sm'
        //     }
        // }

    }
})

export default theme