import { Alert, AlertIcon, AlertTitle, Box, Button, Flex, FormControl, FormLabel, Grid, GridItem, Icon, Input, Menu, MenuButton, MenuItem, MenuList, Select, Table, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { AiOutlineMore } from "react-icons/ai"
import { deletePixel } from "../../api/pixel"
import { fetchScrubLogsOfProject } from "../../api/scrubLog"
import { useAppSelector } from "../../hooks/redux"
import { AddPixelModal } from "./AddPixelModal"
import { AddRuleModal } from "./AddRuleModal"
import { LogsViewModal } from "./LogsViewModal"

const PixelsTab = ({ project, data, fetchProjectData }: any) => {


    const { trafficSources } = useAppSelector(state => state.trafficSource)

    const { isOpen: isAddPixelModalOpen, onClose: onAddPixelModalClose, onOpen: onAddPixelModalOpen } = useDisclosure()
    const { isOpen: isAddRuleModalOpen, onClose: onAddRuleModalClose, onOpen: onAddRuleModalOpen } = useDisclosure()


    const [defaultThrottle, setDefaultThrottle] = useState('')
    const [throttleAffiliateId, setThrottleAffiliateId] = useState('')
    const [throttleCampaignId, setThrottleCampaignId] = useState('')
    const [prepaidCampaignId, setPrepaidCampaignId] = useState('')
    const [internalId, setInternalId] = useState('')

    const [rules, setRules] = useState<any[]>([])
    const [pixels, setPixels] = useState<any[]>([])


    const [selectedRuleIndex, setSelectedRuleIndex] = useState<any>(null);
    const [selectedPixel, setSelectedPixel] = useState<any>(null);

    useEffect(() => {
        if (!isAddPixelModalOpen)
            setSelectedPixel(null)
    }, [isAddPixelModalOpen])



    useEffect(() => {
        data.defaultThrottle = defaultThrottle
        data.throttleAffiliateId = throttleAffiliateId
        data.throttleCampaignId = throttleCampaignId
        data.prepaidCampaignId = prepaidCampaignId
        data.internalId = internalId
        data.rules = rules

    })

    useEffect(() => {

        if (project) {
            setDefaultThrottle(project.attributes?.ruleSettings?.defaultThrottle)
            setThrottleAffiliateId(project.attributes.ruleSettings?.throttleAffiliateID)
            setThrottleCampaignId(project.attributes.ruleSettings?.throttleCampaignID)
            setPrepaidCampaignId(project.attributes.ruleSettings?.prepaidCampaignID)
            setInternalId(project.attributes.ruleSettings?.internalID)
            setRules(project.attributes?.rules.map((rule: any) => ({
                rule: rule.rule,
                throttle: rule.throttle,
                s1: rule.s1,
                byAffiliateSource: rule.byAffiliateSource?.data?.id || null,
                threedsScrub: rule.threedsScrub,
                logs: rule.logs || []
            })) || [])

            setPixels(project.attributes?.pixels?.data?.map((pixel: any) => ({
                id: pixel.id,
                ...pixel.attributes
            })) || [])


        }
    }, [project])


    const onAddRule = (newRule: any) => {

        setRules([...rules, {
            rule: newRule.ruleType,
            throttle: newRule.throttle || 0,
            byAffiliateSource: newRule.selectedSource ? parseInt(newRule.selectedSource) : null,
            threedsScrub: newRule.threedsScrub,
            s1: newRule.s1,
            logs: [
                {
                    throttle: newRule.throttle || 0,
                    rule: newRule.ruleType,
                    traffic_source: newRule.selectedSource ? parseInt(newRule.selectedSource) : null,
                    type: 'created',
                    timestamp: new Date().toISOString()
                }
            ]
        }])
    }

    const onEditRule = (index: number, updatedRule: any) => {

        const updatedRules = [...rules]

        if (
            updatedRules[index].throttle !== updatedRule.throttle ||
            updatedRules[index].rule !== updatedRule.ruleType ||
            updatedRules[index].s1 !== updatedRule.s1 ||
            updatedRules[index].threedsScrub !== updatedRule.threedsScrub
        ) {
            updatedRules[index].logs = [
                ...updatedRules[index].logs,
                {
                    throttle: updatedRule.throttle || 0,
                    rule: updatedRule.ruleType,
                    traffic_source: updatedRule.selectedSource ? parseInt(updatedRule.selectedSource) : null,
                    type: 'updated',
                    timestamp: new Date().toISOString()
                }
            ]
        }

        updatedRules[index].rule = updatedRule.ruleType
        updatedRules[index].throttle = updatedRule.throttle || 0
        updatedRules[index].byAffiliateSource = updatedRule.selectedSource ? parseInt(updatedRule.selectedSource) : null
        updatedRules[index].s1 = updatedRule.s1
        updatedRules[index].threedsScrub = updatedRule.threedsScrub



        setRules(updatedRules)
    }

    const onRuleEditClick = (index: any) => {
        setSelectedRuleIndex(index)
        onAddRuleModalOpen()
    }

    const onRuleAddClick = () => {
        setSelectedRuleIndex(null)
        onAddRuleModalOpen()
    }

    const onViewRuleLogsClick = (index: any) => {
        setSelectedRuleIndex(index)
        onRuleLogsModalOpen()
    }

    const onPixelDeleteClick = (pixelId: any) => {
        deletePixel(pixelId)
            .then(() => {
                fetchProjectData(false)
            })
            .catch(err => console.log(err))
    }


    const onRuleDeleteClick = (index: any) => {
        const filteredRules = rules.filter((r, i) => i !== index)
        setRules(filteredRules)
    }


    const { isOpen: isRuleLogsModalOpen, onClose: onRuleLogsModalClose, onOpen: onRuleLogsModalOpen } = useDisclosure()

    return (
        <Box p={'5'}>

            <LogsViewModal
                logs={rules[selectedRuleIndex]?.logs || []}
                isOpen={isRuleLogsModalOpen}
                onClose={onRuleLogsModalClose}
            />

            <AddPixelModal
                pixel={selectedPixel}
                fetchProjectData={fetchProjectData}
                project={project}
                isOpen={isAddPixelModalOpen}
                onClose={onAddPixelModalClose}
            />

            <AddRuleModal
                ruleIndex={selectedRuleIndex}
                rule={rules[selectedRuleIndex]}
                onEditRule={onEditRule}
                onAddRule={onAddRule}
                isOpen={isAddRuleModalOpen}
                onClose={onAddRuleModalClose} />

            <Grid gap={6} templateColumns={'repeat(2,1fr)'}>
                <GridItem>
                    <Flex justifyContent={'space-between'} alignItems={'center'} mb={'3'}>
                        <Text fontWeight={'bold'} fontSize={'xl'}>Pixels</Text>
                        <Button rounded={'full'} colorScheme={'brand'} onClick={onAddPixelModalOpen}>Add Pixel</Button>
                    </Flex>


                    <Table bgColor={'white'} variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Traffic Source Name</Th>
                                <Th>Product IDs</Th>
                                <Th>Campaign IDs</Th>
                                <Th>Pixel Type</Th>
                                <Th />
                            </Tr>
                        </Thead>

                        <Tbody>
                            {
                                pixels.map(pixel => (
                                    <Tr>
                                        <Th>{pixel.traffic_source?.data?.attributes?.name}</Th>
                                        <Th>{pixel.productIDs}</Th>
                                        <Th>{pixel.campaignIDs}</Th>
                                        <Th>{pixel.type}</Th>
                                        <Td isNumeric>
                                            <Menu>
                                                <MenuButton>
                                                    <Icon cursor={'pointer'} fontSize={'2xl'} as={AiOutlineMore} />
                                                </MenuButton>
                                                <MenuList>
                                                    <MenuItem
                                                        onClick={() => {
                                                            setSelectedPixel(pixel)
                                                            onAddPixelModalOpen()
                                                        }}
                                                        color={'red.500'}>Edit</MenuItem>
                                                    <MenuItem onClick={() => {
                                                        onPixelDeleteClick(pixel.id)
                                                    }} color={'red.500'}>Delete</MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))
                            }

                        </Tbody>

                    </Table>

                    {
                        !pixels.length && (
                            <Alert status='info'>
                                <AlertIcon />
                                <AlertTitle>No Pixels Added</AlertTitle>
                            </Alert>
                        )
                    }

                </GridItem>

                <GridItem>
                    <Flex justifyContent={'space-between'} alignItems={'center'} mb={'3'}>
                        <Text fontWeight={'bold'} fontSize={'xl'}>Rules</Text>
                        <Button rounded={'full'} colorScheme={'brand'} onClick={onRuleAddClick}>Add Rule</Button>
                    </Flex>

                    <Grid mb={'5'} gap={6} templateColumns={'repeat(2,1fr)'}>
                        {/* <GridItem>
                            <FormControl>
                                <FormLabel color={'gray.500'}>Default Throttle %</FormLabel>
                                <Input value={defaultThrottle} onChange={(e) => setDefaultThrottle(e.target.value)} bgColor={'white'} rounded={'none'} />
                            </FormControl>
                        </GridItem>

                        <GridItem>
                            <FormControl>
                                <FormLabel color={'gray.500'}>Throttle to Affiliate ID</FormLabel>
                                <Select bgColor={'white'} value={throttleAffiliateId} onChange={(e) => setThrottleAffiliateId(e.target.value)}>
                                    {
                                        trafficSources.map(source => {
                                            return <option value={source.crmID}>{source.name}</option>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </GridItem>

                        <GridItem>
                            <FormControl>
                                <FormLabel color={'gray.500'}>Throttle to Campaign ID</FormLabel>
                                <Input value={throttleCampaignId} onChange={(e) => setThrottleCampaignId(e.target.value)} bgColor={'white'} rounded={'none'} />
                            </FormControl>
                        </GridItem> */}

                        {/* <GridItem>
                            <FormControl>
                                <FormLabel color={'gray.500'}>Prepaid Campaign ID</FormLabel>
                                <Input value={prepaidCampaignId} onChange={(e) => setPrepaidCampaignId(e.target.value)} bgColor={'white'} rounded={'none'} />
                            </FormControl>
                        </GridItem> */}

                        <GridItem>
                            <FormControl>
                                <FormLabel color={'gray.500'}>Internal ID (The affiliate to send the scrubed leads)</FormLabel>
                                <Input value={internalId} onChange={(e) => setInternalId(e.target.value)} bgColor={'white'} rounded={'none'} />
                            </FormControl>
                        </GridItem>

                    </Grid>


                    <Table bgColor={'white'} variant='simple'>
                        <Thead>
                            <Tr>
                                <Th>Rule</Th>
                                <Th>Source</Th>
                                <Th>Throttle %</Th>
                                <Th />
                                <Th />
                            </Tr>
                        </Thead>


                        <Tbody>
                            {
                                rules.map((rule, index) => (
                                    <Tr key={index}>
                                        <Td>{rule.rule}</Td>
                                        <Td>{rule.rule === 'sub-affiliate' && `ts: `} {trafficSources.find(source => source.id === rule.byAffiliateSource)?.name || ''} {rule.rule === 'sub-affiliate' && ` s1:${rule.s1}`} </Td>
                                        <Td>{rule.throttle}</Td>
                                        <Td>
                                            <Text onClick={() => onViewRuleLogsClick(index)} fontSize={'xs'} color={'brand.500'} cursor={'pointer'}>
                                                {`...logs`}
                                            </Text>
                                        </Td>
                                        <Td isNumeric>
                                            <Menu>
                                                <MenuButton>
                                                    <Icon cursor={'pointer'} fontSize={'2xl'} as={AiOutlineMore} />
                                                </MenuButton>
                                                <MenuList>
                                                    <MenuItem onClick={() => onRuleEditClick(index)} >Edit</MenuItem>
                                                    <MenuItem onClick={() => onRuleDeleteClick(index)} color={'red.500'}>Delete</MenuItem>
                                                </MenuList>
                                            </Menu>
                                        </Td>
                                    </Tr>
                                ))
                            }

                        </Tbody>

                    </Table>

                    {
                        !rules.length && (
                            <Alert status='info'>
                                <AlertIcon />
                                <AlertTitle>No Rules Added</AlertTitle>
                            </Alert>
                        )
                    }

                </GridItem>
            </Grid>
        </Box>
    )

}

export default PixelsTab