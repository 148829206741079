import { store } from "../store"
import { baseAxiosInstance } from "../config/axios"

export const fetchOrganizationProjects = async (organizationId: number) => {
    const token = store.getState().user.token

    return baseAxiosInstance.get(`/projects/organization/${organizationId}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}


export const fetchSingleProject = async (projectId: number) => {
    const token = store.getState().user.token

    return baseAxiosInstance.get(`/projects/${projectId}?populate=settings,settings.favicon,globalSEO,globalSEO.anotherProject,assets,thumbnail,konnektiveGeneralSettings,konnektiveLiveSettings,konnektiveInternalSettings,konnektivePrepaidSettings,konnektiveLiveProducts,konnektiveLiveUpsells,konnektiveInternalProducts,konnektiveInternalUpsells,konnektivePrepaidProducts,konnektivePrepaidUpsells,tokens,ruleSettings,rules,rules.byAffiliateSource,rules.logs,rule.logs.traffic_source,pixels,pixels.traffic_source,domains`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const createProjectForOrganization = async (organizationId: number, body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.post(`/projects/create/${organizationId}`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

export const updateProject = async (projectId: number, body: any) => {
    const token = store.getState().user.token

    return baseAxiosInstance.put(`/projects/${projectId}`, body, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}


export const deleteProject = async (projectId: number) => {
    const token = store.getState().user.token

    return baseAxiosInstance.delete(`/projects/${projectId}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}


export const cloneProject = async (organizationId: any, projectId: number) => {
    const token = store.getState().user.token

    return baseAxiosInstance.get(`/projects/clone/${organizationId}/${projectId}`, {
        headers: {
            "Authorization": `${token}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

