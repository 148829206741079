import { Box, Button, Flex, FormControl, FormLabel, Heading, Input, ListItem, Spacer, Text, UnorderedList, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { deactivatePlugin, updatePlugin } from '../../api/plugin'
import { CustomTabs } from '../../components/CustomTabs'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { initOrganizationState } from '../../store/actions/organization'

export const PluginDetailSidebar = ({ selectedPluginId, onBackDropClick, onClose }: any) => {

    const { plugin: { plugins }, organization: { currentSelectedOrganization } } = useAppSelector(state => state)
    const plugin = plugins.find(plugin => plugin.id == selectedPluginId)
    const activatedPlugin = currentSelectedOrganization?.activatedPlugins?.find((p: any) => p.plugin.id == plugin?.id)


    return (
        <>
            <Box onClick={onBackDropClick} position={'fixed'} top={0} left={0} bottom={0} right={0} bgColor={'gray.500'} zIndex={1} opacity={0.5}>
            </Box>
            <Flex
                position={'fixed'}
                top={0}
                right={0}
                bottom={0}
                height="100vh"
                width={'400px'}
                direction="column"
                bg="white"
                zIndex={1}
            >
                <Box p={'5'}>
                    <Flex justifyContent={'space-between'}>
                        <Text fontSize={'xl'} fontWeight={'semibold'}>{plugin?.attributes.name}</Text>
                    </Flex>
                </Box>

                <CustomTabs
                    tabs={[
                        'INFO',
                        'SETTINGS'
                    ]}
                    panels={[
                        <InfoTab onClose={onClose} plugin={plugin} />,
                        <SettingsTab plugin={plugin} activatedPlugin={activatedPlugin} onClose={onClose} />
                    ]}
                />

            </Flex>
        </>
    )
}

const InfoTab = ({ plugin, onClose }: any) => {

    const { organization: { currentSelectedOrganization } } = useAppSelector(state => state)
    const dispatch = useAppDispatch()

    const [deleteLoading, setDeleteLoading] = useState(false)

    const onDeleteClick = async () => {
        setDeleteLoading(true)
        deactivatePlugin(plugin?.id, currentSelectedOrganization?.id)
            .then(response => {
                dispatch(initOrganizationState())
                onClose()

            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setDeleteLoading(false)
            })
    }

    return (
        <Box p={'2'}>
            <Box mb={'3'}>
                <Text fontWeight={'semibold'}>GENERAL</Text>
                <Text color={'gray.500'}>{plugin?.attributes.description}</Text>
            </Box>

            {/* <Box mb={'5'}>
                <Text fontWeight={'semibold'}>ADDED</Text>
                <UnorderedList color={'gray.500'}>
                    <ListItem>Adds website build blocks</ListItem>
                </UnorderedList>
            </Box> */}

            <Flex justifyContent={'flex-end'}>
                <Button isLoading={deleteLoading} rounded={'full'} onClick={onDeleteClick} colorScheme={'red'}>Delete</Button>
            </Flex>


        </Box>
    )
}

const SettingsTab: React.FC<any> = ({ activatedPlugin, plugin, onClose }) => {

    const { organization: { currentSelectedOrganization } } = useAppSelector(state => state)
    const toast = useToast()

    const configVars = {
        ...activatedPlugin?.settings?.configVars || {}
    }

    const onSaveClick = () => {

        updatePlugin(plugin.id, currentSelectedOrganization?.id, {
            settings: {
                configVars
            }
        })
            .then(response => {
                console.log(response)
                onClose()
                toast({
                    status:'success',
                    title:'Settings Updated'
                })
            })
            .catch(err => {
                console.log(err)
                toast({
                    status:'error',
                    title:'Failed to update settings'
                })
            })
            .finally(() => {

            })

    }



    return (
        <Flex h={'full'} direction={'column'}>
            <Box p={'2'}>


                {plugin?.attributes?.config?.configVars?.map((variable: any) => (
                    <ConfigurationVariable
                        data={configVars}
                        defaultValue={configVars[variable] || ''}
                        variable={variable} />
                )
                )}

            </Box>
            <Spacer />
            <Flex justifyContent={'flex-end'}>
                <Button onClick={onSaveClick} rounded={'full'} colorScheme={'brand'}>Save</Button>
            </Flex>
        </Flex>

    )
}

const ConfigurationVariable = ({ data, defaultValue, variable }: any) => {

    const [value, setValue] = useState(defaultValue || '')

    useEffect(() => {
        data[variable] = value
    }, [value])

    return (
        <FormControl mb={'3'}>
            <FormLabel color={'gray.500'}>{variable}</FormLabel>
            <Input value={value} onChange={(e) => setValue(e.target.value)} rounded={'none'} />
        </FormControl>
    )
}