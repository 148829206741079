import { Box, Center, Circle, Flex, HStack, IconButton, Text } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineLogout } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { initUserState } from '../../store/actions/user'
import { InfoSection } from './InfoSection'

export const OnboardingPage = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    
    const onLogoutClick = async () => {
        localStorage.removeItem('user')
        localStorage.removeItem('userOrganization')
        await dispatch(initUserState())
        navigate('/login', { replace: true })
    }

    return (
        <Box minH={'100vh'} bgColor={'gray.50'}>

            <Flex p={'5'} justifyContent={'flex-end'}>
                <IconButton
                    rounded={'full'}
                    aria-label='Search database'
                    colorScheme='brand'
                    onClick={onLogoutClick}
                    icon={<AiOutlineLogout />}
                />
            </Flex>

            <Center>
                <Box shadow={'sm'} my={'10'} p={'5'} w={'40%'} bgColor={'white'}>

                    <Text mb={'8'} textAlign={'center'} color={'brand.500'} fontWeight={'bold'} fontSize={'3xl'}>Super Funnels</Text>
                    {/* <Box mb={'5'} px={'5'}>
                        <StepsRow />
                    </Box> */}

                    <InfoSection />
                </Box>
            </Center>
        </Box>
    )
}


const StepsRow = () => {
    return (
        <Flex justifyContent={'space-between'}>
            <Step step={1} isActive title="Sign Up" />
            <Step step={2} title="Setup" />
            <Step step={3} title="Build" />

        </Flex>
    )
}

const Step = ({ step, title, isActive = false }: any) => (
    <HStack>
        <Circle bgColor={isActive && 'brand.500'} borderColor={'gray.200'} borderWidth={isActive || 'thin'} size={'8'}>
            <Text color={isActive ? 'white' : 'gray.300'} fontSize={'lg'}>{step}</Text>
        </Circle>
        <Text fontWeight={'semibold'}>{title}</Text>
    </HStack>
)