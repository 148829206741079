import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Select, Text } from '@chakra-ui/react'
import React from 'react'
import { FiMoreVertical } from 'react-icons/fi'

export const UserDetailCard = ({
    heading,
    detail,
    detail2,
    roleOptions = [],
    selectedRoleOption,
    onRoleOptionChange,
    onRemoveClick,
    isRemoveEnabled = true,
    isRoleOptionsDisabled = false
}: any) => {
    return (

        <Box bgColor={'white'} p={'5'}>

            <Flex direction={'row'} alignItems={'flex-start'} justifyContent={'space-between'}>
                <Box>

                    <Text fontWeight={'semibold'} fontSize={'xl'}>{heading}</Text>
                    <Text color={'gray.500'} >{detail}</Text>
                    <Text color={'gray.500'} >{detail2}</Text>
                </Box>

                {
                    isRemoveEnabled ?
                        <Menu>
                            <MenuButton>
                                <FiMoreVertical cursor={'pointer'} fontSize={'20px'} />
                            </MenuButton>
                            <MenuList>
                                <MenuItem onClick={onRemoveClick}>Remove</MenuItem>
                            </MenuList>
                        </Menu> : null
                }

            </Flex>


            <Select disabled={isRoleOptionsDisabled} onChange={onRoleOptionChange} borderRadius={'0'} mt={'3'} value={selectedRoleOption}>
                {
                    roleOptions.map((opt: any, index: number) => (
                        <option key={index} value={opt.value}>{opt.label}</option>
                    ))
                }
            </Select>


        </Box>
    )
}
