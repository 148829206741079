import React, { useEffect } from 'react';
import Router from './router';
import { useAppDispatch, useAppSelector } from './hooks/redux';
import '@fontsource/inter/300.css'
import { useNavigate } from 'react-router-dom';
import { routeNames } from './router/routeNames';
import { initUserState } from './store/actions/user';
import { initOrganizationState } from './store/actions/organization';
import { initProjectState } from './store/actions/project';
import { initTrafficSourceState } from './store/actions/trafficSource';
import { initPluginState } from './store/actions/plugin';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function App() {

  const dispatch = useAppDispatch()
  const { user: { user, isUserLoading }, organization: { isOrganizationStateLoading } } = useAppSelector(state => state)

  useEffect(() => {
    dispatch(initUserState())
  }, [])

  useEffect(() => {

    (async () => {
      if (!isUserLoading && user) {
        await dispatch(initOrganizationState())
        dispatch(initProjectState())
        dispatch(initTrafficSourceState())
        dispatch(initPluginState())
      }
    })()

  }, [user, isUserLoading])

  return (
    <Router />
  );
}

export default App;
