import { Box, Heading, Text } from '@chakra-ui/react'
import React, { } from 'react'

export const CustomHeaderCard = ({ children , heading }: any) => {
    return (
        <Box>
            <Box bgColor={'white'} p={'4'}>
                <Text fontSize={'xl'} fontWeight={'semibold'}>{heading}</Text>
            </Box>
            <Box bgColor={'gray.50'} p={'4'}>
                {children}
            </Box>
        </Box>
    )
}
