import { Box, Flex, FormControl, FormLabel, Grid, GridItem, Select, Text, useDisclosure } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import DateTimePickerModal from './DateTimePickerModal'

export const PaginationFilters = ({ onFiltersChange, pageCount = 1 }: any) => {


    const { isOpen, onClose, onOpen } = useDisclosure()

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    })

    const [noOfDisplayRecord, setNoOfDisplayRecords] = useState("25");
    const [currentPage, setCurrentPage] = useState(1)


    useEffect(() => {
        onFiltersChange({
            selectedDateRange,
            noOfDisplayRecord,
            currentPage
        })

    }, [selectedDateRange, noOfDisplayRecord, currentPage])



    return (
        <Grid gap={5} templateColumns={'repeat(3,1fr)'}>
            <DateTimePickerModal onRangeChange={(newRange: any) => setSelectedDateRange(newRange)} selectedRange={selectedDateRange} isOpen={isOpen} onClose={onClose} />

            <GridItem>
                <Box>

                    {/* <Box flex={1}>
                <Text>Status</Text>
                <Select value={selectedStatus} onChange={(e: any) => setSelectedStatus(e.target.value)} bgColor={'white'}>
                    <option value={'all'}>All</option>
                    <option value={'approved'}>Approved</option>
                    <option value={'declined'}>Declined</option>
                </Select>
            </Box> */}

                    <Box flex={1}>
                        <Text>Date Filter</Text>
                        <Text onClick={() => onOpen()} cursor={'pointer'} p={'1'} bgColor={'white'}>{dayjs(selectedDateRange.startDate).format('DD MMM YYYY')} - {dayjs(selectedDateRange.endDate).format('DD MMM YYYY')}</Text>
                    </Box>
                </Box>
            </GridItem>

            <GridItem>

            </GridItem>

            <GridItem>
                <Flex justifyContent={'end'}>


                    <FormControl mr={'3'}>
                        <FormLabel>No of Records</FormLabel>
                        <Select w={'full'} bgColor={'white'} value={noOfDisplayRecord} onChange={(e) => setNoOfDisplayRecords((e.target.value))}>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                            <option value="250">250</option>
                            <option value="500">500</option>
                            <option value="1000">1000</option>
                            <option value="2000">2000</option>
                            <option value="4000">4000</option>
                        </Select>
                    </FormControl>


                    <FormControl>
                        <FormLabel>Go To Page</FormLabel>
                        <Select w={'full'} bgColor={'white'} value={`${currentPage || 1}`} onChange={(e) => setCurrentPage(parseInt(e.target.value))}>
                            {
                                Array(pageCount || 1).fill('').map((v, i) => (
                                    <option value={`${i + 1}`}>{i + 1}</option>
                                ))
                            }
                        </Select>
                    </FormControl>


                </Flex>
            </GridItem>

        </Grid>

    )
}
