import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Text } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

export const TrafficSourceDetailSidebar = ({ trafficSource, onBackDropClick, onEditTrafficSource, onAddNewTrafficSource }: any) => {


    const isEdit = !!trafficSource

    const [isLoading, setIsLoading] = useState(false)

    const validationSchema = yup.object().shape({
        name: yup.string().required('Required'),
        crmId: yup.string().required('Required')
    })

    const { register, handleSubmit, watch, formState: { errors }, setValue } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onSaveClick = handleSubmit(async values => {

        setIsLoading(true)
        if (isEdit)
            await onEditTrafficSource(trafficSource.id, values)
        else
            await onAddNewTrafficSource(values)
        setIsLoading(false)
    })

    useEffect(() => {

        if (trafficSource) {
            setValue('name', trafficSource.name)
            setValue('crmId', trafficSource.crmID)
        }

    }, [trafficSource])

    return (
        <>
            <Box onClick={onBackDropClick} position={'fixed'} top={0} left={0} bottom={0} right={0} bgColor={'gray.500'} zIndex={1} opacity={0.5}>
            </Box>
            <Flex
                overflow={'auto'}
                position={'fixed'}
                top={0}
                right={0}
                bottom={0}
                height="100vh"
                width={'300px'}
                direction="column"
                bg="white"
                zIndex={1}
            >
                <Box p={'5'}>
                    <Flex justifyContent={'space-between'}>
                        <Text fontSize={'xl'} fontWeight={'semibold'}>Add Traffic Source</Text>
                    </Flex>
                </Box>

                <Box p={'5'}>
                    <FormControl isInvalid={!!errors.name} mb={'3'}>
                        <FormLabel>Name</FormLabel>
                        <Input {...register('name')} />
                    </FormControl>

                    <FormControl isInvalid={!!errors.crmId} mb={'3'}>
                        <FormLabel>ID on CRM</FormLabel>
                        <Input {...register('crmId')} />
                    </FormControl>

                    <HStack mt={'5'} justifyContent={'flex-end'} spacing={'2'}>
                        <Button onClick={onBackDropClick} rounded={'full'}>Cancel</Button>
                        <Button isLoading={isLoading} onClick={onSaveClick} rounded={'full'} colorScheme={'brand'}>Save</Button>
                    </HStack>

                </Box>



            </Flex>
        </>
    )
}
