import { Button, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import RichTextEditor from '../../components/TextEditor'

export const NewTokenModal = ({ tokens, selectedToken, index, isOpen, onClose, onAddNewToken, onEditNewToken }: any) => {


    const [key, setKey] = useState('')
    const [value, setValue] = useState('')

    const toast = useToast()

    const isEdit = !!selectedToken

    useEffect(() => {
        if (isEdit) {
            setKey(selectedToken.key)
            setValue(selectedToken.value)
        } else {
            setKey('')
            setValue('')
        }
    }, [isEdit, selectedToken, index])


    const onSaveClick = () => {



        if (isEdit) {

            const tokenFound = tokens.find((t: any) => t.key === key && (isEdit && selectedToken.key !== key))

            if (tokenFound) {
                toast({
                    status: 'error',
                    title: 'token key already exists.'
                })
                return
            }
            onEditNewToken(index, { key, value })
        } else {

            const tokenFound = tokens.find((t: any) => t.key === key)

            if (tokenFound) {
                toast({
                    status: 'error',
                    title: 'token key already exists.'
                })
                return
            }
            onAddNewToken({ key, value })
        }

        onClose()
    }



    return (
        <Modal size={'xl'} isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>New Token</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl mb={'3'}>
                        <FormLabel>Key</FormLabel>
                        <Input value={key} onChange={(e) => setKey(e.target.value)} rounded={'none'} />
                    </FormControl>

                    <FormControl maxH={'300px'} overflow={'auto'}>
                        <FormLabel>Value</FormLabel>
                        {/* <Input {...register('value')} rounded={'none'} /> */}
                        <RichTextEditor data={value} onChange={(v: any) => setValue(v)} />
                    </FormControl>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onSaveClick} rounded={'full'} colorScheme='brand'>Save</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
