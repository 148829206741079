import { Box, Button, Center, FormControl, FormErrorMessage, FormLabel, Input, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { routeNames } from '../../router/routeNames'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { forgotPassword } from '../../api/auth'

export const ForgotPasswordPage = () => {

    const navigate = useNavigate()
    const toast = useToast()

    const [isLoading , setIsLoading] = useState(false)

    const validationSchema = yup.object().shape({
        email: yup.string().required('Required').email('Enter a valid email')
    })

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onResetClick = handleSubmit((values) => {

        setIsLoading(true)
        forgotPassword(values)
            .then(response => {
                toast({
                    status: 'success',
                    title: 'Email Sent'
                })
            })
            .catch(err => {
                toast({
                    status: 'error',
                    title: 'Failed',
                    description: err?.response?.data?.error?.message || 'Try again'
                })
            })
            .finally(() => setIsLoading(false))

    })

    return (
        <Box bgColor={'gray.50'} minH={'100vh'}>
            <Center py={'24'}>
                <Box bgColor={'white'} shadow={'md'} p={'5'} w={'35%'}>
                    <Text fontSize={'3xl'} textAlign={'center'} fontWeight={'bold'} color={'brand.500'}>Super Funnels</Text>
                    <Text mb={'5'} fontSize={'2xl'} textAlign={'center'} fontWeight={'bold'} >Welcome To Super Funnels</Text>

                    <Box px={'5'}>

                        <FormControl isInvalid={!!errors.email} mb={'3'}>
                            <FormLabel>Email</FormLabel>
                            <Input {...register('email')} />
                            <FormErrorMessage>{`${errors?.email?.message || ''}`}</FormErrorMessage>
                        </FormControl>

                        <Center mb={'5'}>
                            <Button isLoading={isLoading} onClick={onResetClick} rounded={'full'} colorScheme={'brand'}>Send Reset Password Email</Button>
                        </Center>

                        <Text onClick={() => navigate(routeNames.login())} cursor={'pointer'} textAlign={'center'} color={'brand.500'}>Back to Login</Text>


                    </Box>
                </Box>
            </Center>
        </Box>
    )
}
