import { Box, Text, Flex, Divider, Menu, MenuButton, Button, MenuList, MenuItem, Center, Square, Avatar, HStack } from '@chakra-ui/react'
import React from 'react'
import { AiOutlineMenu, AiOutlineArrowDown, AiOutlineQuestionCircle } from 'react-icons/ai'
import { BiBuildings } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { routeNames } from '../../router/routeNames'
import { switchOrganization } from '../../store/actions/organization'
import { initUserState } from '../../store/actions/user'

export const Navbar = ({ onSideMenuClick, onHeadingClick, heading, subHeading }: any) => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { currentSelectedOrganization, userOrganizations } = useAppSelector(state => state.organization)

    const onLogoutClick = async () => {
        localStorage.removeItem('user')
        localStorage.removeItem('userOrganization')
        await dispatch(initUserState())
        navigate('/login', { replace: true })
    }

    const onOrganizationChange = (organization: any) => {
        dispatch(switchOrganization(organization.id))
    }

    return (
        <Flex>
            <Square onClick={onSideMenuClick} cursor={'pointer'} p={'4'} bgColor={'brand.500'}>
                <Center>
                    <AiOutlineMenu fontSize={'25px'} color='white' />
                </Center>
            </Square>

            <Flex flex={1} p={'3'}>

                <Flex alignItems={'center'} >
                    <Text cursor={'pointer'} onClick={onHeadingClick} fontWeight={'bold'} fontSize={'20px'} color={'brand.500'}>{heading}</Text>
                    <Box px={1} />
                    <Divider orientation='vertical' />
                    <Box px={1} />
                    <Text fontWeight={'semibold'} color={'gray.500'}>{subHeading}</Text>
                </Flex>

                <Box flex={1} />

                <HStack spacing={'5'} alignItems={'center'}>

                    <Menu>
                        <MenuButton color={'gray.500'} rounded={'full'} as={Button} leftIcon={<BiBuildings />} rightIcon={<AiOutlineArrowDown />}>
                            {currentSelectedOrganization?.name}
                        </MenuButton>
                        <MenuList>
                            {
                                userOrganizations.map((org) => (
                                    <MenuItem onClick={() => onOrganizationChange(org)} key={org.id}>{org.name}</MenuItem>
                                ))
                            }
                        </MenuList>
                    </Menu>

                    <Menu>
                        <MenuButton size={'sm'} as={Avatar} cursor={'pointer'} />
                        <MenuList>
                            <MenuItem onClick={() => navigate(routeNames.profile())}>Profile</MenuItem>
                            <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                        </MenuList>
                    </Menu>

                    <AiOutlineQuestionCircle fontSize={'30px'} color={'gray.100'} />

                </HStack>

            </Flex>
        </Flex>
    )
}
