import { useDisclosure } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { createProjectForOrganization } from '../../api/project'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { routeNames } from '../../router/routeNames'
import { initProjectState } from '../../store/actions/project'
import ProjectTypeSelectionModal from './ProjectTypeSelectionModal'

export default function ProjectCreate({
    isOpen,
    onOpen,
    onClose
}: any) {

    const { project: { projects }, organization: { currentSelectedOrganization, isOrganizationStateLoading } } = useAppSelector(state => state)
    const isMastercardVisaPluginActivated = !!currentSelectedOrganization?.activatedPlugins?.find((p: any) => p.plugin.identifier === 'master-visa')

    console.log(isMastercardVisaPluginActivated)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const createProject = (selectedType: any) => {
        createProjectForOrganization(currentSelectedOrganization?.id, {
            data: {
                type: selectedType
            }
        })
            .then(response => {
                dispatch(initProjectState())
                navigate(routeNames.newProject(response.id))
            })
            .catch(err => {

            })
    }

    const onConfirm = async (selectedType: any) => {
        createProject(selectedType)
    }

    useEffect(() => {

        if (!isMastercardVisaPluginActivated && isOpen) {
            createProject('secure-nonsecure')
        }

    }, [isMastercardVisaPluginActivated, isOpen])

    if (isMastercardVisaPluginActivated)
        return (
            <ProjectTypeSelectionModal onConfirmClick={onConfirm} isOpen={isOpen} onClose={onClose} />
        )
    else
        return null
}
