import { Box, Divider, Flex, Heading, Spacer, Stack } from '@chakra-ui/react'
import * as React from 'react'
import {
  FaRegBell,
  FaRegChartBar,
  FaRegHeart,
  FaRegPaperPlane,
  FaRegQuestionCircle,
  FaUser,
  FaPlug,
  FaTrafficLight,
  FaUsersCog
} from 'react-icons/fa'

import { AiOutlineFolderOpen, AiFillTag } from 'react-icons/ai'
import { RiUserSettingsLine } from 'react-icons/ri'
import { IoIosTimer } from 'react-icons/io'


import { NavLink } from './NavLink'
import { useLocation } from 'react-router-dom'
import { routeNames } from '../../../router/routeNames'

export const Sidebar: React.FC<any> = ({ onBackdropClick }) => {


  const location = useLocation()

  return (
    <>
      <Box
        position={'fixed'}
        onClick={onBackdropClick}
        top={0} left={0} bottom={0} right={0} bgColor={'gray.500'} zIndex={1} opacity={0.5} />
      <Flex
        position={'fixed'}
        top={0}
        left={0}
        bottom={0}
        height="100vh"
        width={'250px'}
        direction="column"
        bg="brand.500"
        color="white"
        px={6}
        py={8}
        zIndex={1}
      >
        <Box mb={8}>
          <Heading>Super Funnels</Heading>
        </Box>
        <Stack spacing={6}>
          <Stack>
            <NavLink to={routeNames.projects()} label="Projects" icon={AiOutlineFolderOpen} isActive={location.pathname.includes('/projects')} />
            <NavLink to={routeNames.plugins()} label="Plugins" icon={FaPlug} isActive={location.pathname.includes('/plugins')} />
            <NavLink to={routeNames.trafficSources()} label="Traffic Sources" icon={FaTrafficLight} isActive={location.pathname.includes('/traffic-sources')} />
            {/* <NavLink to={routeNames.products()} label="Products" icon={AiFillTag} isActive={location.pathname.includes('/products')} /> */}
            <NavLink to={routeNames.accountSettings()} label="Account Settings" icon={RiUserSettingsLine} isActive={location.pathname.includes('/account-settings')} />
            <NavLink to={routeNames.billingManager()} label="Billing Manager" icon={FaUsersCog} isActive={location.pathname.includes('/billing-manager')} />
            <NavLink to={routeNames.activityLogs()} label="Activity Logs" icon={IoIosTimer} isActive={location.pathname.includes('/activity-logs')} />
        
          </Stack>
        </Stack>
        <Spacer />
      </Flex>
    </>

  )
}
