import { Box, Slide } from '@chakra-ui/react'
import React, { useState } from 'react'
import { Navbar } from './components/Navbar'
import { Sidebar } from './components/Sidebar/App'


interface Props {
    heading: string
    subHeading: string
    onHeadingClick: any
}

export const DashboardLayout: React.FC<any> = ({ children, heading, subHeading , onHeadingClick }) => {

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    return (
        <>



            <Slide direction='left' in={isSidebarOpen} style={{ zIndex: 10 }}>
                <Sidebar onBackdropClick={() => setIsSidebarOpen(false)} />
            </Slide>

            <Box>
                <Navbar heading={heading} subHeading={subHeading} onHeadingClick={onHeadingClick} onSideMenuClick={() => setIsSidebarOpen(true)} />
                <Box minH={'100vh'} bgColor={'gray.100'}>
                    {children}
                </Box>
            </Box>
        </>
    )
}
