import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useColorModeValue as mode,
  useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { registerUser } from '../../api/auth';
import { routeNames } from '../../router/routeNames';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../hooks/redux';
import { initUserState } from '../../store/actions/user';

export const Form = () => {

  const toast = useToast()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const validationSchema = yup.object().shape({
    email: yup.string().required('Required').email('Enter a valid email'),
    firstname: yup.string().required('Required'),
    lastname: yup.string().required('Required'),
    companyName: yup.string().required('Required'),
    phoneNumber: yup.string().required('Required')
  })

  const { register, handleSubmit, watch, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const [loading, setLoading] = React.useState(false)


  const onCreateAccountClick = handleSubmit(values => {

    setLoading(true)
    registerUser({
      ...values,
      username: values.email
    })
      .then(async response => {
        navigate(routeNames.accountRequested())

      })
      .catch(err => {
        toast({
          status: 'error',
          title: 'Signup Failed',
          description: err?.response?.data?.error?.message || 'Try again'
        })
      })
      .finally(() => {
        setLoading(false)
      })
  })

  return (

    <Stack spacing="4">
      <FormControl>
        <FormLabel mb={1}>Firstname</FormLabel>
        <Input {...register('firstname')} autoComplete="current-password" />
      </FormControl>

      <FormControl>
        <FormLabel mb={1}>Lastname</FormLabel>
        <Input {...register('lastname')} autoComplete="current-password" />
      </FormControl>

      <FormControl>
        <FormLabel mb={1}>Company Name</FormLabel>
        <Input {...register('companyName')} autoComplete="current-password" />
      </FormControl>

      <FormControl id="email">
        <FormLabel mb={1}>Email</FormLabel>
        <Input {...register('email')} type="email" autoComplete="email" />
      </FormControl>

      <FormControl>
        <FormLabel mb={1}>Phone</FormLabel>
        <Input {...register('phoneNumber')} />
      </FormControl>

      <Button isLoading={loading} onClick={onCreateAccountClick} type="submit" rounded={'full'} colorScheme="brand" size="lg" fontSize="md">
        Create my account
      </Button>
    </Stack>
  )
}
