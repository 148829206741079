import { Box, Button, Center, FormControl, FormErrorMessage, FormLabel, Input, Text, useToast } from '@chakra-ui/react'
import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { resetPassword } from '../../api/auth'
import { routeNames } from '../../router/routeNames'

export const ResetPasswordPage = () => {


    const navigate = useNavigate()
    const toast = useToast()

    const [searchParams, setSearchParams] = useSearchParams()
    const code = searchParams.get('code')

    const [isLoading, setIsLoading] = useState(false)

    const validationSchema = yup.object().shape({
        password: yup.string().required('Required'),
        passwordConfirmation: yup.string().required('Required'),
    })

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onResetClick = handleSubmit((values) => {

        setIsLoading(true)

        resetPassword({
            ...values,
            code
        })
            .then(response => {
                toast({
                    status: 'success',
                    title: 'Password Reset'
                })
                navigate(routeNames.login())
            })
            .catch(err => {
                toast({
                    status: 'error',
                    title: 'Failed',
                    description: err?.response?.data?.error?.message || 'Try again'
                })
            })
            .finally(() => setIsLoading(false))

    })

    return (
        <Box bgColor={'gray.50'} minH={'100vh'}>
            <Center py={'24'}>
                <Box bgColor={'white'} shadow={'md'} p={'5'} w={'35%'}>
                    <Text fontSize={'3xl'} textAlign={'center'} fontWeight={'bold'} color={'brand.500'}>Super Funnels</Text>
                    <Text mb={'5'} fontSize={'2xl'} textAlign={'center'} fontWeight={'bold'} >Welcome To Super Funnels</Text>

                    <Box px={'5'}>

                        <FormControl mb={'3'}>
                            <FormLabel>New Password</FormLabel>
                            <Input {...register('password')} />
                            <FormErrorMessage>{`${errors?.password?.message || ''}`}</FormErrorMessage>
                        </FormControl>

                        <FormControl mb={'3'}>
                            <FormLabel>Again New Password</FormLabel>
                            <Input {...register('passwordConfirmation')} />
                            <FormErrorMessage>{`${errors?.passwordConfirmation?.message || ''}`}</FormErrorMessage>
                        </FormControl>


                        <Center mb={'5'}>
                            <Button onClick={onResetClick} isLoading={isLoading} rounded={'full'} colorScheme={'brand'}>Reset</Button>
                        </Center>

                    </Box>
                </Box>
            </Center>
        </Box>
    )
}
