import { Box, Center, Flex, Grid, GridItem, IconButton, Spinner, Stat, StatGroup, StatLabel, StatNumber, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchSingleBmsBatch } from '../../api/bms-batch'
import { useAppSelector } from '../../hooks/redux'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { AiOutlineReload } from 'react-icons/ai'

export const RerunOrderDetailPage = () => {


    const batchStatusColors: any = {
        'pending': 'blue.500',
        'completed': 'green.500'
    }

    const { currentSelectedOrganization } = useAppSelector(state => state.organization)
    const { id } = useParams()

    const [rerunBatchDetail, setRerunBatchDetail] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {

        if (id && currentSelectedOrganization) {
            fetchData()
        }

    }, [id, currentSelectedOrganization])

    const fetchData = () => {
        setIsLoading(true)
        fetchSingleBmsBatch(id, currentSelectedOrganization?.id)
            .then(response => {
                setRerunBatchDetail(response)
            })
            .finally(() => setIsLoading(false))
    }

    const onRefreshClick = () => {
        fetchData()
    }

    const completedOrders = rerunBatchDetail?.bms_batch_orders?.filter((o: any) => o.status === 'completed')
    const failedOrders = rerunBatchDetail?.bms_batch_orders?.filter((o: any) => o.status === 'failed')
    const pendingOrders = rerunBatchDetail?.bms_batch_orders?.filter((o: any) => o.status === 'pending')


    return (
        <DashboardLayout heading={'Rerun-Order Batch'}>

            <Center>
                {
                    isLoading ?
                        <Spinner mt={'10'} /> :
                        (
                            <Box my={'10'} p={'2'} bgColor={'white'} w={'70%'}>
                                <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                                    <Flex justifyContent={'space-between'}>

                                        <Box>
                                            <Text fontWeight={'bold'} fontSize={'xl'}>Batch Details</Text>
                                            <Text >View your Re-run orders batch details here.</Text>
                                        </Box>

                                        <IconButton onClick={onRefreshClick} aria-label='reload' icon={<AiOutlineReload />} />
                                    </Flex>
                                </Box>

                                <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                                    <StatGroup>
                                        <Stat>
                                            <StatLabel>Approved</StatLabel>
                                            <StatNumber>{completedOrders?.length || 0}</StatNumber>
                                        </Stat>

                                        <Stat>
                                            <StatLabel>Declined</StatLabel>
                                            <StatNumber>{failedOrders?.length || 0}</StatNumber>
                                        </Stat>

                                        <Stat>
                                            <StatLabel>Found</StatLabel>
                                            <StatNumber>{rerunBatchDetail?.bms_batch_orders?.length || 0}</StatNumber>
                                        </Stat>

                                        <Stat>
                                            <StatLabel>Unprocessed</StatLabel>
                                            <StatNumber>{pendingOrders?.length || 0}</StatNumber>
                                        </Stat>

                                    </StatGroup>
                                </Box>

                                <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                                    <Text fontWeight={'bold'} fontSize={'xl'}>Settings</Text>
                                    <Grid templateColumns={'repeat(3,1fr)'}>
                                        <GridItem>
                                            <Box mt={'3'}>
                                                <Text fontSize={'sm'}>CRM</Text>
                                                <Text fontWeight={'bold'} fontSize={'md'}>{rerunBatchDetail?.crm}</Text>
                                            </Box>
                                        </GridItem>

                                        <GridItem>
                                            <Box mt={'3'}>
                                                <Text fontSize={'sm'}>Originating Project</Text>
                                                <Text fontWeight={'bold'} fontSize={'md'}>{rerunBatchDetail?.currentProject?.settings?.name}</Text>
                                            </Box>

                                        </GridItem>

                                        <GridItem>
                                            <Box mt={'3'}>
                                                <Text fontSize={'sm'}>New Project</Text>
                                                <Text fontWeight={'bold'} fontSize={'md'}>{rerunBatchDetail?.newProeject?.settings?.name}</Text>
                                            </Box>
                                        </GridItem>

                                        <GridItem>
                                            <Box mt={'3'}>
                                                <Text fontSize={'sm'}>New Product</Text>
                                                <Text fontWeight={'bold'} fontSize={'md'}>{rerunBatchDetail?.productId}</Text>
                                            </Box>
                                        </GridItem>

                                        <GridItem>
                                            <Box mt={'3'}>
                                                <Text fontSize={'sm'}>New Campaign</Text>
                                                <Text fontWeight={'bold'} fontSize={'md'}>{rerunBatchDetail?.campaignId}</Text>
                                            </Box>
                                        </GridItem>

                                        <GridItem>

                                            <Box mt={'3'}>
                                                <Text fontSize={'sm'}>Start Time</Text>
                                                <Text fontWeight={'bold'} fontSize={'md'}>{rerunBatchDetail?.fromDate ? dayjs(rerunBatchDetail?.fromDate).format('DD-MM-YYYY hh:mm a') : 'None'}</Text>
                                            </Box>

                                        </GridItem>


                                        <GridItem>

                                            <Box mt={'3'}>
                                                <Text fontSize={'sm'}>End Time</Text>
                                                <Text fontWeight={'bold'} fontSize={'md'}>{rerunBatchDetail?.toDate ? dayjs(rerunBatchDetail?.toDate).format('DD-MM-YYYY hh:mm a') : 'None'}</Text>
                                            </Box>

                                        </GridItem>

                                        <GridItem>
                                            <Box mt={'3'}>
                                                <Text fontSize={'sm'}>Match By Order Id</Text>
                                                <Text fontWeight={'bold'} fontSize={'md'}>{rerunBatchDetail?.isMatchByOrderId ? 'TRUE' : 'FALSE'}</Text>
                                            </Box>
                                        </GridItem>

                                        <GridItem>
                                            <Box mt={'3'}>
                                                <Text fontSize={'sm'}>Use 3DS Rebill Token</Text>
                                                <Text fontWeight={'bold'} fontSize={'md'}>{rerunBatchDetail?.isUse3DSToken ? 'TRUE' : 'FALSE'}</Text>
                                            </Box>
                                        </GridItem>

                                    </Grid>



                                </Box>

                                <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                                    <Flex px={'3'} justifyContent={'space-between'} alignItems={'center'}>

                                        <Text mb={'3'} fontWeight={'bold'} fontSize={'xl'}>{rerunBatchDetail?.bms_batch_orders?.length || 0} Orders</Text>

                                        <Text fontWeight={'bold'} color={batchStatusColors[rerunBatchDetail?.status]}>{rerunBatchDetail?.status?.toUpperCase()}</Text>
                                    </Flex>

                                    <TableContainer>

                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Order ID</Th>
                                                    <Th>Status</Th>
                                                    <Th>Scheduled Time</Th>
                                                    <Th>Response</Th>
                                                </Tr>
                                            </Thead>

                                            <Tbody>
                                                {
                                                    rerunBatchDetail?.bms_batch_orders?.map((order: any) => (
                                                        <Tr>
                                                            <Td>{order.orderId}</Td>
                                                            <Td>{order.status}</Td>
                                                            <Td>{dayjs(order?.createdAt).format('DD-MM-YYYY hh:mm a')}</Td>
                                                            <Td>{order.response}</Td>


                                                        </Tr>
                                                    ))
                                                }

                                            </Tbody>

                                        </Table>
                                    </TableContainer>
                                </Box>

                            </Box>
                        )
                }


            </Center>
        </DashboardLayout>
    )
}
