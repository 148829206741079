import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text, useToast } from '@chakra-ui/react'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import { setupOrganization } from '../../api/organization';
import { useNavigate } from 'react-router-dom';
import { routeNames } from '../../router/routeNames';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { initUserState } from '../../store/actions/user';

export const InfoSection = () => {

    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const toast = useToast()
    const dispatch = useAppDispatch()

    const validationSchema = yup.object().shape({
        firstname: yup.string().required('Required'),
        lastname: yup.string().required('Required'),
        companyName: yup.string().required('Required'),
        phone: yup.string().required('Required'),
    })

    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onCreateMyAccountClick = handleSubmit(values => {
        setLoading(true)
        setupOrganization(values)
            .then(async response => {
                await dispatch(initUserState())
                navigate(routeNames.home())
            })
            .catch(err => {
                toast({
                    status: 'error',
                    title: 'Setup Failed',
                    description: err?.response?.data?.error?.message || 'Try again'
                })
            })
            .finally(() => {
                setLoading(false)
            })
    })


    return (
        <Box>
            <Text mb={'3'} fontSize={'2xl'} fontWeight={'bold'} textAlign={'center'}>Congratulations</Text>
            <Text mb={'5'} fontSize={'lg'} textAlign={'center'}>Since this is your first time here, let’s finish your account details!</Text>

            <Box>
                <FormControl isInvalid={!!errors.firstname} mb={'3'}>
                    <FormLabel>First Name</FormLabel>
                    <Input {...register('firstname')} />
                    <FormErrorMessage>{`${errors?.firstname?.message}` || ''}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.lastname} mb={'3'}>
                    <FormLabel>Last Name</FormLabel>
                    <Input {...register('lastname')} />
                    <FormErrorMessage>{`${errors?.lastname?.message}` || ''}</FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!errors.companyName} mb={'3'}>
                    <FormLabel>Company Name</FormLabel>
                    <Input {...register('companyName')} />
                    <FormErrorMessage>{`${errors?.companyName?.message}` || ''}</FormErrorMessage>

                </FormControl>

                <FormControl isInvalid={!!errors.phone} mb={'3'}>
                    <FormLabel>Phone</FormLabel>
                    <Input {...register('phone')} />
                    <FormErrorMessage>{`${errors?.phone?.message}` || ''}</FormErrorMessage>

                </FormControl>

                <Flex justifyContent={'center'}>
                    <Button isLoading={loading} onClick={onCreateMyAccountClick} colorScheme={'brand'} rounded={'full'}>
                        Create my account
                    </Button>

                </Flex>
            </Box>
        </Box>
    )
}
