import { Box, Button, Center, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { fetchBmsBatchs } from '../../api/bms-batch'
import { useAppSelector } from '../../hooks/redux'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { routeNames } from '../../router/routeNames'

export const RerunOrdersViewPage = () => {

    const navigate = useNavigate()

    const [batches, setBatches] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)

    const { currentSelectedOrganization } = useAppSelector(state => state.organization)

    useEffect(() => {

        if (currentSelectedOrganization?.id) {

            // setIsLoading(true)
            // fetchBmsBatchs(currentSelectedOrganization.id)
            //     .then(response => {
            //         console.log(response)
            //         setBatches(response)
            //     })
            //     .finally(() => {
            //         setIsLoading(false)
            //     })
        }
    }, [currentSelectedOrganization])

    return (
        <DashboardLayout heading={'Rerun-Order Batches'} >

            <Center>

                <Box my={'10'} p={'2'} bgColor={'white'} w={'70%'}>
                    <Box mb={'2'} p={'4'} bgColor={'gray.50'}>
                        <Box>
                            <Text fontWeight={'bold'} fontSize={'xl'}>Rerun Batches</Text>
                            <Text >View your rerun orders batches.</Text>
                        </Box>
                    </Box>

                    <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                        {
                            isLoading ?
                                <Center>
                                    <Spinner />
                                </Center> : (
                                    <TableContainer>

                                        <Table variant='simple'>
                                            <Thead>
                                                <Tr>
                                                    <Th>Status</Th>
                                                    <Th>Timestamp</Th>
                                                    <Th># Orders</Th>
                                                    <Th>Approved</Th>
                                                    <Th>Declined</Th>
                                                    <Th>Pending</Th>
                                                    <Th />
                                                </Tr>
                                            </Thead>

                                            <Tbody>
                                                {
                                                    batches.map((batch: any) => (

                                                        <Tr>
                                                            <Td>{batch.status?.toUpperCase()}</Td>
                                                            <Td>{dayjs(batch.createdAt).format('DD-MM-YYYY hh:mm a')}</Td>
                                                            <Td>{batch.bms_batch_orders?.length || 0}</Td>
                                                            <Td>{batch.bms_batch_orders?.filter((o: any) => o.status === 'completed')?.length || 0}</Td>
                                                            <Td>{batch.bms_batch_orders?.filter((o: any) => o.status === 'failed')?.length || 0}</Td>
                                                            <Td>{batch.bms_batch_orders?.filter((o: any) => o.status === 'pending')?.length || 0}</Td>
                                                            <Td>
                                                                <Button onClick={() => {
                                                                    navigate(routeNames.rerunOrderDetail(batch.id))
                                                                }}>View</Button>
                                                            </Td>

                                                        </Tr>
                                                    ))
                                                }

                                            </Tbody>

                                        </Table>
                                    </TableContainer>

                                )
                        }
                          </Box>

                </Box>
            </Center>
        </DashboardLayout>
    )
}
