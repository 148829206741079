import { createSlice, PayloadAction } from '@reduxjs/toolkit'
export interface OrganizationState {
    userOrganizations: any[],
    currentSelectedOrganization: any,
    organizationInvitations: any[],
    isOrganizationStateLoading: boolean
}

const initialState: OrganizationState = {
    userOrganizations: [],
    currentSelectedOrganization: null,
    organizationInvitations: [],
    isOrganizationStateLoading: true
}

export const organizationSlice = createSlice({
    name: 'organization',
    initialState,
    reducers: {
        setUserOrganizations: (state, action: PayloadAction<any>) => {
            state.userOrganizations = action.payload
        },
        setCurrentSelectedOrganization: (state, action: PayloadAction<any>) => {
            state.currentSelectedOrganization = action.payload
        },
        setOrganizationInvitations: (state, action: PayloadAction<any>) => {
            state.organizationInvitations = action.payload
        },
        setIsOrganizationStateLoading: (state, action: PayloadAction<any>) => {
            state.isOrganizationStateLoading = action.payload
        },
    },
})

// Action creators are generated for each case reducer function
export const { setIsOrganizationStateLoading, setUserOrganizations, setCurrentSelectedOrganization, setOrganizationInvitations } = organizationSlice.actions

const organizationReducer = organizationSlice.reducer
export default organizationReducer