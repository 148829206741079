import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Input,
    Checkbox,
    Grid,
    GridItem,
    FormControl,
    FormLabel,
    Select,
    Flex,
    RadioGroup,
    Radio,
    Textarea,
    Box,
    useToast,
    Switch,
    useDisclosure,
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { billingManagerRerun } from '../../api/plugin'
import DateTimePickerModal from '../../components/DateTimePickerModal'
import { useAppSelector } from '../../hooks/redux'
import TimePicker from 'react-time-picker';

export const OrderRerunModal = ({ isOpen, onClose, fetchData }: any) => {


    const { projects, isProjectsLoading } = useAppSelector(state => state.project)

    const [orderIds, setOrderIds] = useState('')
    const [selectedCRM, setSelectedCRM] = useState('konnektive')
    const [loading, setLoading] = useState(false)
    const [orderType, setOrderType] = useState('order')
    const [isUse3DSToken, setIsUse3DSToken] = useState(false)
    const [campaignId, setCampaignId] = useState('')
    const [productId, setProductId] = useState('')
    const [rebillId, setRebillId] = useState('')

    const [isMatchByOrderId, setIsMatchByOrderId] = useState(false)

    const [currentProject, setCurrentProject] = useState<any>()
    const [newProject, setNewProject] = useState<any>()

    const [fromTime, setFromTime] = useState<any>(dayjs().format('hh:mm'))
    const [toTime, setToTime] = useState<any>(dayjs().format('hh:mm'))

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    })

    const { isOpen: isDatePickerOpen, onOpen: onDatePickerOpen, onClose: onDatePickerClose } = useDisclosure()

    const [intervalTime, setIntervalTime] = useState("0")

    useEffect(() => {
        setCurrentProject(projects[0]?.id || null)
        setNewProject(projects[0]?.id || null)
    }, [projects])

    const toast = useToast()

    const { currentSelectedOrganization } = useAppSelector(state => state.organization)

    const onRerunClick = () => {



        if (!isMatchByOrderId && (!newProject || !currentProject || !fromTime || !toTime)) {
            toast({
                title: 'Please select all required fields'
            })
            return
        }

        if (isMatchByOrderId && (!orderIds)) {
            toast({
                title: 'Please enter the order Ids'
            })
            return
        }


        const [fromHour, fromMin] = fromTime.split(':')
        const [toHour, toMin] = toTime.split(':')

        setLoading(true)
        billingManagerRerun({

            campaignId,
            productId,
            isMatchByOrderId,
            orderIds: orderIds.split("\n"),
            crm: selectedCRM,
            intervalTime: parseFloat(intervalTime),
            fromDate: dayjs(selectedDateRange.startDate)
                .set('hour', parseInt(fromHour))
                .set('minute', parseInt(fromMin)).set('second', 0).set('millisecond', 0).toISOString(),
            toDate: dayjs(selectedDateRange.endDate)
                .set('hour', parseInt(toHour))
                .set('minute', parseInt(toMin)).set('second', 0).set('millisecond', 0).toISOString(),

            currentProject: parseInt(currentProject),
            newProject: parseInt(newProject),
            isUse3DSToken,

        }, currentSelectedOrganization?.id)
            .then(response => {
                toast({
                    status: 'success',
                    title: "Batch Ran"
                })

                onClose()
            })
            .catch(err => {
                toast({
                    status: 'error',
                    title: "Failed"
                })
            })
            .finally(() => {
                fetchData()
                setLoading(false)
            })



    }

    return (
        <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <DateTimePickerModal
                onRangeChange={(newRange: any) => setSelectedDateRange(newRange)}
                selectedRange={selectedDateRange}
                isOpen={isDatePickerOpen}
                onClose={onDatePickerClose}
            />
            <ModalContent>
                <ModalHeader>Rerun Orders</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <Grid pb={'3'} gap={5} templateColumns={'repeat(1,1fr)'}>

                        <GridItem>
                            <Box>

                                <FormControl mb={'5'}>
                                    <FormLabel>Match By OrderId ?</FormLabel>
                                    <Switch isChecked={isMatchByOrderId} onChange={(e) => setIsMatchByOrderId(e.target.checked)} />
                                </FormControl>


                                <FormControl mb={'5'}>
                                    <FormLabel>CRM</FormLabel>
                                    <Select value={selectedCRM} onChange={(e) => setSelectedCRM(e.target.value)}>
                                        <option value="konnektive">Konnektive Plugin</option>
                                        <option value="neobod">Neobod Plugin</option>
                                    </Select>
                                </FormControl>

                                {
                                    !isMatchByOrderId ? (
                                        <>
                                            <FormControl mb={'5'}>
                                                <FormLabel>Current Project</FormLabel>

                                                <Select onChange={(e: any) => setCurrentProject(e.target.value)} value={currentProject} bgColor={'white'}>

                                                    {
                                                        projects.map(p => (
                                                            <option value={p.id}>{p?.settings?.name}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>

                                            <FormControl mb={'5'}>
                                                <FormLabel>New Project</FormLabel>

                                                <Select onChange={(e: any) => setNewProject(e.target.value)} value={newProject} bgColor={'white'}>

                                                    {
                                                        projects.map(p => (
                                                            <option value={p.id}>{p?.settings?.name}</option>
                                                        ))
                                                    }
                                                </Select>
                                            </FormControl>

                                            <FormControl mb={'5'}>
                                                <FormLabel>Select Date Range</FormLabel>
                                                <Text onClick={() => onDatePickerOpen()} cursor={'pointer'} p={'1'} bgColor={'white'}>{dayjs(selectedDateRange.startDate).format('DD MMM YYYY')} - {dayjs(selectedDateRange.endDate).format('DD MMM YYYY')}</Text>
                                            </FormControl>

                                            <FormControl mb={'5'}>
                                                <FormLabel>Select From Time</FormLabel>
                                                <TimePicker onChange={setFromTime} value={fromTime} />
                                            </FormControl>

                                            <FormControl mb={'5'}>
                                                <FormLabel>Select To Time</FormLabel>
                                                <TimePicker onChange={setToTime} value={toTime} />
                                            </FormControl>

                                        </>
                                    ) : (
                                        <>
                                            <FormControl mb={'5'}>
                                                <FormLabel>Campaign Id</FormLabel>
                                                <Input value={campaignId} onChange={(e) => setCampaignId(e.target.value)} />
                                            </FormControl>


                                            <FormControl mb={'5'}>
                                                <FormLabel>Product Id</FormLabel>
                                                <Input value={productId} onChange={(e) => setProductId(e.target.value)} />
                                            </FormControl>


                                            <FormControl mb={'5'}>
                                                <FormLabel>Orders ID</FormLabel>
                                                <Textarea value={orderIds} onChange={(e) => setOrderIds(e.target.value)} minLength={30} />
                                            </FormControl>
                                        </>
                                    )
                                }

                                <FormControl mb={'5'}>
                                    <FormLabel>Interval Time (In Mins)</FormLabel>
                                    <Input type="number" min={0} max={100} value={intervalTime} onChange={(e) => setIntervalTime(e.target.value)} />
                                </FormControl>


                                <FormControl mb={'5'}>
                                    <FormLabel>Use 3DS Rebill Token</FormLabel>
                                    <Switch isChecked={isUse3DSToken} onChange={(e) => setIsUse3DSToken(e.target.checked)} />
                                </FormControl>



                                {/* <FormControl mb={'5'}>
                                    <FormLabel>Order Type</FormLabel>
                                    <Select value={orderType} onChange={(e) => setOrderType(e.target.value)}>
                                        <option value="order">Order</option>
                                        <option value="upsale">Upsale</option>
                                    </Select>
                                </FormControl> */}



                                {/* <FormControl mb={'5'}>
                                    <FormLabel>Rebill Id</FormLabel>
                                    <Input value={rebillId} onChange={(e) => setRebillId(e.target.value)} />
                                </FormControl> */}


                            </Box>
                        </GridItem>


                    </Grid>

                    <Flex justifyContent={'flex-end'}>
                        <Button onClick={onRerunClick} isLoading={loading} colorScheme={'brand'}>Rerun</Button>
                    </Flex>


                </ModalBody>
            </ModalContent>
        </Modal>

    )
}
