import { baseAxiosInstance } from "../config/axios"

export const fetchCurrentUser = async (authToken: string) => {
    return baseAxiosInstance.get('/users/me', {
        headers: {
            "Authorization": `${authToken}`
        }
    })
        .then(response => response.data)
        .catch(err => {
            throw err
        })
}

