import { fetchOrganizationInvites, fetchUserOrganizations } from "../../api/organization";
import { setCurrentSelectedOrganization, setIsOrganizationStateLoading, setOrganizationInvitations, setUserOrganizations } from "../reducers/organization";
import { initProjectState } from "./project";

export const initOrganizationState = () => async (dispatch: any, getState: any) => {

    const userSelectedOrgId = localStorage.getItem('userOrganization')
    const userOrganizations = await fetchUserOrganizations()

    let userSelectedOrg;

    if (userSelectedOrgId) {
        userSelectedOrg = userOrganizations.find((org: any) => org.id == userSelectedOrgId)
    } else {
        userSelectedOrg = userOrganizations[0]
        localStorage.setItem('userOrganization', userOrganizations[0].id)
    }

    const orgInvites = await fetchOrganizationInvites(userSelectedOrg.id)
        .catch(err => console.log(err))

    dispatch(setCurrentSelectedOrganization(userSelectedOrg))
    dispatch(setUserOrganizations(userOrganizations))
    dispatch(setOrganizationInvitations(orgInvites))
    dispatch(setIsOrganizationStateLoading(false))
}


export const switchOrganization = (organizationId: number) => async (dispatch: any, getState: any) => {
    localStorage.setItem('userOrganization', `${organizationId}`)
    await dispatch(initOrganizationState())
    await dispatch(initProjectState())
}
