import { Grid } from '@chakra-ui/react'
import React from 'react'

export const CustomResponsiveGrid = ({ children, ...rest }: any) => {
    return (
        <Grid p={'5'} gap={'7'} templateColumns={'repeat(4,1fr)'} {...rest} >
            {children}
        </Grid>
    )
}
