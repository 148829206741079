import { Box, Button, Center, Flex, FormControl, FormLabel, Grid, GridItem, HStack, IconButton, Select, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-date-range';
import { fetchLeadsRecordsOfProject } from '../../api/leadsRecord';
import DateTimePickerModal from '../../components/DateTimePickerModal';
import isBetween from 'dayjs/plugin/isBetween'
import dayjs from 'dayjs';
import { AiOutlineEye } from 'react-icons/ai';
import { BillingDetailModal } from '../EditProject/BillingDetailModal';
import { useAppSelector } from '../../hooks/redux';
import { DashboardLayout } from '../../Layout/DashboardLayout';
import CustomPagination from '../../components/CustomPagination';
import { OrderRerunModal } from './OrderRerunModal';
import { useNavigate } from 'react-router-dom';
import { routeNames } from '../../router/routeNames';
import { fetchBmsBatchs } from '../../api/bms-batch';
import { PaginationFilters } from '../../components/PaginationFilters';

dayjs.extend(isBetween)

export const BillingManagerPage = () => {


    const navigate = useNavigate()


    const [paginationMetaData, setPaginationMetaData] = useState<any>(null)
    const { isOpen, onClose, onOpen } = useDisclosure()
    const { isOpen: isRerunModalOpen, onClose: onRerunModalClose, onOpen: onRerunModalOpen } = useDisclosure()

    const toast = useToast()

    const [batches, setBatches] = useState<any>([])
    const [isLoading, setIsLoading] = useState(true)

    const { currentSelectedOrganization } = useAppSelector(state => state.organization)

    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    })

    const [noOfDisplayRecord, setNoOfDisplayRecords] = useState("25");
    const [currentPage, setCurrentPage] = useState(1)



    const onPaginationFiltersChange = ({
        selectedDateRange,
        noOfDisplayRecord,
        currentPage
    }: any) => {
        setSelectedDateRange(selectedDateRange)
        setNoOfDisplayRecords(noOfDisplayRecord)
        setCurrentPage(currentPage)

    }

    useEffect(() => {
        fetchData()
    }, [currentSelectedOrganization, selectedDateRange, noOfDisplayRecord, currentPage])

    const fetchData = () => {
        if (currentSelectedOrganization?.id) {
            setIsLoading(true)
            fetchBmsBatchs(currentSelectedOrganization.id, {
                from: selectedDateRange.startDate.toISOString(),
                to: selectedDateRange.endDate.toISOString(),
                noOfRecords: noOfDisplayRecord,
                currentPage
            }
            )
                .then(response => {
                    // console.log(response)
                    setBatches(response.results)
                    setPaginationMetaData(response.pagination)
                })
                .finally(() => {
                    setIsLoading(false)
                })
        }
    }

    const onRerunOrdersClick = () => {
        onRerunModalOpen()
    }

    return (

        <DashboardLayout heading={'Billing Manager'} >
            <Box>
                <DateTimePickerModal onRangeChange={(newRange: any) => setSelectedDateRange(newRange)} selectedRange={selectedDateRange} isOpen={isOpen} onClose={onClose} />
                <OrderRerunModal
                    fetchData={fetchData}
                    isOpen={isRerunModalOpen}
                    onClose={onRerunModalClose} />

                <Center>

                    <Box my={'10'} p={'2'} bgColor={'white'} w={'70%'}>
                        <Box mb={'2'} p={'4'} bgColor={'gray.50'}>
                            <Box>
                                <Text fontWeight={'bold'} fontSize={'xl'}>Billing Manager</Text>
                                <Text >View your project traffic details.</Text>
                            </Box>
                        </Box>



                        <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                            <PaginationFilters onFiltersChange={onPaginationFiltersChange} pageCount={paginationMetaData?.pageCount} />

                        </Box>

                        <Box mb={'2'} p={'4'} bgColor={'gray.50'}>
                            <HStack spacing={'3'}>
                                <Button onClick={onRerunOrdersClick} colorScheme={'brand'}>New Batch</Button>
                            </HStack>
                        </Box>

                        <Box mb={'2'} p={'4'} bgColor={'gray.50'}>

                            {
                                isLoading ?
                                    <Center>
                                        <Spinner />
                                    </Center> : (
                                        <TableContainer>

                                            <Table variant='simple'>
                                                <Thead>
                                                    <Tr>
                                                        <Th>Status</Th>
                                                        <Th>Timestamp</Th>
                                                        <Th># Orders</Th>
                                                        <Th>Approved</Th>
                                                        <Th>Declined</Th>
                                                        <Th>Pending</Th>
                                                        <Th />
                                                    </Tr>
                                                </Thead>

                                                <Tbody>
                                                    {
                                                        batches.map((batch: any) => (

                                                            <Tr>
                                                                <Td>{batch.status?.toUpperCase()}</Td>
                                                                <Td>{dayjs(batch.createdAt).format('DD-MM-YYYY hh:mm a')}</Td>
                                                                <Td>{batch.bms_batch_orders?.length || 0}</Td>
                                                                <Td>{batch.bms_batch_orders?.filter((o: any) => o.status === 'completed')?.length || 0}</Td>
                                                                <Td>{batch.bms_batch_orders?.filter((o: any) => o.status === 'failed')?.length || 0}</Td>
                                                                <Td>{batch.bms_batch_orders?.filter((o: any) => o.status === 'pending')?.length || 0}</Td>
                                                                <Td>
                                                                    <Button onClick={() => {
                                                                        navigate(routeNames.rerunOrderDetail(batch.id))
                                                                    }}>View</Button>
                                                                </Td>

                                                            </Tr>
                                                        ))
                                                    }

                                                </Tbody>

                                            </Table>
                                        </TableContainer>

                                    )
                            }
                        </Box>


                    </Box>

                </Center>

            </Box>

        </DashboardLayout>

    )
}
