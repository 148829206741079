import { Box, Center, Flex, IconButton, Spinner, Table, Tbody, Td, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { fetchActivityLogsOfOrganization } from '../../api/activity-logs'
import { useAppSelector } from '../../hooks/redux'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { AiOutlineEye } from 'react-icons/ai'
import { ViewActivityDataModal } from './ViewActivityDataModal'
import { PaginationFilters } from '../../components/PaginationFilters'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'

export const ActivityLogsPage = () => {

    const [logs, setLogs] = useState<any[]>([])
    const [selectedLog, setSelectedLog] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(false)

    const [isSortByAscTime, setIsSortByAscTime] = useState(true)


    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
    })

    const [paginationMetaData, setPaginationMetaData] = useState<any>(null)
    const [noOfDisplayRecord, setNoOfDisplayRecords] = useState("25");
    const [currentPage, setCurrentPage] = useState(1)

    const { isOpen, onClose, onOpen } = useDisclosure()

    const { currentSelectedOrganization } = useAppSelector(state => state.organization)


    useEffect(() => {
        if (currentSelectedOrganization) {
            fetchData()
        }
    }, [currentSelectedOrganization, selectedDateRange, noOfDisplayRecord, currentPage , isSortByAscTime])


    const fetchData = () => {
        setIsLoading(true)
        fetchActivityLogsOfOrganization(currentSelectedOrganization?.id, {
            from: selectedDateRange.startDate.toISOString(),
            to: selectedDateRange.endDate.toISOString(),
            noOfRecords: noOfDisplayRecord,
            currentPage,
            sortByTimestamp: isSortByAscTime ? 'asc' : 'desc'
        })
            .then(response => {
                setLogs(response.results || [])
                setPaginationMetaData(response.pagination)
            })
            .finally(() => setIsLoading(false))
    }

    const onPaginationFiltersChange = ({
        selectedDateRange,
        noOfDisplayRecord,
        currentPage
    }: any) => {
        setSelectedDateRange(selectedDateRange)
        setNoOfDisplayRecords(noOfDisplayRecord)
        setCurrentPage(currentPage)
    }

    const renderObjectName = (log: any) => {
        let val = log.objectName
        if (!val) {
            if (log.objectType === 'project') {
                val = log.project?.settings?.name || ''
            }

        }
        return val
    }

    const onViewDataClick = (log: any) => {
        setSelectedLog(log)
        onOpen()

    }

    return (
        <DashboardLayout heading={'Activity Logs'}>
            <ViewActivityDataModal isOpen={isOpen} onClose={onClose} data={selectedLog?.data} />
            {/* <Box>

            </Box> */}
            <Box p={'5'}>

                <Box my={'2'} p={'4'} bgColor={'gray.50'}>

                    <PaginationFilters onFiltersChange={onPaginationFiltersChange} pageCount={paginationMetaData?.pageCount} />

                </Box>


                <Center>
                    {
                        isLoading ?
                            <Spinner /> : (
                                <Table bgColor={'white'}>
                                    <Thead>
                                        <Tr>
                                            <Th onClick={() => setIsSortByAscTime(!isSortByAscTime)} cursor="pointer">
                                                <Flex>
                                                    Timestamp {isSortByAscTime ? <BsArrowDown /> : <BsArrowUp />}
                                                </Flex>
                                            </Th>
                                            <Th>Subject</Th>
                                            <Th>Action</Th>
                                            <Th>Object Type</Th>
                                            <Th>Object Name</Th>
                                            <Th>Data</Th>
                                        </Tr>
                                    </Thead>

                                    <Tbody bgColor={'gray.50'}>
                                        {
                                            logs.map(log => (
                                                <Tr>
                                                    <Td>{dayjs(log.createdAt).format('YYYY-MM-DD hh:mm a')}</Td>
                                                    <Td>{log.subject}</Td>
                                                    <Td>{log.action?.toUpperCase()}</Td>
                                                    <Td>{log.objectType}</Td>
                                                    <Td>{renderObjectName(log)}</Td>
                                                    <Td>
                                                        <IconButton onClick={() => onViewDataClick(log)} aria-label='view' icon={<AiOutlineEye />} />
                                                    </Td>


                                                </Tr>
                                            ))
                                        }

                                    </Tbody>

                                </Table>
                            )
                    }

                </Center>
            </Box>




        </DashboardLayout>
    )
}
