import React from 'react'
import { CustomTabs } from '../../components/CustomTabs'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { GeneralTab } from './GeneralTab'

export const ProfilePage = () => {
    return (
        <DashboardLayout heading="Profile" >

            <CustomTabs
                tabs={[
                    'GENERAL'
                ]}

                panels={[
                    <GeneralTab />
                ]}
            />

        </DashboardLayout>
    )
}
