import { Box, Button, Center, Checkbox, CheckboxGroup, Flex, FormControl, FormLabel, Grid, GridItem, Heading, HStack, Input, Menu, MenuButton, MenuItem, MenuItemOption, MenuList, Radio, RadioGroup, Select, Spinner, Switch, Table, Tbody, Text, Th, Thead, Tr, useDisclosure, useToast, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { AssetDetailCard } from '../../components/AssetDetailCard'
import CustomDropzone from '../../components/CustomDropzone'
import { CustomWhiteMenuButton } from '../../components/CustomMenu'
import { CustomTabs } from '../../components/CustomTabs'
import { DashboardLayout } from '../../Layout/DashboardLayout'
import { AddPixelModal } from './AddPixelModal'
import AssetsTab from './AssetsTab'
import GeneralTab from './GeneralTab'
import PixelsTab from './PixelsTab'
import { TokensTab } from './TokensTab'
import { UploadAssetModal } from './UploadAssetModal'
import { AiOutlineUpload } from 'react-icons/ai'
import { FiMoreVertical } from 'react-icons/fi'
import { yupResolver } from '@hookform/resolvers/yup'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { createProjectForOrganization, fetchSingleProject, updateProject } from '../../api/project'
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { initProjectState } from '../../store/actions/project'
import { StatsTab } from './StatsTab'
import { routeNames } from '../../router/routeNames'

export const EditProjectPage = () => {


    const { id: projectId } = useParams()

    const data: any = {}

    const navigate = useNavigate()
    const toast = useToast()
    const dispatch = useAppDispatch()

    const [project, setProject] = useState<any>(null)

    const [projectLoading, setProjectLoading] = useState<any>(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetchProjectData()

        return () => {
            setIsLoading(false)
            setProjectLoading(false)
            setProject(null)
        }
    }, [])

    const fetchProjectData = (isLoad = true) => {
        if (isLoad)
            setProjectLoading(true)

        fetchSingleProject(parseInt(`${projectId}`))
            .then(response => {
                setProject(response.data)
            })
            .catch(err => console.log(err))
            .finally(() => setProjectLoading(false))
    }



    const onSaveAndPublishClick = () => {
        setIsLoading(true)

        updateProject(parseInt(`${projectId}`), {
            data: {

                isBundled: data.isBundled,
                isActivated: data.isActivated,
                is3dsEnabled: data.is3dsEnabled,
                isNeobodEnabled: data.isNeobodEnabled,
                neobodSalesOption: data.neobodSalesOption,
                
                settings: {
                    name: data.projectName,
                    favicon: data.favicon?.id || null,
                    sessionLockPage: data.lockSessionOnPage,
                    sessionDuration: data.sessionDuration || 0
                },

                globalSEO: {
                    titleTag: data.titleTag,
                    mobileRedirect: data.mobileRedirect,
                    blockSearchEnginesAndSpiders: data.blockSearchEnginesAndSpiders,
                    externalURL: data.externalURL,
                    anotherProject: data.anotherProject || null,
                    anotherPage: data.anotherPage
                },

                konnektiveGeneralSettings: {
                    appVersion: data.kpAppVersion,
                    upsellDelayTime: parseFloat(data.kpUpsellDelayTime),
                    isManualUpsell: data.kpIsManualUpsell,
                },

                konnektiveLiveSettings: {
                    campaignId: data.kpLiveCampaignId,
                    productId: data.kpLiveProductId,
                    upsellId: data.kpLiveUpsellId,
                    rebillUpsellId: data.kpLiveRebillUpsellId,
                    rebillProductId: data.kpLiveRebillProductId,
                    salutarisToken: data.kpLiveSalutarisToken,
                    isUpsell_S_NS: data.kpLiveIsUpsell_S_NS
                },

                konnektiveInternalSettings: {
                    campaignId: data.kpInternalCampaignId,
                    productId: data.kpInternalProductId,
                    upsellId: data.kpInternalUpsellId,
                    rebillUpsellId: data.kpInternalRebillUpsellId,
                    rebillProductId: data.kpInternalRebillProductId,
                    salutarisToken: data.kpInternalSalutarisToken,
                    isUpsell_S_NS: data.kpInternalIsUpsell_S_NS

                },

                konnektivePrepaidSettings: {
                    campaignId: data.kpPrepaidCampaignId,
                    productId: data.kpPrepaidProductId,
                    upsellId: data.kpPrepaidUpsellId,
                    rebillUpsellId: data.kpPrepaidRebillUpsellId,
                    rebillProductId: data.kpPrepaidRebillProductId,
                    salutarisToken: data.kpPrepaidSalutarisToken,
                    isUpsell_S_NS: data.kpPrepaidIsUpsell_S_NS

                },

                ruleSettings: {
                    defaultThrottle: parseFloat(data.defaultThrottle),
                    throttleAffiliateID: data.throttleAffiliateId,
                    throttleCampaignID: data.throttleCampaignId,
                    prepaidCampaignID: data.prepaidCampaignId,
                    internalID: data.internalId
                },

                rules: data.rules || [],
                tokens: data.tokens || [],
                domains: data.domains || [],
                konnektiveLiveProducts: data.kpLiveProducts || [],
                konnektiveLiveUpsells: data.kpLiveUpsells || [],
                konnektiveInternalProducts: data.kpInternalProducts || [],
                konnektiveInternalUpsells: data.kpInternalUpsells || [],
                konnektivePrepaidProducts: data.kpPrepaidProducts || [],
                konnektivePrepaidUpsells: data.kpPrepaidUpsells || [],

            }
        })
            .then(async response => {
                fetchProjectData()
                dispatch(initProjectState())
                toast({
                    status: 'success',
                    title: 'Project Details Updated'
                })

            })
            .then(() => {

            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))

    }


    return (
        <DashboardLayout onHeadingClick={() => navigate(routeNames.projects())} heading={'Project'} subHeading={project?.attributes?.settings?.name || 'Unnamed'}>
            {
                projectLoading ?
                    <Center h={'100vh'}>
                        <Spinner />
                    </Center> :

                    <CustomTabs

                        rightContent={
                            <HStack px={'2'}>
                                <Button isLoading={isLoading} onClick={onSaveAndPublishClick} leftIcon={<AiOutlineUpload fontSize={'30px'} />} rounded={'full'} colorScheme={'whatsapp'}>Save and Publish</Button>
                                <Menu>
                                    <MenuButton>
                                        <FiMoreVertical fontSize={'25px'} />
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem>Clone Project</MenuItem>
                                        <MenuItem color={'red.500'}>Delete</MenuItem>
                                    </MenuList>
                                </Menu>
                            </HStack>
                        }
                        tabs={[

                            'GENERAL',
                            'ASSETS',
                            'TOKENS',
                            'PIXELS',
                            // 'STATS',
                            // 'BILLING MANAGER'
                        ]}

                        panels={[
                            <GeneralTab project={project} data={data} />,
                            <AssetsTab project={project} />,
                            <TokensTab project={project} data={data} />,
                            <PixelsTab fetchProjectData={fetchProjectData} project={project} data={data} />,
                            // <StatsTab project={project} />,
                            // <BillingManagerTab project={project} data={data} />
                        ]}

                    />

            }


        </DashboardLayout>
    )
}





