import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    Input,
    Checkbox,
} from '@chakra-ui/react'

export const CreateSSLModal = ({ isOpen, onClose }: any) => {
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Add SSL Certificate</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Text color={'gray.500'}>Domain</Text>
                    <Input mb={'2'} borderRadius={'none'} />
                    <Checkbox>Wildcard SSL</Checkbox>
                </ModalBody>

                <ModalFooter>
                    <Button rounded={'full'} onClick={onClose} colorScheme='gray' mr={3}>
                        Cancel
                    </Button>
                    <Button rounded={'full'} colorScheme='brand'>Create</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
